@import url("css.css?family=Raleway:300,400,600");

html {
    font-family: sans-serif;
}

body {
    margin-top: 0;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 0;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
    display: block;
}

audio,
canvas,
progress,
video {
    display: inline-block;
    vertical-align: baseline;
}

audio:not([controls]) {
    display: none;
    height: 0px;
}

[hidden],
template {
    display: none;
}

a {
    background-color: transparent;
}

a:active,
a:hover {
    outline-width: 0;
}

abbr[title] {
    border-bottom-color: initial;
    border-bottom-style: dotted;
    border-bottom-width: 1px;
}

b,
strong {
    font-weight: 700;
}

dfn {
    font-style: italic;
}

h1 {
    font-size: 2em;
    margin-top: 0.67em;
    margin-right: 0;
    margin-bottom: 0.67em;
    margin-left: 0;
}

mark {
    background-color: rgb(255, 255, 0);
    background-image: initial;
    color: rgb(0, 0, 0);
}

small {
    font-size: 80%;
}

sub,
sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
}

sup {
    top: -0.5em;
}

sub {
    bottom: -0.25em;
}

img {
    border-top-color: initial;
    border-top-style: initial;
    border-top-width: 0;
    border-right-color: initial;
    border-right-style: initial;
    border-right-width: 0;
    border-bottom-color: initial;
    border-bottom-style: initial;
    border-bottom-width: 0;
    border-left-color: initial;
    border-left-style: initial;
    border-left-width: 0;
}

svg:not(:root) {
    overflow-x: hidden;
    overflow-y: hidden;
}

figure {
    margin-top: 1em;
    margin-right: 40px;
    margin-bottom: 1em;
    margin-left: 40px;
}

hr {
    box-sizing: content-box;
    height: 0px;
}

pre {
    overflow-x: auto;
    overflow-y: auto;
}

code,
kbd,
pre,
samp {
    font-family: monospace, monospace;
    font-size: 1em;
}

button,
input,
optgroup,
select,
textarea {
    color: inherit;
    font-style: inherit;
    font-variant-alternates: inherit;
    font-variant-caps: inherit;
    font-variant-east-asian: inherit;
    font-variant-ligatures: inherit;
    font-variant-numeric: inherit;
    font-variant-position: inherit;
    font-weight: inherit;
    font-stretch: inherit;
    font-size: inherit;
    margin-top: 0;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 0;
}

button {
    overflow-x: visible;
    overflow-y: visible;
}

button,
select {
    text-transform: none;
}

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
    cursor: pointer;
}

button[disabled],
html input[disabled] {
    cursor: default;
}

button:focus-inner,
input:focus-inner {
    border-top-color: initial;
    border-top-style: initial;
    border-top-width: 0;
    border-right-color: initial;
    border-right-style: initial;
    border-right-width: 0;
    border-bottom-color: initial;
    border-bottom-style: initial;
    border-bottom-width: 0;
    border-left-color: initial;
    border-left-style: initial;
    border-left-width: 0;
    padding-top: 0;
    padding-right: 0;
    padding-bottom: 0;
    padding-left: 0;
}

input {
    line-height: normal;
}

input[type="checkbox"],
input[type="radio"] {
    box-sizing: border-box;
    padding-top: 0;
    padding-right: 0;
    padding-bottom: 0;
    padding-left: 0;
}

input[type="number"]:inner-spin-button,
input[type="number"]:outer-spin-button {
    height: auto;
}

input[type="search"] {
    box-sizing: content-box;
}

input[type="search"]:search-cancel-button,
input[type="search"]:search-decoration {}

fieldset {
    border-top-color: silver;
    border-top-style: solid;
    border-top-width: 1px;
    border-right-color: silver;
    border-right-style: solid;
    border-right-width: 1px;
    border-bottom-color: silver;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-left-color: silver;
    border-left-style: solid;
    border-left-width: 1px;
    margin-top: 0;
    margin-right: 2px;
    margin-bottom: 0;
    margin-left: 2px;
    padding-top: 0.35em;
    padding-right: 0.625em;
    padding-bottom: 0.75em;
    padding-left: 0.625em;
}

textarea {
    overflow-x: auto;
    overflow-y: auto;
}

optgroup {
    font-weight: 700;
}

table {
    border-collapse: collapse;
    border-spacing: 0 0;
}

td,
th {
    padding-top: 0;
    padding-right: 0;
    padding-bottom: 0;
    padding-left: 0;
}

@media print {

    *,
    :after,
    :before {
        background-color: transparent !important;
        background-image: initial !important;
        color: rgb(0, 0, 0) !important;
        box-shadow: none !important;
        text-shadow: none !important;
    }

    a,
    a:visited {
        text-decoration-line: underline;
    }

    a[href]:after {
        content: " (" href ")";
    }

    abbr[title]:after {
        content: " (" title ")";
    }

    a[href^="#"]:after,
    a[href^="javascript:"]:after {
        content: "";
    }

    blockquote,
    pre {
        border-top-color: rgb(153, 153, 153);
        border-top-style: solid;
        border-top-width: 1px;
        border-right-color: rgb(153, 153, 153);
        border-right-style: solid;
        border-right-width: 1px;
        border-bottom-color: rgb(153, 153, 153);
        border-bottom-style: solid;
        border-bottom-width: 1px;
        border-left-color: rgb(153, 153, 153);
        border-left-style: solid;
        border-left-width: 1px;
        page-break-inside: avoid;
    }

    thead {
        display: table-header-group;
    }

    img,
    tr {
        page-break-inside: avoid;
    }

    img {
        max-width: 100% !important;
    }

    h2,
    h3,
    p {
        orphans: 3;
        widows: 3;
    }

    h2,
    h3 {
        page-break-after: avoid;
    }

    .navbar {
        display: none;
    }

    .btn>.caret,
    .dropup>.btn>.caret {
        border-top-color: rgb(0, 0, 0) !important;
    }

    .label {
        border-top-color: rgb(0, 0, 0);
        border-top-style: solid;
        border-top-width: 1px;
        border-right-color: rgb(0, 0, 0);
        border-right-style: solid;
        border-right-width: 1px;
        border-bottom-color: rgb(0, 0, 0);
        border-bottom-style: solid;
        border-bottom-width: 1px;
        border-left-color: rgb(0, 0, 0);
        border-left-style: solid;
        border-left-width: 1px;
    }

    .table {
        border-collapse: collapse !important;
    }

    .table td,
    .table th {
        background-color: rgb(255, 255, 255) !important;
    }

    .table-bordered td,
    .table-bordered th {
        border-top-color: rgb(221, 221, 221) !important;
        border-top-style: solid !important;
        border-top-width: 1px !important;
        border-right-color: rgb(221, 221, 221) !important;
        border-right-style: solid !important;
        border-right-width: 1px !important;
        border-bottom-color: rgb(221, 221, 221) !important;
        border-bottom-style: solid !important;
        border-bottom-width: 1px !important;
        border-left-color: rgb(221, 221, 221) !important;
        border-left-style: solid !important;
        border-left-width: 1px !important;
    }
}

@font-face {
    font-family: Glyphicons Halflings;
    src: url("https://bm719.com/fonts/vendor/bootstrap-sass/bootstrap/glyphicons-halflings-regular.eot?f4769f9bdb7466be65088239c12046d1?#iefix") format("embedded-opentype"), url("https://bm719.com/fonts/vendor/bootstrap-sass/bootstrap/glyphicons-halflings-regular.woff2?448c34a56d699c29117adc64c43affeb") format("woff2"), url("https://bm719.com/fonts/vendor/bootstrap-sass/bootstrap/glyphicons-halflings-regular.woff?fa2772327f55d8198301fdb8bcfc8158") format("woff"), url("https://bm719.com/fonts/vendor/bootstrap-sass/bootstrap/glyphicons-halflings-regular.ttf?e18bbf611f2a2e43afc071aa2f4e1512") format("truetype"), url("https://bm719.com/fonts/vendor/bootstrap-sass/bootstrap/glyphicons-halflings-regular.svg?89889688147bd7575d6327160d64e760#glyphicons_halflingsregular") format("svg");
}

.glyphicon {
    position: relative;
    top: 1px;
    display: inline-block;
    font-family: Glyphicons Halflings;
    font-style: normal;
    font-weight: 400;
    line-height: 1;
}

.glyphicon-asterisk:before {
    content: "*";
}

.glyphicon-plus:before {
    content: "+";
}

.glyphicon-eur:before,
.glyphicon-euro:before {
    content: "€";
}

.glyphicon-minus:before {
    content: "−";
}

.glyphicon-cloud:before {
    content: "☁";
}

.glyphicon-envelope:before {
    content: "✉";
}

.glyphicon-pencil:before {
    content: "✏";
}

.glyphicon-glass:before {
    content: "";
}

.glyphicon-music:before {
    content: "";
}

.glyphicon-search:before {
    content: "";
}

.glyphicon-heart:before {
    content: "";
}

.glyphicon-star:before {
    content: "";
}

.glyphicon-star-empty:before {
    content: "";
}

.glyphicon-user:before {
    content: "";
}

.glyphicon-film:before {
    content: "";
}

.glyphicon-th-large:before {
    content: "";
}

.glyphicon-th:before {
    content: "";
}

.glyphicon-th-list:before {
    content: "";
}

.glyphicon-ok:before {
    content: "";
}

.glyphicon-remove:before {
    content: "";
}

.glyphicon-zoom-in:before {
    content: "";
}

.glyphicon-zoom-out:before {
    content: "";
}

.glyphicon-off:before {
    content: "";
}

.glyphicon-signal:before {
    content: "";
}

.glyphicon-cog:before {
    content: "";
}

.glyphicon-trash:before {
    content: "";
}

.glyphicon-home:before {
    content: "";
}

.glyphicon-file:before {
    content: "";
}

.glyphicon-time:before {
    content: "";
}

.glyphicon-road:before {
    content: "";
}

.glyphicon-download-alt:before {
    content: "";
}

.glyphicon-download:before {
    content: "";
}

.glyphicon-upload:before {
    content: "";
}

.glyphicon-inbox:before {
    content: "";
}

.glyphicon-play-circle:before {
    content: "";
}

.glyphicon-repeat:before {
    content: "";
}

.glyphicon-refresh:before {
    content: "";
}

.glyphicon-list-alt:before {
    content: "";
}

.glyphicon-lock:before {
    content: "";
}

.glyphicon-flag:before {
    content: "";
}

.glyphicon-headphones:before {
    content: "";
}

.glyphicon-volume-off:before {
    content: "";
}

.glyphicon-volume-down:before {
    content: "";
}

.glyphicon-volume-up:before {
    content: "";
}

.glyphicon-qrcode:before {
    content: "";
}

.glyphicon-barcode:before {
    content: "";
}

.glyphicon-tag:before {
    content: "";
}

.glyphicon-tags:before {
    content: "";
}

.glyphicon-book:before {
    content: "";
}

.glyphicon-bookmark:before {
    content: "";
}

.glyphicon-print:before {
    content: "";
}

.glyphicon-camera:before {
    content: "";
}

.glyphicon-font:before {
    content: "";
}

.glyphicon-bold:before {
    content: "";
}

.glyphicon-italic:before {
    content: "";
}

.glyphicon-text-height:before {
    content: "";
}

.glyphicon-text-width:before {
    content: "";
}

.glyphicon-align-left:before {
    content: "";
}

.glyphicon-align-center:before {
    content: "";
}

.glyphicon-align-right:before {
    content: "";
}

.glyphicon-align-justify:before {
    content: "";
}

.glyphicon-list:before {
    content: "";
}

.glyphicon-indent-left:before {
    content: "";
}

.glyphicon-indent-right:before {
    content: "";
}

.glyphicon-facetime-video:before {
    content: "";
}

.glyphicon-picture:before {
    content: "";
}

.glyphicon-map-marker:before {
    content: "";
}

.glyphicon-adjust:before {
    content: "";
}

.glyphicon-tint:before {
    content: "";
}

.glyphicon-edit:before {
    content: "";
}

.glyphicon-share:before {
    content: "";
}

.glyphicon-check:before {
    content: "";
}

.glyphicon-move:before {
    content: "";
}

.glyphicon-step-backward:before {
    content: "";
}

.glyphicon-fast-backward:before {
    content: "";
}

.glyphicon-backward:before {
    content: "";
}

.glyphicon-play:before {
    content: "";
}

.glyphicon-pause:before {
    content: "";
}

.glyphicon-stop:before {
    content: "";
}

.glyphicon-forward:before {
    content: "";
}

.glyphicon-fast-forward:before {
    content: "";
}

.glyphicon-step-forward:before {
    content: "";
}

.glyphicon-eject:before {
    content: "";
}

.glyphicon-chevron-left:before {
    content: "";
}

.glyphicon-chevron-right:before {
    content: "";
}

.glyphicon-plus-sign:before {
    content: "";
}

.glyphicon-minus-sign:before {
    content: "";
}

.glyphicon-remove-sign:before {
    content: "";
}

.glyphicon-ok-sign:before {
    content: "";
}

.glyphicon-question-sign:before {
    content: "";
}

.glyphicon-info-sign:before {
    content: "";
}

.glyphicon-screenshot:before {
    content: "";
}

.glyphicon-remove-circle:before {
    content: "";
}

.glyphicon-ok-circle:before {
    content: "";
}

.glyphicon-ban-circle:before {
    content: "";
}

.glyphicon-arrow-left:before {
    content: "";
}

.glyphicon-arrow-right:before {
    content: "";
}

.glyphicon-arrow-up:before {
    content: "";
}

.glyphicon-arrow-down:before {
    content: "";
}

.glyphicon-share-alt:before {
    content: "";
}

.glyphicon-resize-full:before {
    content: "";
}

.glyphicon-resize-small:before {
    content: "";
}

.glyphicon-exclamation-sign:before {
    content: "";
}

.glyphicon-gift:before {
    content: "";
}

.glyphicon-leaf:before {
    content: "";
}

.glyphicon-fire:before {
    content: "";
}

.glyphicon-eye-open:before {
    content: "";
}

.glyphicon-eye-close:before {
    content: "";
}

.glyphicon-warning-sign:before {
    content: "";
}

.glyphicon-plane:before {
    content: "";
}

.glyphicon-calendar:before {
    content: "";
}

.glyphicon-random:before {
    content: "";
}

.glyphicon-comment:before {
    content: "";
}

.glyphicon-magnet:before {
    content: "";
}

.glyphicon-chevron-up:before {
    content: "";
}

.glyphicon-chevron-down:before {
    content: "";
}

.glyphicon-retweet:before {
    content: "";
}

.glyphicon-shopping-cart:before {
    content: "";
}

.glyphicon-folder-close:before {
    content: "";
}

.glyphicon-folder-open:before {
    content: "";
}

.glyphicon-resize-vertical:before {
    content: "";
}

.glyphicon-resize-horizontal:before {
    content: "";
}

.glyphicon-hdd:before {
    content: "";
}

.glyphicon-bullhorn:before {
    content: "";
}

.glyphicon-bell:before {
    content: "";
}

.glyphicon-certificate:before {
    content: "";
}

.glyphicon-thumbs-up:before {
    content: "";
}

.glyphicon-thumbs-down:before {
    content: "";
}

.glyphicon-hand-right:before {
    content: "";
}

.glyphicon-hand-left:before {
    content: "";
}

.glyphicon-hand-up:before {
    content: "";
}

.glyphicon-hand-down:before {
    content: "";
}

.glyphicon-circle-arrow-right:before {
    content: "";
}

.glyphicon-circle-arrow-left:before {
    content: "";
}

.glyphicon-circle-arrow-up:before {
    content: "";
}

.glyphicon-circle-arrow-down:before {
    content: "";
}

.glyphicon-globe:before {
    content: "";
}

.glyphicon-wrench:before {
    content: "";
}

.glyphicon-tasks:before {
    content: "";
}

.glyphicon-filter:before {
    content: "";
}

.glyphicon-briefcase:before {
    content: "";
}

.glyphicon-fullscreen:before {
    content: "";
}

.glyphicon-dashboard:before {
    content: "";
}

.glyphicon-paperclip:before {
    content: "";
}

.glyphicon-heart-empty:before {
    content: "";
}

.glyphicon-link:before {
    content: "";
}

.glyphicon-phone:before {
    content: "";
}

.glyphicon-pushpin:before {
    content: "";
}

.glyphicon-usd:before {
    content: "";
}

.glyphicon-gbp:before {
    content: "";
}

.glyphicon-sort:before {
    content: "";
}

.glyphicon-sort-by-alphabet:before {
    content: "";
}

.glyphicon-sort-by-alphabet-alt:before {
    content: "";
}

.glyphicon-sort-by-order:before {
    content: "";
}

.glyphicon-sort-by-order-alt:before {
    content: "";
}

.glyphicon-sort-by-attributes:before {
    content: "";
}

.glyphicon-sort-by-attributes-alt:before {
    content: "";
}

.glyphicon-unchecked:before {
    content: "";
}

.glyphicon-expand:before {
    content: "";
}

.glyphicon-collapse-down:before {
    content: "";
}

.glyphicon-collapse-up:before {
    content: "";
}

.glyphicon-log-in:before {
    content: "";
}

.glyphicon-flash:before {
    content: "";
}

.glyphicon-log-out:before {
    content: "";
}

.glyphicon-new-window:before {
    content: "";
}

.glyphicon-record:before {
    content: "";
}

.glyphicon-save:before {
    content: "";
}

.glyphicon-open:before {
    content: "";
}

.glyphicon-saved:before {
    content: "";
}

.glyphicon-import:before {
    content: "";
}

.glyphicon-export:before {
    content: "";
}

.glyphicon-send:before {
    content: "";
}

.glyphicon-floppy-disk:before {
    content: "";
}

.glyphicon-floppy-saved:before {
    content: "";
}

.glyphicon-floppy-remove:before {
    content: "";
}

.glyphicon-floppy-save:before {
    content: "";
}

.glyphicon-floppy-open:before {
    content: "";
}

.glyphicon-credit-card:before {
    content: "";
}

.glyphicon-transfer:before {
    content: "";
}

.glyphicon-cutlery:before {
    content: "";
}

.glyphicon-header:before {
    content: "";
}

.glyphicon-compressed:before {
    content: "";
}

.glyphicon-earphone:before {
    content: "";
}

.glyphicon-phone-alt:before {
    content: "";
}

.glyphicon-tower:before {
    content: "";
}

.glyphicon-stats:before {
    content: "";
}

.glyphicon-sd-video:before {
    content: "";
}

.glyphicon-hd-video:before {
    content: "";
}

.glyphicon-subtitles:before {
    content: "";
}

.glyphicon-sound-stereo:before {
    content: "";
}

.glyphicon-sound-dolby:before {
    content: "";
}

.glyphicon-sound-5-1:before {
    content: "";
}

.glyphicon-sound-6-1:before {
    content: "";
}

.glyphicon-sound-7-1:before {
    content: "";
}

.glyphicon-copyright-mark:before {
    content: "";
}

.glyphicon-registration-mark:before {
    content: "";
}

.glyphicon-cloud-download:before {
    content: "";
}

.glyphicon-cloud-upload:before {
    content: "";
}

.glyphicon-tree-conifer:before {
    content: "";
}

.glyphicon-tree-deciduous:before {
    content: "";
}

.glyphicon-cd:before {
    content: "";
}

.glyphicon-save-file:before {
    content: "";
}

.glyphicon-open-file:before {
    content: "";
}

.glyphicon-level-up:before {
    content: "";
}

.glyphicon-copy:before {
    content: "";
}

.glyphicon-paste:before {
    content: "";
}

.glyphicon-alert:before {
    content: "";
}

.glyphicon-equalizer:before {
    content: "";
}

.glyphicon-king:before {
    content: "";
}

.glyphicon-queen:before {
    content: "";
}

.glyphicon-pawn:before {
    content: "";
}

.glyphicon-bishop:before {
    content: "";
}

.glyphicon-knight:before {
    content: "";
}

.glyphicon-baby-formula:before {
    content: "";
}

.glyphicon-tent:before {
    content: "⛺";
}

.glyphicon-blackboard:before {
    content: "";
}

.glyphicon-bed:before {
    content: "";
}

.glyphicon-apple:before {
    content: "";
}

.glyphicon-erase:before {
    content: "";
}

.glyphicon-hourglass:before {
    content: "⌛";
}

.glyphicon-lamp:before {
    content: "";
}

.glyphicon-duplicate:before {
    content: "";
}

.glyphicon-piggy-bank:before {
    content: "";
}

.glyphicon-scissors:before {
    content: "";
}

.glyphicon-bitcoin:before,
.glyphicon-btc:before,
.glyphicon-xbt:before {
    content: "";
}

.glyphicon-jpy:before,
.glyphicon-yen:before {
    content: "¥";
}

.glyphicon-rub:before,
.glyphicon-ruble:before {
    content: "₽";
}

.glyphicon-scale:before {
    content: "";
}

.glyphicon-ice-lolly:before {
    content: "";
}

.glyphicon-ice-lolly-tasted:before {
    content: "";
}

.glyphicon-education:before {
    content: "";
}

.glyphicon-option-horizontal:before {
    content: "";
}

.glyphicon-option-vertical:before {
    content: "";
}

.glyphicon-menu-hamburger:before {
    content: "";
}

.glyphicon-modal-window:before {
    content: "";
}

.glyphicon-oil:before {
    content: "";
}

.glyphicon-grain:before {
    content: "";
}

.glyphicon-sunglasses:before {
    content: "";
}

.glyphicon-text-size:before {
    content: "";
}

.glyphicon-text-color:before {
    content: "";
}

.glyphicon-text-background:before {
    content: "";
}

.glyphicon-object-align-top:before {
    content: "";
}

.glyphicon-object-align-bottom:before {
    content: "";
}

.glyphicon-object-align-horizontal:before {
    content: "";
}

.glyphicon-object-align-left:before {
    content: "";
}

.glyphicon-object-align-vertical:before {
    content: "";
}

.glyphicon-object-align-right:before {
    content: "";
}

.glyphicon-triangle-right:before {
    content: "";
}

.glyphicon-triangle-left:before {
    content: "";
}

.glyphicon-triangle-bottom:before {
    content: "";
}

.glyphicon-triangle-top:before {
    content: "";
}

.glyphicon-console:before {
    content: "";
}

.glyphicon-superscript:before {
    content: "";
}

.glyphicon-subscript:before {
    content: "";
}

.glyphicon-menu-left:before {
    content: "";
}

.glyphicon-menu-right:before {
    content: "";
}

.glyphicon-menu-down:before {
    content: "";
}

.glyphicon-menu-up:before {
    content: "";
}

*,
:after,
:before {
    box-sizing: border-box;
}

html {
    font-size: 10px;
}

body {
    font-family: Raleway, sans-serif;
    font-size: 14px;
    line-height: 1.6;
    color: rgb(99, 107, 111);
    background-color: rgb(245, 248, 250);
}

button,
input,
select,
textarea {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}

a {
    color: rgb(48, 151, 209);
    text-decoration-line: none;
}

a:focus,
a:hover {
    color: rgb(33, 106, 148);
    text-decoration-line: underline;
}

a:focus {
    outline-width: 5px;
}

figure {
    margin-top: 0;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 0;
}

img {
    vertical-align: middle;
}

.img-responsive {
    display: block;
    max-width: 100%;
    height: auto;
}

.img-rounded {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
}

.img-thumbnail {
    padding-top: 4px;
    padding-right: 4px;
    padding-bottom: 4px;
    padding-left: 4px;
    line-height: 1.6;
    background-color: rgb(245, 248, 250);
    border-top-color: rgb(221, 221, 221);
    border-top-style: solid;
    border-top-width: 1px;
    border-right-color: rgb(221, 221, 221);
    border-right-style: solid;
    border-right-width: 1px;
    border-bottom-color: rgb(221, 221, 221);
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-left-color: rgb(221, 221, 221);
    border-left-style: solid;
    border-left-width: 1px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
    display: inline-block;
    max-width: 100%;
    height: auto;
}

.img-circle {
    border-top-left-radius: 50%;
    border-top-right-radius: 50%;
    border-bottom-right-radius: 50%;
    border-bottom-left-radius: 50%;
}

hr {
    margin-top: 22px;
    margin-bottom: 22px;
    border-top-color: rgb(238, 238, 238);
    border-top-style: solid;
    border-top-width: 1px;
    border-right-color: initial;
    border-right-style: initial;
    border-right-width: 0;
    border-bottom-color: initial;
    border-bottom-style: initial;
    border-bottom-width: 0;
    border-left-color: initial;
    border-left-style: initial;
    border-left-width: 0;
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    margin-top: -1px;
    margin-right: -1px;
    margin-bottom: -1px;
    margin-left: -1px;
    padding-top: 0;
    padding-right: 0;
    padding-bottom: 0;
    padding-left: 0;
    overflow-x: hidden;
    overflow-y: hidden;
    clip: rect(0, 0, 0, 0);
    border-top-color: initial;
    border-top-style: initial;
    border-top-width: 0;
    border-right-color: initial;
    border-right-style: initial;
    border-right-width: 0;
    border-bottom-color: initial;
    border-bottom-style: initial;
    border-bottom-width: 0;
    border-left-color: initial;
    border-left-style: initial;
    border-left-width: 0;
}

.sr-only-focusable:active,
.sr-only-focusable:focus {
    position: static;
    width: auto;
    height: auto;
    margin-top: 0;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 0;
    overflow-x: visible;
    overflow-y: visible;
    clip: auto;
}

[role="button"] {
    cursor: pointer;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: inherit;
    font-weight: 500;
    line-height: 1.1;
    color: inherit;
}

.h1 .small,
.h1 small,
.h2 .small,
.h2 small,
.h3 .small,
.h3 small,
.h4 .small,
.h4 small,
.h5 .small,
.h5 small,
.h6 .small,
.h6 small,
h1 .small,
h1 small,
h2 .small,
h2 small,
h3 .small,
h3 small,
h4 .small,
h4 small,
h5 .small,
h5 small,
h6 .small,
h6 small {
    font-weight: 400;
    line-height: 1;
    color: rgb(119, 119, 119);
}

.h1,
.h2,
.h3,
h1,
h2,
h3 {
    margin-top: 22px;
    margin-bottom: 11px;
}

.h1 .small,
.h1 small,
.h2 .small,
.h2 small,
.h3 .small,
.h3 small,
h1 .small,
h1 small,
h2 .small,
h2 small,
h3 .small,
h3 small {
    font-size: 65%;
}

.h4,
.h5,
.h6,
h4,
h5,
h6 {
    margin-top: 11px;
    margin-bottom: 11px;
}

.h4 .small,
.h4 small,
.h5 .small,
.h5 small,
.h6 .small,
.h6 small,
h4 .small,
h4 small,
h5 .small,
h5 small,
h6 .small,
h6 small {
    font-size: 75%;
}

.h1,
h1 {
    font-size: 36px;
}

.h2,
h2 {
    font-size: 30px;
}

.h3,
h3 {
    font-size: 24px;
}

.h4,
h4 {
    font-size: 18px;
}

.h5,
h5 {
    font-size: 14px;
}

.h6,
h6 {
    font-size: 12px;
}

p {
    margin-top: 0;
    margin-right: 0;
    margin-bottom: 11px;
    margin-left: 0;
}

.lead {
    margin-bottom: 22px;
    font-size: 16px;
    font-weight: 300;
    line-height: 1.4;
}

@media (min-width: 768px) {
    .lead {
        font-size: 21px;
    }
}

.small,
small {
    font-size: 85%;
}

.mark,
mark {
    background-color: rgb(252, 248, 227);
    padding-top: 0.2em;
    padding-right: 0.2em;
    padding-bottom: 0.2em;
    padding-left: 0.2em;
}

.text-left {
    text-align: left;
}


.text-right {
    text-align: right;
}

.text-center {
    text-align: center;
}

.text-justify {
    text-align: justify;
}

.text-nowrap {
    white-space: nowrap;
}

.text-lowercase {
    text-transform: lowercase;
}

.initialism,
.text-uppercase {
    text-transform: uppercase;
}

.text-capitalize {
    text-transform: capitalize;
}

.text-muted {
    color: rgb(119, 119, 119);
}

.text-primary {
    color: rgb(48, 151, 209);
}

a.text-primary:focus,
a.text-primary:hover {
    color: rgb(37, 121, 169);
}

.text-success {
    color: rgb(60, 118, 61);
}

a.text-success:focus,
a.text-success:hover {
    color: rgb(43, 84, 44);
}

.text-info {
    color: rgb(49, 112, 143);
}

a.text-info:focus,
a.text-info:hover {
    color: rgb(36, 82, 105);
}

.text-warning {
    color: rgb(138, 109, 59);
}

a.text-warning:focus,
a.text-warning:hover {
    color: rgb(102, 81, 44);
}

.text-danger {
    color: rgb(169, 68, 66);
}

a.text-danger:focus,
a.text-danger:hover {
    color: rgb(132, 53, 52);
}

.bg-primary {
    color: rgb(255, 255, 255);
    background-color: rgb(48, 151, 209);
}

a.bg-primary:focus,
a.bg-primary:hover {
    background-color: rgb(37, 121, 169);
}

.bg-success {
    background-color: rgb(223, 240, 216);
}

a.bg-success:focus,
a.bg-success:hover {
    background-color: rgb(193, 226, 179);
}

.bg-info {
    background-color: rgb(217, 237, 247);
}

a.bg-info:focus,
a.bg-info:hover {
    background-color: rgb(175, 217, 238);
}

.bg-warning {
    background-color: rgb(252, 248, 227);
}

a.bg-warning:focus,
a.bg-warning:hover {
    background-color: rgb(247, 236, 181);
}

.bg-danger {
    background-color: rgb(242, 222, 222);
}

a.bg-danger:focus,
a.bg-danger:hover {
    background-color: rgb(228, 185, 185);
}

.page-header {
    padding-bottom: 10px;
    margin-top: 44px;
    margin-right: 0;
    margin-bottom: 22px;
    margin-left: 0;
    border-bottom-color: rgb(238, 238, 238);
    border-bottom-style: solid;
    border-bottom-width: 1px;
}

ol,
ul {
    margin-top: 0;
    margin-bottom: 11px;
}

ol ol,
ol ul,
ul ol,
ul ul {
    margin-bottom: 0;
}

.list-inline,
.list-unstyled {
    padding-left: 0;
    list-style-type: none;
    list-style-image: none;
}

.list-inline {
    margin-left: -5px;
}

.list-inline>li {
    display: inline-block;
    padding-left: 5px;
    padding-right: 5px;
}

dl {
    margin-top: 0;
    margin-bottom: 22px;
}

dd,
dt {
    line-height: 1.6;
}

dt {
    font-weight: 700;
}

dd {
    margin-left: 0;
}

.dl-horizontal dd:after,
.dl-horizontal dd:before {
    content: " ";
    display: table;
}

.dl-horizontal dd:after {
    clear: both;
}

@media (min-width: 768px) {
    .dl-horizontal dt {
        float: left;
        width: 160px;
        clear: left;
        text-align: right;
        overflow-x: hidden;
        overflow-y: hidden;
        white-space: nowrap;
    }

    .dl-horizontal dd {
        margin-left: 180px;
    }
}

abbr[data-original-title],
abbr[title] {
    cursor: help;
    border-bottom-color: rgb(119, 119, 119);
    border-bottom-style: dotted;
    border-bottom-width: 1px;
}

.initialism {
    font-size: 90%;
}

blockquote {
    padding-top: 11px;
    padding-right: 22px;
    padding-bottom: 11px;
    padding-left: 22px;
    margin-top: 0;
    margin-right: 0;
    margin-bottom: 22px;
    margin-left: 0;
    font-size: 17.5px;
    border-left-color: rgb(238, 238, 238);
    border-left-style: solid;
    border-left-width: 5px;
}

blockquote ol:last-child,
blockquote p:last-child,
blockquote ul:last-child {
    margin-bottom: 0;
}

blockquote .small,
blockquote footer,
blockquote small {
    display: block;
    font-size: 80%;
    line-height: 1.6;
    color: rgb(119, 119, 119);
}

blockquote .small:before,
blockquote footer:before,
blockquote small:before {
    content: "—   ";
}

.blockquote-reverse,
blockquote.pull-right {
    padding-right: 15px;
    padding-left: 0;
    border-right-color: rgb(238, 238, 238);
    border-right-style: solid;
    border-right-width: 5px;
    border-left-color: initial;
    border-left-style: initial;
    border-left-width: 0;
    text-align: right;
}

.blockquote-reverse .small:before,
.blockquote-reverse footer:before,
.blockquote-reverse small:before,
blockquote.pull-right .small:before,
blockquote.pull-right footer:before,
blockquote.pull-right small:before {
    content: "";
}

.blockquote-reverse .small:after,
.blockquote-reverse footer:after,
.blockquote-reverse small:after,
blockquote.pull-right .small:after,
blockquote.pull-right footer:after,
blockquote.pull-right small:after {
    content: "   —";
}

address {
    margin-bottom: 22px;
    font-style: normal;
    line-height: 1.6;
}

code,
kbd,
pre,
samp {
    font-family: Menlo, Monaco, Consolas, Courier New, monospace;
}

code {
    color: rgb(199, 37, 78);
    background-color: rgb(249, 242, 244);
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
}

code,
kbd {
    padding-top: 2px;
    padding-right: 4px;
    padding-bottom: 2px;
    padding-left: 4px;
    font-size: 90%;
}

kbd {
    color: rgb(255, 255, 255);
    background-color: rgb(51, 51, 51);
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.25);
}

kbd kbd {
    padding-top: 0;
    padding-right: 0;
    padding-bottom: 0;
    padding-left: 0;
    font-size: 100%;
    font-weight: 700;
    box-shadow: none;
}

pre {
    display: block;
    padding-top: 10.5px;
    padding-right: 10.5px;
    padding-bottom: 10.5px;
    padding-left: 10.5px;
    margin-top: 0;
    margin-right: 0;
    margin-bottom: 11px;
    margin-left: 0;
    font-size: 13px;
    line-height: 1.6;
    word-break: break-all;
    word-wrap: break-word;
    color: rgb(51, 51, 51);
    background-color: rgb(245, 245, 245);
    border-top-color: rgb(204, 204, 204);
    border-top-style: solid;
    border-top-width: 1px;
    border-right-color: rgb(204, 204, 204);
    border-right-style: solid;
    border-right-width: 1px;
    border-bottom-color: rgb(204, 204, 204);
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-left-color: rgb(204, 204, 204);
    border-left-style: solid;
    border-left-width: 1px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
}

pre code {
    padding-top: 0;
    padding-right: 0;
    padding-bottom: 0;
    padding-left: 0;
    font-size: inherit;
    color: inherit;
    white-space: pre-wrap;
    background-color: transparent;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.pre-scrollable {
    max-height: 340px;
    overflow-y: scroll;
}

.container {
    margin-right: auto;
    margin-left: auto;
    padding-left: 15px;
    padding-right: 15px;
}

.container:after,
.container:before {
    content: " ";
    display: table;
}

.container:after {
    clear: both;
}

@media (min-width: 768px) {
    .container {
        width: 750px;
    }
}

@media (min-width: 992px) {
    .container {
        width: 970px;
    }
}

@media (min-width: 1200px) {
    .container {
        width: 1170px;
    }
}

.container-fluid {
    margin-right: auto;
    margin-left: auto;
    padding-left: 15px;
    padding-right: 15px;
}

.container-fluid:after,
.container-fluid:before {
    content: " ";
    display: table;
}

.container-fluid:after {
    clear: both;
}

.row {
    margin-left: -15px;
    margin-right: -15px;
}

.row:after,
.row:before {
    content: " ";
    display: table;
}

.row:after {
    clear: both;
}

.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-xs-1,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9,
.col-xs-10,
.col-xs-11,
.col-xs-12 {
    position: relative;
    min-height: 1px;
    padding-left: 15px;
    padding-right: 15px;
}

.col-xs-1,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9,
.col-xs-10,
.col-xs-11,
.col-xs-12 {
    float: left;
}

.col-xs-1 {
    width: 8.333333%;
}

.col-xs-2 {
    width: 16.666666%;
}

.col-xs-3 {
    width: 25%;
}

.col-xs-4 {
    width: 33.333332%;
}

.col-xs-5 {
    width: 41.666668%;
}

.col-xs-6 {
    width: 50%;
}

.col-xs-7 {
    width: 58.333332%;
}

.col-xs-8 {
    width: 66.666664%;
}

.col-xs-9 {
    width: 75%;
}

.col-xs-10 {
    width: 83.333336%;
}

.col-xs-11 {
    width: 91.666664%;
}

.col-xs-12 {
    width: 100%;
}

.col-xs-pull-0 {
    right: auto;
}

.col-xs-pull-1 {
    right: 8.333333%;
}

.col-xs-pull-2 {
    right: 16.666666%;
}

.col-xs-pull-3 {
    right: 25%;
}

.col-xs-pull-4 {
    right: 33.333332%;
}

.col-xs-pull-5 {
    right: 41.666668%;
}

.col-xs-pull-6 {
    right: 50%;
}

.col-xs-pull-7 {
    right: 58.333332%;
}

.col-xs-pull-8 {
    right: 66.666664%;
}

.col-xs-pull-9 {
    right: 75%;
}

.col-xs-pull-10 {
    right: 83.333336%;
}

.col-xs-pull-11 {
    right: 91.666664%;
}

.col-xs-pull-12 {
    right: 100%;
}

.col-xs-push-0 {
    left: auto;
}

.col-xs-push-1 {
    left: 8.333333%;
}

.col-xs-push-2 {
    left: 16.666666%;
}

.col-xs-push-3 {
    left: 25%;
}

.col-xs-push-4 {
    left: 33.333332%;
}

.col-xs-push-5 {
    left: 41.666668%;
}

.col-xs-push-6 {
    left: 50%;
}

.col-xs-push-7 {
    left: 58.333332%;
}

.col-xs-push-8 {
    left: 66.666664%;
}

.col-xs-push-9 {
    left: 75%;
}

.col-xs-push-10 {
    left: 83.333336%;
}

.col-xs-push-11 {
    left: 91.666664%;
}

.col-xs-push-12 {
    left: 100%;
}

.col-xs-offset-0 {
    margin-left: 0;
}

.col-xs-offset-1 {
    margin-left: 8.333333%;
}

.col-xs-offset-2 {
    margin-left: 16.666666%;
}

.col-xs-offset-3 {
    margin-left: 25%;
}

.col-xs-offset-4 {
    margin-left: 33.333332%;
}

.col-xs-offset-5 {
    margin-left: 41.666668%;
}

.col-xs-offset-6 {
    margin-left: 50%;
}

.col-xs-offset-7 {
    margin-left: 58.333332%;
}

.col-xs-offset-8 {
    margin-left: 66.666664%;
}

.col-xs-offset-9 {
    margin-left: 75%;
}

.col-xs-offset-10 {
    margin-left: 83.333336%;
}

.col-xs-offset-11 {
    margin-left: 91.666664%;
}

.col-xs-offset-12 {
    margin-left: 100%;
}

@media (min-width: 768px) {

    .col-sm-1,
    .col-sm-2,
    .col-sm-3,
    .col-sm-4,
    .col-sm-5,
    .col-sm-6,
    .col-sm-7,
    .col-sm-8,
    .col-sm-9,
    .col-sm-10,
    .col-sm-11,
    .col-sm-12 {
        float: left;
    }

    .col-sm-1 {
        width: 8.333333%;
    }

    .col-sm-2 {
        width: 16.666666%;
    }

    .col-sm-3 {
        width: 25%;
    }

    .col-sm-4 {
        width: 33.333332%;
    }

    .col-sm-5 {
        width: 41.666668%;
    }

    .col-sm-6 {
        width: 50%;
    }

    .col-sm-7 {
        width: 58.333332%;
    }

    .col-sm-8 {
        width: 66.666664%;
    }

    .col-sm-9 {
        width: 75%;
    }

    .col-sm-10 {
        width: 83.333336%;
    }

    .col-sm-11 {
        width: 91.666664%;
    }

    .col-sm-12 {
        width: 100%;
    }

    .col-sm-pull-0 {
        right: auto;
    }

    .col-sm-pull-1 {
        right: 8.333333%;
    }

    .col-sm-pull-2 {
        right: 16.666666%;
    }

    .col-sm-pull-3 {
        right: 25%;
    }

    .col-sm-pull-4 {
        right: 33.333332%;
    }

    .col-sm-pull-5 {
        right: 41.666668%;
    }

    .col-sm-pull-6 {
        right: 50%;
    }

    .col-sm-pull-7 {
        right: 58.333332%;
    }

    .col-sm-pull-8 {
        right: 66.666664%;
    }

    .col-sm-pull-9 {
        right: 75%;
    }

    .col-sm-pull-10 {
        right: 83.333336%;
    }

    .col-sm-pull-11 {
        right: 91.666664%;
    }

    .col-sm-pull-12 {
        right: 100%;
    }

    .col-sm-push-0 {
        left: auto;
    }

    .col-sm-push-1 {
        left: 8.333333%;
    }

    .col-sm-push-2 {
        left: 16.666666%;
    }

    .col-sm-push-3 {
        left: 25%;
    }

    .col-sm-push-4 {
        left: 33.333332%;
    }

    .col-sm-push-5 {
        left: 41.666668%;
    }

    .col-sm-push-6 {
        left: 50%;
    }

    .col-sm-push-7 {
        left: 58.333332%;
    }

    .col-sm-push-8 {
        left: 66.666664%;
    }

    .col-sm-push-9 {
        left: 75%;
    }

    .col-sm-push-10 {
        left: 83.333336%;
    }

    .col-sm-push-11 {
        left: 91.666664%;
    }

    .col-sm-push-12 {
        left: 100%;
    }

    .col-sm-offset-0 {
        margin-left: 0;
    }

    .col-sm-offset-1 {
        margin-left: 8.333333%;
    }

    .col-sm-offset-2 {
        margin-left: 16.666666%;
    }

    .col-sm-offset-3 {
        margin-left: 25%;
    }

    .col-sm-offset-4 {
        margin-left: 33.333332%;
    }

    .col-sm-offset-5 {
        margin-left: 41.666668%;
    }

    .col-sm-offset-6 {
        margin-left: 50%;
    }

    .col-sm-offset-7 {
        margin-left: 58.333332%;
    }

    .col-sm-offset-8 {
        margin-left: 66.666664%;
    }

    .col-sm-offset-9 {
        margin-left: 75%;
    }

    .col-sm-offset-10 {
        margin-left: 83.333336%;
    }

    .col-sm-offset-11 {
        margin-left: 91.666664%;
    }

    .col-sm-offset-12 {
        margin-left: 100%;
    }
}

@media (min-width: 992px) {

    .col-md-1,
    .col-md-2,
    .col-md-3,
    .col-md-4,
    .col-md-5,
    .col-md-6,
    .col-md-7,
    .col-md-8,
    .col-md-9,
    .col-md-10,
    .col-md-11,
    .col-md-12 {
        float: left;
    }

    .col-md-1 {
        width: 8.333333%;
    }

    .col-md-2 {
        width: 16.666666%;
    }

    .col-md-3 {
        width: 25%;
    }

    .col-md-4 {
        width: 33.333332%;
    }

    .col-md-5 {
        width: 41.666668%;
    }

    .col-md-6 {
        width: 50%;
    }

    .col-md-7 {
        width: 58.333332%;
    }

    .col-md-8 {
        width: 66.666664%;
    }

    .col-md-9 {
        width: 75%;
    }

    .col-md-10 {
        width: 83.333336%;
    }

    .col-md-11 {
        width: 91.666664%;
    }

    .col-md-12 {
        width: 100%;
    }

    .col-md-pull-0 {
        right: auto;
    }

    .col-md-pull-1 {
        right: 8.333333%;
    }

    .col-md-pull-2 {
        right: 16.666666%;
    }

    .col-md-pull-3 {
        right: 25%;
    }

    .col-md-pull-4 {
        right: 33.333332%;
    }

    .col-md-pull-5 {
        right: 41.666668%;
    }

    .col-md-pull-6 {
        right: 50%;
    }

    .col-md-pull-7 {
        right: 58.333332%;
    }

    .col-md-pull-8 {
        right: 66.666664%;
    }

    .col-md-pull-9 {
        right: 75%;
    }

    .col-md-pull-10 {
        right: 83.333336%;
    }

    .col-md-pull-11 {
        right: 91.666664%;
    }

    .col-md-pull-12 {
        right: 100%;
    }

    .col-md-push-0 {
        left: auto;
    }

    .col-md-push-1 {
        left: 8.333333%;
    }

    .col-md-push-2 {
        left: 16.666666%;
    }

    .col-md-push-3 {
        left: 25%;
    }

    .col-md-push-4 {
        left: 33.333332%;
    }

    .col-md-push-5 {
        left: 41.666668%;
    }

    .col-md-push-6 {
        left: 50%;
    }

    .col-md-push-7 {
        left: 58.333332%;
    }

    .col-md-push-8 {
        left: 66.666664%;
    }

    .col-md-push-9 {
        left: 75%;
    }

    .col-md-push-10 {
        left: 83.333336%;
    }

    .col-md-push-11 {
        left: 91.666664%;
    }

    .col-md-push-12 {
        left: 100%;
    }

    .col-md-offset-0 {
        margin-left: 0;
    }

    .col-md-offset-1 {
        margin-left: 8.333333%;
    }

    .col-md-offset-2 {
        margin-left: 16.666666%;
    }

    .col-md-offset-3 {
        margin-left: 25%;
    }

    .col-md-offset-4 {
        margin-left: 33.333332%;
    }

    .col-md-offset-5 {
        margin-left: 41.666668%;
    }

    .col-md-offset-6 {
        margin-left: 50%;
    }

    .col-md-offset-7 {
        margin-left: 58.333332%;
    }

    .col-md-offset-8 {
        margin-left: 66.666664%;
    }

    .col-md-offset-9 {
        margin-left: 75%;
    }

    .col-md-offset-10 {
        margin-left: 83.333336%;
    }

    .col-md-offset-11 {
        margin-left: 91.666664%;
    }

    .col-md-offset-12 {
        margin-left: 100%;
    }
}

@media (min-width: 1200px) {

    .col-lg-1,
    .col-lg-2,
    .col-lg-3,
    .col-lg-4,
    .col-lg-5,
    .col-lg-6,
    .col-lg-7,
    .col-lg-8,
    .col-lg-9,
    .col-lg-10,
    .col-lg-11,
    .col-lg-12 {
        float: left;
    }

    .col-lg-1 {
        width: 8.333333%;
    }

    .col-lg-2 {
        width: 16.666666%;
    }

    .col-lg-3 {
        width: 25%;
    }

    .col-lg-4 {
        width: 33.333332%;
    }

    .col-lg-5 {
        width: 41.666668%;
    }

    .col-lg-6 {
        width: 50%;
    }

    .col-lg-7 {
        width: 58.333332%;
    }

    .col-lg-8 {
        width: 66.666664%;
    }

    .col-lg-9 {
        width: 75%;
    }

    .col-lg-10 {
        width: 83.333336%;
    }

    .col-lg-11 {
        width: 91.666664%;
    }

    .col-lg-12 {
        width: 100%;
    }

    .col-lg-pull-0 {
        right: auto;
    }

    .col-lg-pull-1 {
        right: 8.333333%;
    }

    .col-lg-pull-2 {
        right: 16.666666%;
    }

    .col-lg-pull-3 {
        right: 25%;
    }

    .col-lg-pull-4 {
        right: 33.333332%;
    }

    .col-lg-pull-5 {
        right: 41.666668%;
    }

    .col-lg-pull-6 {
        right: 50%;
    }

    .col-lg-pull-7 {
        right: 58.333332%;
    }

    .col-lg-pull-8 {
        right: 66.666664%;
    }

    .col-lg-pull-9 {
        right: 75%;
    }

    .col-lg-pull-10 {
        right: 83.333336%;
    }

    .col-lg-pull-11 {
        right: 91.666664%;
    }

    .col-lg-pull-12 {
        right: 100%;
    }

    .col-lg-push-0 {
        left: auto;
    }

    .col-lg-push-1 {
        left: 8.333333%;
    }

    .col-lg-push-2 {
        left: 16.666666%;
    }

    .col-lg-push-3 {
        left: 25%;
    }

    .col-lg-push-4 {
        left: 33.333332%;
    }

    .col-lg-push-5 {
        left: 41.666668%;
    }

    .col-lg-push-6 {
        left: 50%;
    }

    .col-lg-push-7 {
        left: 58.333332%;
    }

    .col-lg-push-8 {
        left: 66.666664%;
    }

    .col-lg-push-9 {
        left: 75%;
    }

    .col-lg-push-10 {
        left: 83.333336%;
    }

    .col-lg-push-11 {
        left: 91.666664%;
    }

    .col-lg-push-12 {
        left: 100%;
    }

    .col-lg-offset-0 {
        margin-left: 0;
    }

    .col-lg-offset-1 {
        margin-left: 8.333333%;
    }

    .col-lg-offset-2 {
        margin-left: 16.666666%;
    }

    .col-lg-offset-3 {
        margin-left: 25%;
    }

    .col-lg-offset-4 {
        margin-left: 33.333332%;
    }

    .col-lg-offset-5 {
        margin-left: 41.666668%;
    }

    .col-lg-offset-6 {
        margin-left: 50%;
    }

    .col-lg-offset-7 {
        margin-left: 58.333332%;
    }

    .col-lg-offset-8 {
        margin-left: 66.666664%;
    }

    .col-lg-offset-9 {
        margin-left: 75%;
    }

    .col-lg-offset-10 {
        margin-left: 83.333336%;
    }

    .col-lg-offset-11 {
        margin-left: 91.666664%;
    }

    .col-lg-offset-12 {
        margin-left: 100%;
    }
}

table {
    background-color: transparent;
}

caption {
    padding-top: 8px;
    padding-bottom: 8px;
    color: rgb(119, 119, 119);
}

caption,
th {
    text-align: left;
}

.table {
    width: 100%;
    max-width: 100%;
    margin-bottom: 22px;
}

.table>tbody>tr>td,
.table>tbody>tr>th,
.table>tfoot>tr>td,
.table>tfoot>tr>th,
.table>thead>tr>td,
.table>thead>tr>th {
    padding-top: 8px;
    padding-right: 8px;
    padding-bottom: 8px;
    padding-left: 8px;
    line-height: 1.6;
    vertical-align: top;
    border-top-color: rgb(221, 221, 221);
    border-top-style: solid;
    border-top-width: 1px;
}

.table>thead>tr>th {
    vertical-align: bottom;
    border-bottom-color: rgb(221, 221, 221);
    border-bottom-style: solid;
    border-bottom-width: 2px;
}

.table>caption+thead>tr:first-child>td,
.table>caption+thead>tr:first-child>th,
.table>colgroup+thead>tr:first-child>td,
.table>colgroup+thead>tr:first-child>th,
.table>thead:first-child>tr:first-child>td,
.table>thead:first-child>tr:first-child>th {
    border-top-color: initial;
    border-top-style: initial;
    border-top-width: 0;
}

.table>tbody+tbody {
    border-top-color: rgb(221, 221, 221);
    border-top-style: solid;
    border-top-width: 2px;
}

.table .table {
    background-color: rgb(245, 248, 250);
}

.table-condensed>tbody>tr>td,
.table-condensed>tbody>tr>th,
.table-condensed>tfoot>tr>td,
.table-condensed>tfoot>tr>th,
.table-condensed>thead>tr>td,
.table-condensed>thead>tr>th {
    padding-top: 5px;
    padding-right: 5px;
    padding-bottom: 5px;
    padding-left: 5px;
}

.table-bordered,
.table-bordered>tbody>tr>td,
.table-bordered>tbody>tr>th,
.table-bordered>tfoot>tr>td,
.table-bordered>tfoot>tr>th,
.table-bordered>thead>tr>td,
.table-bordered>thead>tr>th {
    border-top-color: rgb(221, 221, 221);
    border-top-style: solid;
    border-top-width: 1px;
    border-right-color: rgb(221, 221, 221);
    border-right-style: solid;
    border-right-width: 1px;
    border-bottom-color: rgb(221, 221, 221);
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-left-color: rgb(221, 221, 221);
    border-left-style: solid;
    border-left-width: 1px;
}

.table-bordered>thead>tr>td,
.table-bordered>thead>tr>th {
    border-bottom-width: 2px;
}

.table-striped>tbody>tr:nth-of-type(2n+1) {
    background-color: rgb(249, 249, 249);
}

.table-hover>tbody>tr:hover {
    background-color: rgb(245, 245, 245);
}

table col[class*="col-"] {
    position: static;
    float: none;
    display: table-column;
}

table td[class*="col-"],
table th[class*="col-"] {
    position: static;
    float: none;
    display: table-cell;
}

.table>tbody>tr.active>td,
.table>tbody>tr.active>th,
.table>tbody>tr>td.active,
.table>tbody>tr>th.active,
.table>tfoot>tr.active>td,
.table>tfoot>tr.active>th,
.table>tfoot>tr>td.active,
.table>tfoot>tr>th.active,
.table>thead>tr.active>td,
.table>thead>tr.active>th,
.table>thead>tr>td.active,
.table>thead>tr>th.active {
    background-color: rgb(245, 245, 245);
}

.table-hover>tbody>tr.active:hover>td,
.table-hover>tbody>tr.active:hover>th,
.table-hover>tbody>tr:hover>.active,
.table-hover>tbody>tr>td.active:hover,
.table-hover>tbody>tr>th.active:hover {
    background-color: rgb(232, 232, 232);
}

.table>tbody>tr.success>td,
.table>tbody>tr.success>th,
.table>tbody>tr>td.success,
.table>tbody>tr>th.success,
.table>tfoot>tr.success>td,
.table>tfoot>tr.success>th,
.table>tfoot>tr>td.success,
.table>tfoot>tr>th.success,
.table>thead>tr.success>td,
.table>thead>tr.success>th,
.table>thead>tr>td.success,
.table>thead>tr>th.success {
    background-color: rgb(223, 240, 216);
}

.table-hover>tbody>tr.success:hover>td,
.table-hover>tbody>tr.success:hover>th,
.table-hover>tbody>tr:hover>.success,
.table-hover>tbody>tr>td.success:hover,
.table-hover>tbody>tr>th.success:hover {
    background-color: rgb(208, 233, 198);
}

.table>tbody>tr.info>td,
.table>tbody>tr.info>th,
.table>tbody>tr>td.info,
.table>tbody>tr>th.info,
.table>tfoot>tr.info>td,
.table>tfoot>tr.info>th,
.table>tfoot>tr>td.info,
.table>tfoot>tr>th.info,
.table>thead>tr.info>td,
.table>thead>tr.info>th,
.table>thead>tr>td.info,
.table>thead>tr>th.info {
    background-color: rgb(217, 237, 247);
}

.table-hover>tbody>tr.info:hover>td,
.table-hover>tbody>tr.info:hover>th,
.table-hover>tbody>tr:hover>.info,
.table-hover>tbody>tr>td.info:hover,
.table-hover>tbody>tr>th.info:hover {
    background-color: rgb(196, 227, 243);
}

.table>tbody>tr.warning>td,
.table>tbody>tr.warning>th,
.table>tbody>tr>td.warning,
.table>tbody>tr>th.warning,
.table>tfoot>tr.warning>td,
.table>tfoot>tr.warning>th,
.table>tfoot>tr>td.warning,
.table>tfoot>tr>th.warning,
.table>thead>tr.warning>td,
.table>thead>tr.warning>th,
.table>thead>tr>td.warning,
.table>thead>tr>th.warning {
    background-color: rgb(252, 248, 227);
}

.table-hover>tbody>tr.warning:hover>td,
.table-hover>tbody>tr.warning:hover>th,
.table-hover>tbody>tr:hover>.warning,
.table-hover>tbody>tr>td.warning:hover,
.table-hover>tbody>tr>th.warning:hover {
    background-color: rgb(250, 242, 204);
}

.table>tbody>tr.danger>td,
.table>tbody>tr.danger>th,
.table>tbody>tr>td.danger,
.table>tbody>tr>th.danger,
.table>tfoot>tr.danger>td,
.table>tfoot>tr.danger>th,
.table>tfoot>tr>td.danger,
.table>tfoot>tr>th.danger,
.table>thead>tr.danger>td,
.table>thead>tr.danger>th,
.table>thead>tr>td.danger,
.table>thead>tr>th.danger {
    background-color: rgb(242, 222, 222);
}

.table-hover>tbody>tr.danger:hover>td,
.table-hover>tbody>tr.danger:hover>th,
.table-hover>tbody>tr:hover>.danger,
.table-hover>tbody>tr>td.danger:hover,
.table-hover>tbody>tr>th.danger:hover {
    background-color: rgb(235, 204, 204);
}

.table-responsive {
    overflow-x: auto;
    min-height: 0.01%;
}

@media screen AND (max-width: 767px) {
    .table-responsive {
        width: 100%;
        margin-bottom: 16.5px;
        overflow-y: hidden;
        border-top-color: rgb(221, 221, 221);
        border-top-style: solid;
        border-top-width: 1px;
        border-right-color: rgb(221, 221, 221);
        border-right-style: solid;
        border-right-width: 1px;
        border-bottom-color: rgb(221, 221, 221);
        border-bottom-style: solid;
        border-bottom-width: 1px;
        border-left-color: rgb(221, 221, 221);
        border-left-style: solid;
        border-left-width: 1px;
    }

    .table-responsive>.table {
        margin-bottom: 0;
    }

    .table-responsive>.table>tbody>tr>td,
    .table-responsive>.table>tbody>tr>th,
    .table-responsive>.table>tfoot>tr>td,
    .table-responsive>.table>tfoot>tr>th,
    .table-responsive>.table>thead>tr>td,
    .table-responsive>.table>thead>tr>th {
        white-space: nowrap;
    }

    .table-responsive>.table-bordered {
        border-top-color: initial;
        border-top-style: initial;
        border-top-width: 0;
        border-right-color: initial;
        border-right-style: initial;
        border-right-width: 0;
        border-bottom-color: initial;
        border-bottom-style: initial;
        border-bottom-width: 0;
        border-left-color: initial;
        border-left-style: initial;
        border-left-width: 0;
    }

    .table-responsive>.table-bordered>tbody>tr>td:first-child,
    .table-responsive>.table-bordered>tbody>tr>th:first-child,
    .table-responsive>.table-bordered>tfoot>tr>td:first-child,
    .table-responsive>.table-bordered>tfoot>tr>th:first-child,
    .table-responsive>.table-bordered>thead>tr>td:first-child,
    .table-responsive>.table-bordered>thead>tr>th:first-child {
        border-left-color: initial;
        border-left-style: initial;
        border-left-width: 0;
    }

    .table-responsive>.table-bordered>tbody>tr>td:last-child,
    .table-responsive>.table-bordered>tbody>tr>th:last-child,
    .table-responsive>.table-bordered>tfoot>tr>td:last-child,
    .table-responsive>.table-bordered>tfoot>tr>th:last-child,
    .table-responsive>.table-bordered>thead>tr>td:last-child,
    .table-responsive>.table-bordered>thead>tr>th:last-child {
        border-right-color: initial;
        border-right-style: initial;
        border-right-width: 0;
    }

    .table-responsive>.table-bordered>tbody>tr:last-child>td,
    .table-responsive>.table-bordered>tbody>tr:last-child>th,
    .table-responsive>.table-bordered>tfoot>tr:last-child>td,
    .table-responsive>.table-bordered>tfoot>tr:last-child>th {
        border-bottom-color: initial;
        border-bottom-style: initial;
        border-bottom-width: 0;
    }
}

fieldset {
    margin-top: 0;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 0;
    min-width: 0px;
}

fieldset,
legend {
    padding-top: 0;
    padding-right: 0;
    padding-bottom: 0;
    padding-left: 0;
    border-top-color: initial;
    border-top-style: initial;
    border-top-width: 0;
    border-right-color: initial;
    border-right-style: initial;
    border-right-width: 0;
    border-bottom-color: initial;
    border-bottom-style: initial;
    border-bottom-width: 0;
    border-left-color: initial;
    border-left-style: initial;
    border-left-width: 0;
}

legend {
    display: block;
    width: 100%;
    margin-bottom: 22px;
    font-size: 21px;
    line-height: inherit;
    color: rgb(51, 51, 51);
    border-bottom-color: rgb(229, 229, 229);
    border-bottom-style: solid;
    border-bottom-width: 1px;
}

label {
    display: inline-block;
    max-width: 100%;
    margin-bottom: 5px;
    font-weight: 700;
}

input[type="search"] {
    box-sizing: border-box;
}

input[type="checkbox"],
input[type="radio"] {
    margin-top: 4px;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 0;
    line-height: normal;
}

input[type="file"] {
    display: block;
}

input[type="range"] {
    display: block;
    width: 100%;
}

select[multiple],
select[size] {
    height: auto;
}

input[type="checkbox"]:focus,
input[type="file"]:focus,
input[type="radio"]:focus {
    outline-width: 5px;
}

output {
    padding-top: 7px;
}

.form-control,
output {
    display: block;
    font-size: 14px;
    line-height: 1.6;
    color: rgb(85, 85, 85);
}

.form-control {
    width: 100%;
    height: 36px;
    padding-top: 6px;
    padding-right: 12px;
    padding-bottom: 6px;
    padding-left: 12px;
    background-color: rgb(255, 255, 255);
    background-image: none;
    border-top-color: rgb(204, 208, 210);
    border-top-style: solid;
    border-top-width: 1px;
    border-right-color: rgb(204, 208, 210);
    border-right-style: solid;
    border-right-width: 1px;
    border-bottom-color: rgb(204, 208, 210);
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-left-color: rgb(204, 208, 210);
    border-left-style: solid;
    border-left-width: 1px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.form-control:focus {
    border-top-color: rgb(152, 203, 232);
    border-right-color: rgb(152, 203, 232);
    border-bottom-color: rgb(152, 203, 232);
    border-left-color: rgb(152, 203, 232);
    outline-width: 0;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(152, 203, 232, 0.6);
}

.form-control:placeholder {
    color: rgb(177, 183, 186);
    opacity: 1;
}

.form-control:input-placeholder {
    color: rgb(177, 183, 186);
}

.form-control:input-placeholder {
    color: rgb(177, 183, 186);
}

.form-control:expand {
    border-top-color: initial;
    border-top-style: initial;
    border-top-width: 0;
    border-right-color: initial;
    border-right-style: initial;
    border-right-width: 0;
    border-bottom-color: initial;
    border-bottom-style: initial;
    border-bottom-width: 0;
    border-left-color: initial;
    border-left-style: initial;
    border-left-width: 0;
    background-color: transparent;
}

.form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control {
    background-color: rgb(238, 238, 238);
    opacity: 1;
}

.form-control[disabled],
fieldset[disabled] .form-control {}

textarea.form-control {
    height: auto;
}

input[type="search"] {}

@media NOT ALL {

    input[type="date"].form-control,
    input[type="datetime-local"].form-control,
    input[type="month"].form-control,
    input[type="time"].form-control {
        line-height: 36px;
    }

    .input-group-sm>.input-group-btn>input[type="date"].btn,
    .input-group-sm>.input-group-btn>input[type="datetime-local"].btn,
    .input-group-sm>.input-group-btn>input[type="month"].btn,
    .input-group-sm>.input-group-btn>input[type="time"].btn,
    .input-group-sm>input[type="date"].form-control,
    .input-group-sm>input[type="date"].input-group-addon,
    .input-group-sm>input[type="datetime-local"].form-control,
    .input-group-sm>input[type="datetime-local"].input-group-addon,
    .input-group-sm>input[type="month"].form-control,
    .input-group-sm>input[type="month"].input-group-addon,
    .input-group-sm>input[type="time"].form-control,
    .input-group-sm>input[type="time"].input-group-addon,
    .input-group-sm input[type="date"],
    .input-group-sm input[type="datetime-local"],
    .input-group-sm input[type="month"],
    .input-group-sm input[type="time"],
    input[type="date"].input-sm,
    input[type="datetime-local"].input-sm,
    input[type="month"].input-sm,
    input[type="time"].input-sm {
        line-height: 30px;
    }

    .input-group-lg>.input-group-btn>input[type="date"].btn,
    .input-group-lg>.input-group-btn>input[type="datetime-local"].btn,
    .input-group-lg>.input-group-btn>input[type="month"].btn,
    .input-group-lg>.input-group-btn>input[type="time"].btn,
    .input-group-lg>input[type="date"].form-control,
    .input-group-lg>input[type="date"].input-group-addon,
    .input-group-lg>input[type="datetime-local"].form-control,
    .input-group-lg>input[type="datetime-local"].input-group-addon,
    .input-group-lg>input[type="month"].form-control,
    .input-group-lg>input[type="month"].input-group-addon,
    .input-group-lg>input[type="time"].form-control,
    .input-group-lg>input[type="time"].input-group-addon,
    .input-group-lg input[type="date"],
    .input-group-lg input[type="datetime-local"],
    .input-group-lg input[type="month"],
    .input-group-lg input[type="time"],
    input[type="date"].input-lg,
    input[type="datetime-local"].input-lg,
    input[type="month"].input-lg,
    input[type="time"].input-lg {
        line-height: 46px;
    }
}

.form-group {
    margin-bottom: 15px;
}

.checkbox,
.radio {
    position: relative;
    display: block;
    margin-top: 10px;
    margin-bottom: 10px;
}

.checkbox label,
.radio label {
    min-height: 22px;
    padding-left: 20px;
    margin-bottom: 0;
    font-weight: 400;
    cursor: pointer;
}

.checkbox-inline input[type="checkbox"],
.checkbox input[type="checkbox"],
.radio-inline input[type="radio"],
.radio input[type="radio"] {
    position: absolute;
    margin-left: -20px;
}

.checkbox+.checkbox,
.radio+.radio {
    margin-top: -5px;
}

.checkbox-inline,
.radio-inline {
    position: relative;
    display: inline-block;
    padding-left: 20px;
    margin-bottom: 0;
    vertical-align: middle;
    font-weight: 400;
    cursor: pointer;
}

.checkbox-inline+.checkbox-inline,
.radio-inline+.radio-inline {
    margin-top: 0;
    margin-left: 10px;
}

.checkbox-inline.disabled,
.checkbox.disabled label,
.radio-inline.disabled,
.radio.disabled label,
fieldset[disabled] .checkbox-inline,
fieldset[disabled] .checkbox label,
fieldset[disabled] .radio-inline,
fieldset[disabled] .radio label,
fieldset[disabled] input[type="checkbox"],
fieldset[disabled] input[type="radio"],
input[type="checkbox"].disabled,
input[type="checkbox"][disabled],
input[type="radio"].disabled,
input[type="radio"][disabled] {}

.form-control-static {
    padding-top: 7px;
    padding-bottom: 7px;
    margin-bottom: 0;
    min-height: 36px;
}

.form-control-static.input-lg,
.form-control-static.input-sm,
.input-group-lg>.form-control-static.form-control,
.input-group-lg>.form-control-static.input-group-addon,
.input-group-lg>.input-group-btn>.form-control-static.btn,
.input-group-sm>.form-control-static.form-control,
.input-group-sm>.form-control-static.input-group-addon,
.input-group-sm>.input-group-btn>.form-control-static.btn {
    padding-left: 0;
    padding-right: 0;
}

.input-group-sm>.form-control,
.input-group-sm>.input-group-addon,
.input-group-sm>.input-group-btn>.btn,
.input-sm {
    height: 30px;
    padding-top: 5px;
    padding-right: 10px;
    padding-bottom: 5px;
    padding-left: 10px;
    font-size: 12px;
    line-height: 1.5;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
}

.input-group-sm>.input-group-btn>select.btn,
.input-group-sm>select.form-control,
.input-group-sm>select.input-group-addon,
select.input-sm {
    height: 30px;
    line-height: 30px;
}

.input-group-sm>.input-group-btn>select[multiple].btn,
.input-group-sm>.input-group-btn>textarea.btn,
.input-group-sm>select[multiple].form-control,
.input-group-sm>select[multiple].input-group-addon,
.input-group-sm>textarea.form-control,
.input-group-sm>textarea.input-group-addon,
select[multiple].input-sm,
textarea.input-sm {
    height: auto;
}

.form-group-sm .form-control {
    height: 30px;
    padding-top: 5px;
    padding-right: 10px;
    padding-bottom: 5px;
    padding-left: 10px;
    font-size: 12px;
    line-height: 1.5;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
}

.form-group-sm select.form-control {
    height: 30px;
    line-height: 30px;
}

.form-group-sm select[multiple].form-control,
.form-group-sm textarea.form-control {
    height: auto;
}

.form-group-sm .form-control-static {
    height: 30px;
    min-height: 34px;
    padding-top: 6px;
    padding-right: 10px;
    padding-bottom: 6px;
    padding-left: 10px;
    font-size: 12px;
    line-height: 1.5;
}

.input-group-lg>.form-control,
.input-group-lg>.input-group-addon,
.input-group-lg>.input-group-btn>.btn,
.input-lg {
    height: 46px;
    padding-top: 10px;
    padding-right: 16px;
    padding-bottom: 10px;
    padding-left: 16px;
    font-size: 18px;
    line-height: 1.3333333;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
}

.input-group-lg>.input-group-btn>select.btn,
.input-group-lg>select.form-control,
.input-group-lg>select.input-group-addon,
select.input-lg {
    height: 46px;
    line-height: 46px;
}

.input-group-lg>.input-group-btn>select[multiple].btn,
.input-group-lg>.input-group-btn>textarea.btn,
.input-group-lg>select[multiple].form-control,
.input-group-lg>select[multiple].input-group-addon,
.input-group-lg>textarea.form-control,
.input-group-lg>textarea.input-group-addon,
select[multiple].input-lg,
textarea.input-lg {
    height: auto;
}

.form-group-lg .form-control {
    height: 46px;
    padding-top: 10px;
    padding-right: 16px;
    padding-bottom: 10px;
    padding-left: 16px;
    font-size: 18px;
    line-height: 1.3333333;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
}

.form-group-lg select.form-control {
    height: 46px;
    line-height: 46px;
}

.form-group-lg select[multiple].form-control,
.form-group-lg textarea.form-control {
    height: auto;
}

.form-group-lg .form-control-static {
    height: 46px;
    min-height: 40px;
    padding-top: 11px;
    padding-right: 16px;
    padding-bottom: 11px;
    padding-left: 16px;
    font-size: 18px;
    line-height: 1.3333333;
}

.has-feedback {
    position: relative;
}

.has-feedback .form-control {
    padding-right: 45px;
}

.form-control-feedback {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    display: block;
    width: 36px;
    height: 36px;
    line-height: 36px;
    text-align: center;
    pointer-events: none;
}

.form-group-lg .form-control+.form-control-feedback,
.input-group-lg+.form-control-feedback,
.input-group-lg>.form-control+.form-control-feedback,
.input-group-lg>.input-group-addon+.form-control-feedback,
.input-group-lg>.input-group-btn>.btn+.form-control-feedback,
.input-lg+.form-control-feedback {
    width: 46px;
    height: 46px;
    line-height: 46px;
}

.form-group-sm .form-control+.form-control-feedback,
.input-group-sm+.form-control-feedback,
.input-group-sm>.form-control+.form-control-feedback,
.input-group-sm>.input-group-addon+.form-control-feedback,
.input-group-sm>.input-group-btn>.btn+.form-control-feedback,
.input-sm+.form-control-feedback {
    width: 30px;
    height: 30px;
    line-height: 30px;
}

.has-success .checkbox,
.has-success .checkbox-inline,
.has-success.checkbox-inline label,
.has-success.checkbox label,
.has-success .control-label,
.has-success .help-block,
.has-success .radio,
.has-success .radio-inline,
.has-success.radio-inline label,
.has-success.radio label {
    color: rgb(60, 118, 61);
}

.has-success .form-control {
    border-top-color: rgb(60, 118, 61);
    border-right-color: rgb(60, 118, 61);
    border-bottom-color: rgb(60, 118, 61);
    border-left-color: rgb(60, 118, 61);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.has-success .form-control:focus {
    border-top-color: rgb(43, 84, 44);
    border-right-color: rgb(43, 84, 44);
    border-bottom-color: rgb(43, 84, 44);
    border-left-color: rgb(43, 84, 44);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px rgb(103, 177, 104);
}

.has-success .input-group-addon {
    color: rgb(60, 118, 61);
    border-top-color: rgb(60, 118, 61);
    border-right-color: rgb(60, 118, 61);
    border-bottom-color: rgb(60, 118, 61);
    border-left-color: rgb(60, 118, 61);
    background-color: rgb(223, 240, 216);
}

.has-success .form-control-feedback {
    color: rgb(60, 118, 61);
}

.has-warning .checkbox,
.has-warning .checkbox-inline,
.has-warning.checkbox-inline label,
.has-warning.checkbox label,
.has-warning .control-label,
.has-warning .help-block,
.has-warning .radio,
.has-warning .radio-inline,
.has-warning.radio-inline label,
.has-warning.radio label {
    color: rgb(138, 109, 59);
}

.has-warning .form-control {
    border-top-color: rgb(138, 109, 59);
    border-right-color: rgb(138, 109, 59);
    border-bottom-color: rgb(138, 109, 59);
    border-left-color: rgb(138, 109, 59);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.has-warning .form-control:focus {
    border-top-color: rgb(102, 81, 44);
    border-right-color: rgb(102, 81, 44);
    border-bottom-color: rgb(102, 81, 44);
    border-left-color: rgb(102, 81, 44);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px rgb(192, 161, 107);
}

.has-warning .input-group-addon {
    color: rgb(138, 109, 59);
    border-top-color: rgb(138, 109, 59);
    border-right-color: rgb(138, 109, 59);
    border-bottom-color: rgb(138, 109, 59);
    border-left-color: rgb(138, 109, 59);
    background-color: rgb(252, 248, 227);
}

.has-warning .form-control-feedback {
    color: rgb(138, 109, 59);
}

.has-error .checkbox,
.has-error .checkbox-inline,
.has-error.checkbox-inline label,
.has-error.checkbox label,
.has-error .control-label,
.has-error .help-block,
.has-error .radio,
.has-error .radio-inline,
.has-error.radio-inline label,
.has-error.radio label {
    color: rgb(169, 68, 66);
}

.has-error .form-control {
    border-top-color: rgb(169, 68, 66);
    border-right-color: rgb(169, 68, 66);
    border-bottom-color: rgb(169, 68, 66);
    border-left-color: rgb(169, 68, 66);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.has-error .form-control:focus {
    border-top-color: rgb(132, 53, 52);
    border-right-color: rgb(132, 53, 52);
    border-bottom-color: rgb(132, 53, 52);
    border-left-color: rgb(132, 53, 52);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px rgb(206, 132, 131);
}

.has-error .input-group-addon {
    color: rgb(169, 68, 66);
    border-top-color: rgb(169, 68, 66);
    border-right-color: rgb(169, 68, 66);
    border-bottom-color: rgb(169, 68, 66);
    border-left-color: rgb(169, 68, 66);
    background-color: rgb(242, 222, 222);
}

.has-error .form-control-feedback {
    color: rgb(169, 68, 66);
}

.has-feedback label~.form-control-feedback {
    top: 27px;
}

.has-feedback label.sr-only~.form-control-feedback {
    top: 0;
}

.help-block {
    display: block;
    margin-top: 5px;
    margin-bottom: 10px;
    color: rgb(164, 170, 174);
}

@media (min-width: 768px) {
    .form-inline .form-group {
        display: inline-block;
        margin-bottom: 0;
        vertical-align: middle;
    }

    .form-inline .form-control {
        display: inline-block;
        width: auto;
        vertical-align: middle;
    }

    .form-inline .form-control-static {
        display: inline-block;
    }

    .form-inline .input-group {
        display: inline-table;
        vertical-align: middle;
    }

    .form-inline .input-group .form-control,
    .form-inline .input-group .input-group-addon,
    .form-inline .input-group .input-group-btn {
        width: auto;
    }

    .form-inline .input-group>.form-control {
        width: 100%;
    }

    .form-inline .control-label {
        margin-bottom: 0;
        vertical-align: middle;
    }

    .form-inline .checkbox,
    .form-inline .radio {
        display: inline-block;
        margin-top: 0;
        margin-bottom: 0;
        vertical-align: middle;
    }

    .form-inline .checkbox label,
    .form-inline .radio label {
        padding-left: 0;
    }

    .form-inline .checkbox input[type="checkbox"],
    .form-inline .radio input[type="radio"] {
        position: relative;
        margin-left: 0;
    }

    .form-inline .has-feedback .form-control-feedback {
        top: 0;
    }
}

.form-horizontal .checkbox,
.form-horizontal .checkbox-inline,
.form-horizontal .radio,
.form-horizontal .radio-inline {
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 7px;
}

.form-horizontal .checkbox,
.form-horizontal .radio {
    min-height: 29px;
}

.form-horizontal .form-group {
    margin-left: -15px;
    margin-right: -15px;
}

.form-horizontal .form-group:after,
.form-horizontal .form-group:before {
    content: " ";
    display: table;
}

.form-horizontal .form-group:after {
    clear: both;
}

@media (min-width: 768px) {
    .form-horizontal .control-label {
        text-align: right;
        margin-bottom: 0;
        padding-top: 7px;
    }
}

.form-horizontal .has-feedback .form-control-feedback {
    right: 15px;
}

@media (min-width: 768px) {
    .form-horizontal .form-group-lg .control-label {
        padding-top: 11px;
        font-size: 18px;
    }
}

@media (min-width: 768px) {
    .form-horizontal .form-group-sm .control-label {
        padding-top: 6px;
        font-size: 12px;
    }
}

.btn {
    display: inline-block;
    margin-bottom: 0;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    background-image: none;
    border-top-color: transparent;
    border-top-style: solid;
    border-top-width: 1px;
    border-right-color: transparent;
    border-right-style: solid;
    border-right-width: 1px;
    border-bottom-color: transparent;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-left-color: transparent;
    border-left-style: solid;
    border-left-width: 1px;
    white-space: nowrap;
    padding-top: 6px;
    padding-right: 12px;
    padding-bottom: 6px;
    padding-left: 12px;
    font-size: 14px;
    line-height: 1.6;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
}

.btn.active.focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn:active:focus,
.btn:focus {
    outline-width: 5px;
}

.btn.focus,
.btn:focus,
.btn:hover {
    color: rgb(99, 107, 111);
    text-decoration-line: none;
}

.btn.active,
.btn:active {
    outline-width: 0;
    background-image: none;
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}

.btn.disabled,
.btn[disabled],
fieldset[disabled] .btn {
    opacity: 0.65;
    box-shadow: none;
}

a.btn.disabled,
fieldset[disabled] a.btn {
    pointer-events: none;
}

.btn-default {
    color: rgb(99, 107, 111);
    background-color: rgb(255, 255, 255);
    border-top-color: rgb(204, 204, 204);
    border-right-color: rgb(204, 204, 204);
    border-bottom-color: rgb(204, 204, 204);
    border-left-color: rgb(204, 204, 204);
}

.btn-default.focus,
.btn-default:focus {
    color: rgb(99, 107, 111);
    background-color: rgb(230, 229, 229);
    border-top-color: rgb(140, 140, 140);
    border-right-color: rgb(140, 140, 140);
    border-bottom-color: rgb(140, 140, 140);
    border-left-color: rgb(140, 140, 140);
}

.btn-default.active,
.btn-default:active,
.btn-default:hover,
.open>.btn-default.dropdown-toggle {
    color: rgb(99, 107, 111);
    background-color: rgb(230, 229, 229);
    border-top-color: rgb(173, 173, 173);
    border-right-color: rgb(173, 173, 173);
    border-bottom-color: rgb(173, 173, 173);
    border-left-color: rgb(173, 173, 173);
}

.btn-default.active.focus,
.btn-default.active:focus,
.btn-default.active:hover,
.btn-default:active.focus,
.btn-default:active:focus,
.btn-default:active:hover,
.open>.btn-default.dropdown-toggle.focus,
.open>.btn-default.dropdown-toggle:focus,
.open>.btn-default.dropdown-toggle:hover {
    color: rgb(99, 107, 111);
    background-color: rgb(212, 212, 212);
    border-top-color: rgb(140, 140, 140);
    border-right-color: rgb(140, 140, 140);
    border-bottom-color: rgb(140, 140, 140);
    border-left-color: rgb(140, 140, 140);
}

.btn-default.active,
.btn-default:active,
.open>.btn-default.dropdown-toggle {
    background-image: none;
}

.btn-default.disabled.focus,
.btn-default.disabled:focus,
.btn-default.disabled:hover,
.btn-default[disabled].focus,
.btn-default[disabled]:focus,
.btn-default[disabled]:hover,
fieldset[disabled] .btn-default.focus,
fieldset[disabled] .btn-default:focus,
fieldset[disabled] .btn-default:hover {
    background-color: rgb(255, 255, 255);
    border-top-color: rgb(204, 204, 204);
    border-right-color: rgb(204, 204, 204);
    border-bottom-color: rgb(204, 204, 204);
    border-left-color: rgb(204, 204, 204);
}

.btn-default .badge {
    color: rgb(255, 255, 255);
    background-color: rgb(99, 107, 111);
}

.btn-primary {
    color: rgb(255, 255, 255);
    background-color: rgb(48, 151, 209);
    border-top-color: rgb(42, 136, 189);
    border-right-color: rgb(42, 136, 189);
    border-bottom-color: rgb(42, 136, 189);
    border-left-color: rgb(42, 136, 189);
}

.btn-primary.focus,
.btn-primary:focus {
    color: rgb(255, 255, 255);
    background-color: rgb(37, 121, 169);
    border-top-color: rgb(19, 61, 85);
    border-right-color: rgb(19, 61, 85);
    border-bottom-color: rgb(19, 61, 85);
    border-left-color: rgb(19, 61, 85);
}

.btn-primary.active,
.btn-primary:active,
.btn-primary:hover,
.open>.btn-primary.dropdown-toggle {
    color: rgb(255, 255, 255);
    background-color: rgb(37, 121, 169);
    border-top-color: rgb(31, 100, 139);
    border-right-color: rgb(31, 100, 139);
    border-bottom-color: rgb(31, 100, 139);
    border-left-color: rgb(31, 100, 139);
}

.btn-primary.active.focus,
.btn-primary.active:focus,
.btn-primary.active:hover,
.btn-primary:active.focus,
.btn-primary:active:focus,
.btn-primary:active:hover,
.open>.btn-primary.dropdown-toggle.focus,
.open>.btn-primary.dropdown-toggle:focus,
.open>.btn-primary.dropdown-toggle:hover {
    color: rgb(255, 255, 255);
    background-color: rgb(31, 100, 139);
    border-top-color: rgb(19, 61, 85);
    border-right-color: rgb(19, 61, 85);
    border-bottom-color: rgb(19, 61, 85);
    border-left-color: rgb(19, 61, 85);
}

.btn-primary.active,
.btn-primary:active,
.open>.btn-primary.dropdown-toggle {
    background-image: none;
}

.btn-primary.disabled.focus,
.btn-primary.disabled:focus,
.btn-primary.disabled:hover,
.btn-primary[disabled].focus,
.btn-primary[disabled]:focus,
.btn-primary[disabled]:hover,
fieldset[disabled] .btn-primary.focus,
fieldset[disabled] .btn-primary:focus,
fieldset[disabled] .btn-primary:hover {
    background-color: rgb(48, 151, 209);
    border-top-color: rgb(42, 136, 189);
    border-right-color: rgb(42, 136, 189);
    border-bottom-color: rgb(42, 136, 189);
    border-left-color: rgb(42, 136, 189);
}

.btn-primary .badge {
    color: rgb(48, 151, 209);
    background-color: rgb(255, 255, 255);
}

.btn-success {
    color: rgb(255, 255, 255);
    background-color: rgb(42, 178, 123);
    border-top-color: rgb(37, 157, 109);
    border-right-color: rgb(37, 157, 109);
    border-bottom-color: rgb(37, 157, 109);
    border-left-color: rgb(37, 157, 109);
}

.btn-success.focus,
.btn-success:focus {
    color: rgb(255, 255, 255);
    background-color: rgb(32, 137, 94);
    border-top-color: rgb(13, 54, 37);
    border-right-color: rgb(13, 54, 37);
    border-bottom-color: rgb(13, 54, 37);
    border-left-color: rgb(13, 54, 37);
}

.btn-success.active,
.btn-success:active,
.btn-success:hover,
.open>.btn-success.dropdown-toggle {
    color: rgb(255, 255, 255);
    background-color: rgb(32, 137, 94);
    border-top-color: rgb(25, 108, 75);
    border-right-color: rgb(25, 108, 75);
    border-bottom-color: rgb(25, 108, 75);
    border-left-color: rgb(25, 108, 75);
}

.btn-success.active.focus,
.btn-success.active:focus,
.btn-success.active:hover,
.btn-success:active.focus,
.btn-success:active:focus,
.btn-success:active:hover,
.open>.btn-success.dropdown-toggle.focus,
.open>.btn-success.dropdown-toggle:focus,
.open>.btn-success.dropdown-toggle:hover {
    color: rgb(255, 255, 255);
    background-color: rgb(25, 108, 75);
    border-top-color: rgb(13, 54, 37);
    border-right-color: rgb(13, 54, 37);
    border-bottom-color: rgb(13, 54, 37);
    border-left-color: rgb(13, 54, 37);
}

.btn-success.active,
.btn-success:active,
.open>.btn-success.dropdown-toggle {
    background-image: none;
}

.btn-success.disabled.focus,
.btn-success.disabled:focus,
.btn-success.disabled:hover,
.btn-success[disabled].focus,
.btn-success[disabled]:focus,
.btn-success[disabled]:hover,
fieldset[disabled] .btn-success.focus,
fieldset[disabled] .btn-success:focus,
fieldset[disabled] .btn-success:hover {
    background-color: rgb(42, 178, 123);
    border-top-color: rgb(37, 157, 109);
    border-right-color: rgb(37, 157, 109);
    border-bottom-color: rgb(37, 157, 109);
    border-left-color: rgb(37, 157, 109);
}

.btn-success .badge {
    color: rgb(42, 178, 123);
    background-color: rgb(255, 255, 255);
}

.btn-info {
    color: rgb(255, 255, 255);
    background-color: rgb(142, 180, 203);
    border-top-color: rgb(125, 168, 195);
    border-right-color: rgb(125, 168, 195);
    border-bottom-color: rgb(125, 168, 195);
    border-left-color: rgb(125, 168, 195);
}

.btn-info.focus,
.btn-info:focus {
    color: rgb(255, 255, 255);
    background-color: rgb(107, 157, 187);
    border-top-color: rgb(61, 105, 131);
    border-right-color: rgb(61, 105, 131);
    border-bottom-color: rgb(61, 105, 131);
    border-left-color: rgb(61, 105, 131);
}

.btn-info.active,
.btn-info:active,
.btn-info:hover,
.open>.btn-info.dropdown-toggle {
    color: rgb(255, 255, 255);
    background-color: rgb(107, 157, 187);
    border-top-color: rgb(83, 141, 176);
    border-right-color: rgb(83, 141, 176);
    border-bottom-color: rgb(83, 141, 176);
    border-left-color: rgb(83, 141, 176);
}

.btn-info.active.focus,
.btn-info.active:focus,
.btn-info.active:hover,
.btn-info:active.focus,
.btn-info:active:focus,
.btn-info:active:hover,
.open>.btn-info.dropdown-toggle.focus,
.open>.btn-info.dropdown-toggle:focus,
.open>.btn-info.dropdown-toggle:hover {
    color: rgb(255, 255, 255);
    background-color: rgb(83, 141, 176);
    border-top-color: rgb(61, 105, 131);
    border-right-color: rgb(61, 105, 131);
    border-bottom-color: rgb(61, 105, 131);
    border-left-color: rgb(61, 105, 131);
}

.btn-info.active,
.btn-info:active,
.open>.btn-info.dropdown-toggle {
    background-image: none;
}

.btn-info.disabled.focus,
.btn-info.disabled:focus,
.btn-info.disabled:hover,
.btn-info[disabled].focus,
.btn-info[disabled]:focus,
.btn-info[disabled]:hover,
fieldset[disabled] .btn-info.focus,
fieldset[disabled] .btn-info:focus,
fieldset[disabled] .btn-info:hover {
    background-color: rgb(142, 180, 203);
    border-top-color: rgb(125, 168, 195);
    border-right-color: rgb(125, 168, 195);
    border-bottom-color: rgb(125, 168, 195);
    border-left-color: rgb(125, 168, 195);
}

.btn-info .badge {
    color: rgb(142, 180, 203);
    background-color: rgb(255, 255, 255);
}

.btn-warning {
    color: rgb(255, 255, 255);
    background-color: rgb(203, 185, 86);
    border-top-color: rgb(197, 177, 67);
    border-right-color: rgb(197, 177, 67);
    border-bottom-color: rgb(197, 177, 67);
    border-left-color: rgb(197, 177, 67);
}

.btn-warning.focus,
.btn-warning:focus {
    color: rgb(255, 255, 255);
    background-color: rgb(182, 163, 56);
    border-top-color: rgb(104, 93, 32);
    border-right-color: rgb(104, 93, 32);
    border-bottom-color: rgb(104, 93, 32);
    border-left-color: rgb(104, 93, 32);
}

.btn-warning.active,
.btn-warning:active,
.btn-warning:hover,
.open>.btn-warning.dropdown-toggle {
    color: rgb(255, 255, 255);
    background-color: rgb(182, 163, 56);
    border-top-color: rgb(155, 138, 48);
    border-right-color: rgb(155, 138, 48);
    border-bottom-color: rgb(155, 138, 48);
    border-left-color: rgb(155, 138, 48);
}

.btn-warning.active.focus,
.btn-warning.active:focus,
.btn-warning.active:hover,
.btn-warning:active.focus,
.btn-warning:active:focus,
.btn-warning:active:hover,
.open>.btn-warning.dropdown-toggle.focus,
.open>.btn-warning.dropdown-toggle:focus,
.open>.btn-warning.dropdown-toggle:hover {
    color: rgb(255, 255, 255);
    background-color: rgb(155, 138, 48);
    border-top-color: rgb(104, 93, 32);
    border-right-color: rgb(104, 93, 32);
    border-bottom-color: rgb(104, 93, 32);
    border-left-color: rgb(104, 93, 32);
}

.btn-warning.active,
.btn-warning:active,
.open>.btn-warning.dropdown-toggle {
    background-image: none;
}

.btn-warning.disabled.focus,
.btn-warning.disabled:focus,
.btn-warning.disabled:hover,
.btn-warning[disabled].focus,
.btn-warning[disabled]:focus,
.btn-warning[disabled]:hover,
fieldset[disabled] .btn-warning.focus,
fieldset[disabled] .btn-warning:focus,
fieldset[disabled] .btn-warning:hover {
    background-color: rgb(203, 185, 86);
    border-top-color: rgb(197, 177, 67);
    border-right-color: rgb(197, 177, 67);
    border-bottom-color: rgb(197, 177, 67);
    border-left-color: rgb(197, 177, 67);
}

.btn-warning .badge {
    color: rgb(203, 185, 86);
    background-color: rgb(255, 255, 255);
}

.btn-danger {
    color: rgb(255, 255, 255);
    background-color: rgb(191, 83, 41);
    border-top-color: rgb(170, 74, 36);
    border-right-color: rgb(170, 74, 36);
    border-bottom-color: rgb(170, 74, 36);
    border-left-color: rgb(170, 74, 36);
}

.btn-danger.focus,
.btn-danger:focus {
    color: rgb(255, 255, 255);
    background-color: rgb(149, 65, 32);
    border-top-color: rgb(65, 28, 14);
    border-right-color: rgb(65, 28, 14);
    border-bottom-color: rgb(65, 28, 14);
    border-left-color: rgb(65, 28, 14);
}

.btn-danger.active,
.btn-danger:active,
.btn-danger:hover,
.open>.btn-danger.dropdown-toggle {
    color: rgb(255, 255, 255);
    background-color: rgb(149, 65, 32);
    border-top-color: rgb(120, 52, 26);
    border-right-color: rgb(120, 52, 26);
    border-bottom-color: rgb(120, 52, 26);
    border-left-color: rgb(120, 52, 26);
}

.btn-danger.active.focus,
.btn-danger.active:focus,
.btn-danger.active:hover,
.btn-danger:active.focus,
.btn-danger:active:focus,
.btn-danger:active:hover,
.open>.btn-danger.dropdown-toggle.focus,
.open>.btn-danger.dropdown-toggle:focus,
.open>.btn-danger.dropdown-toggle:hover {
    color: rgb(255, 255, 255);
    background-color: rgb(120, 52, 26);
    border-top-color: rgb(65, 28, 14);
    border-right-color: rgb(65, 28, 14);
    border-bottom-color: rgb(65, 28, 14);
    border-left-color: rgb(65, 28, 14);
}

.btn-danger.active,
.btn-danger:active,
.open>.btn-danger.dropdown-toggle {
    background-image: none;
}

.btn-danger.disabled.focus,
.btn-danger.disabled:focus,
.btn-danger.disabled:hover,
.btn-danger[disabled].focus,
.btn-danger[disabled]:focus,
.btn-danger[disabled]:hover,
fieldset[disabled] .btn-danger.focus,
fieldset[disabled] .btn-danger:focus,
fieldset[disabled] .btn-danger:hover {
    background-color: rgb(191, 83, 41);
    border-top-color: rgb(170, 74, 36);
    border-right-color: rgb(170, 74, 36);
    border-bottom-color: rgb(170, 74, 36);
    border-left-color: rgb(170, 74, 36);
}

.btn-danger .badge {
    color: rgb(191, 83, 41);
    background-color: rgb(255, 255, 255);
}

.btn-link {
    color: rgb(48, 151, 209);
    font-weight: 400;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.btn-link,
.btn-link.active,
.btn-link:active,
.btn-link[disabled],
fieldset[disabled] .btn-link {
    background-color: transparent;
    box-shadow: none;
}

.btn-link,
.btn-link:active,
.btn-link:focus,
.btn-link:hover {
    border-top-color: transparent;
    border-right-color: transparent;
    border-bottom-color: transparent;
    border-left-color: transparent;
}

.btn-link:focus,
.btn-link:hover {
    color: rgb(33, 106, 148);
    text-decoration-line: underline;
    background-color: transparent;
}

.btn-link[disabled]:focus,
.btn-link[disabled]:hover,
fieldset[disabled] .btn-link:focus,
fieldset[disabled] .btn-link:hover {
    color: rgb(119, 119, 119);
    text-decoration-line: none;
}

.btn-group-lg>.btn,
.btn-lg {
    padding-top: 10px;
    padding-right: 16px;
    padding-bottom: 10px;
    padding-left: 16px;
    font-size: 18px;
    line-height: 1.3333333;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
}

.btn-group-sm>.btn,
.btn-sm {
    padding-top: 5px;
    padding-right: 10px;
    padding-bottom: 5px;
    padding-left: 10px;
    font-size: 12px;
    line-height: 1.5;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
}

.btn-group-xs>.btn,
.btn-xs {
    padding-top: 1px;
    padding-right: 5px;
    padding-bottom: 1px;
    padding-left: 5px;
    font-size: 12px;
    line-height: 1.5;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
}

.btn-block {
    display: block;
    width: 100%;
}

.btn-block+.btn-block {
    margin-top: 5px;
}

input[type="button"].btn-block,
input[type="reset"].btn-block,
input[type="submit"].btn-block {
    width: 100%;
}

.fade {
    opacity: 0;
}

.fade.in {
    opacity: 1;
}

.collapse {
    display: none;
}

.collapse.in {
    display: block;
}

tr.collapse.in {
    display: table-row;
}

tbody.collapse.in {
    display: table-row-group;
}

.collapsing {
    position: relative;
    height: 0px;
    overflow-x: hidden;
    overflow-y: hidden;
    transition-property: height, visibility;
    transition-duration: 0.35s;
    transition-timing-function: ease;
}

.caret {
    display: inline-block;
    width: 0px;
    height: 0px;
    margin-left: 2px;
    vertical-align: middle;
    border-top-color: initial;
    border-top-style: initial;
    border-top-width: 4px;
    border-right-color: transparent;
    border-right-style: solid;
    border-right-width: 4px;
    border-left-color: transparent;
    border-left-style: solid;
    border-left-width: 4px;
}

.dropdown,
.dropup {
    position: relative;
}

.dropdown-toggle:focus {
    outline-width: 0;
}

.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 160px;
    padding-top: 5px;
    padding-right: 0;
    padding-bottom: 5px;
    padding-left: 0;
    margin-top: 2px;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 0;
    list-style-type: none;
    list-style-image: none;
    font-size: 14px;
    text-align: left;
    background-color: rgb(255, 255, 255);
    border-top-color: rgba(0, 0, 0, 0.15);
    border-top-style: solid;
    border-top-width: 1px;
    border-right-color: rgba(0, 0, 0, 0.15);
    border-right-style: solid;
    border-right-width: 1px;
    border-bottom-color: rgba(0, 0, 0, 0.15);
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-left-color: rgba(0, 0, 0, 0.15);
    border-left-style: solid;
    border-left-width: 1px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    background-clip: padding-box;
}

.dropdown-menu.pull-right {
    right: 0;
    left: auto;
}

.dropdown-menu .divider {
    height: 1px;
    margin-top: 10px;
    margin-right: 0;
    margin-bottom: 10px;
    margin-left: 0;
    overflow-x: hidden;
    overflow-y: hidden;
    background-color: rgb(229, 229, 229);
}

.dropdown-menu>li>a {
    display: block;
    padding-top: 3px;
    padding-right: 20px;
    padding-bottom: 3px;
    padding-left: 20px;
    clear: both;
    font-weight: 400;
    line-height: 1.6;
    color: rgb(51, 51, 51);
    white-space: nowrap;
}

.dropdown-menu>li>a:focus,
.dropdown-menu>li>a:hover {
    text-decoration-line: none;
    color: rgb(38, 38, 38);
    background-color: rgb(245, 245, 245);
}

.dropdown-menu>.active>a,
.dropdown-menu>.active>a:focus,
.dropdown-menu>.active>a:hover {
    color: rgb(255, 255, 255);
    text-decoration-line: none;
    outline-width: 0;
    background-color: rgb(48, 151, 209);
}

.dropdown-menu>.disabled>a,
.dropdown-menu>.disabled>a:focus,
.dropdown-menu>.disabled>a:hover {
    color: rgb(119, 119, 119);
}

.dropdown-menu>.disabled>a:focus,
.dropdown-menu>.disabled>a:hover {
    text-decoration-line: none;
    background-color: transparent;
    background-image: none;
}

.open>.dropdown-menu {
    display: block;
}

.open>a {
    outline-width: 0;
}

.dropdown-menu-right {
    left: auto;
    right: 0;
}

.dropdown-menu-left {
    left: 0;
    right: auto;
}

.dropdown-header {
    display: block;
    padding-top: 3px;
    padding-right: 20px;
    padding-bottom: 3px;
    padding-left: 20px;
    font-size: 12px;
    line-height: 1.6;
    color: rgb(119, 119, 119);
    white-space: nowrap;
}

.dropdown-backdrop {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 990;
}

.pull-right>.dropdown-menu {
    right: 0;
    left: auto;
}

.dropup .caret,
.navbar-fixed-bottom .dropdown .caret {
    border-top-color: initial;
    border-top-style: initial;
    border-top-width: 0;
    border-bottom-color: initial;
    border-bottom-style: initial;
    border-bottom-width: 4px;
    content: "";
}

.dropup .dropdown-menu,
.navbar-fixed-bottom .dropdown .dropdown-menu {
    top: auto;
    bottom: 100%;
    margin-bottom: 2px;
}

@media (min-width: 768px) {
    .navbar-right .dropdown-menu {
        right: 0;
        left: auto;
    }

    .navbar-right .dropdown-menu-left {
        left: 0;
        right: auto;
    }
}

.btn-group,
.btn-group-vertical {
    position: relative;
    display: inline-block;
    vertical-align: middle;
}

.btn-group-vertical>.btn,
.btn-group>.btn {
    position: relative;
    float: left;
}

.btn-group-vertical>.btn.active,
.btn-group-vertical>.btn:active,
.btn-group-vertical>.btn:focus,
.btn-group-vertical>.btn:hover,
.btn-group>.btn.active,
.btn-group>.btn:active,
.btn-group>.btn:focus,
.btn-group>.btn:hover {
    z-index: 2;
}

.btn-group .btn+.btn,
.btn-group .btn+.btn-group,
.btn-group .btn-group+.btn,
.btn-group .btn-group+.btn-group {
    margin-left: -1px;
}

.btn-toolbar {
    margin-left: -5px;
}

.btn-toolbar:after,
.btn-toolbar:before {
    content: " ";
    display: table;
}

.btn-toolbar:after {
    clear: both;
}

.btn-toolbar .btn,
.btn-toolbar .btn-group,
.btn-toolbar .input-group {
    float: left;
}

.btn-toolbar>.btn,
.btn-toolbar>.btn-group,
.btn-toolbar>.input-group {
    margin-left: 5px;
}

.btn-group>.btn:not(:first-child):not(:last-child):not(.dropdown-toggle) {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.btn-group>.btn:first-child {
    margin-left: 0;
}

.btn-group>.btn:first-child:not(:last-child):not(.dropdown-toggle) {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
}

.btn-group>.btn:last-child:not(:first-child),
.btn-group>.dropdown-toggle:not(:first-child) {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
}

.btn-group>.btn-group {
    float: left;
}

.btn-group>.btn-group:not(:first-child):not(:last-child)>.btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.btn-group>.btn-group:first-child:not(:last-child)>.btn:last-child,
.btn-group>.btn-group:first-child:not(:last-child)>.dropdown-toggle {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
}

.btn-group>.btn-group:last-child:not(:first-child)>.btn:first-child {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
}

.btn-group .dropdown-toggle:active,
.btn-group.open .dropdown-toggle {
    outline-width: 0;
}

.btn-group>.btn+.dropdown-toggle {
    padding-left: 8px;
    padding-right: 8px;
}

.btn-group-lg.btn-group>.btn+.dropdown-toggle,
.btn-group>.btn-lg+.dropdown-toggle {
    padding-left: 12px;
    padding-right: 12px;
}

.btn-group.open .dropdown-toggle {
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}

.btn-group.open .dropdown-toggle.btn-link {
    box-shadow: none;
}

.btn .caret {
    margin-left: 0;
}

.btn-group-lg>.btn .caret,
.btn-lg .caret {
    border-top-width: 5px;
    border-right-width: 5px;
    border-bottom-width: 0;
    border-left-width: 5px;
}

.dropup .btn-group-lg>.btn .caret,
.dropup .btn-lg .caret {
    border-top-width: 0;
    border-right-width: 5px;
    border-bottom-width: 5px;
    border-left-width: 5px;
}

.btn-group-vertical>.btn,
.btn-group-vertical>.btn-group,
.btn-group-vertical>.btn-group>.btn {
    display: block;
    float: none;
    width: 100%;
    max-width: 100%;
}

.btn-group-vertical>.btn-group:after,
.btn-group-vertical>.btn-group:before {
    content: " ";
    display: table;
}

.btn-group-vertical>.btn-group:after {
    clear: both;
}

.btn-group-vertical>.btn-group>.btn {
    float: none;
}

.btn-group-vertical>.btn+.btn,
.btn-group-vertical>.btn+.btn-group,
.btn-group-vertical>.btn-group+.btn,
.btn-group-vertical>.btn-group+.btn-group {
    margin-top: -1px;
    margin-left: 0;
}

.btn-group-vertical>.btn:not(:first-child):not(:last-child) {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.btn-group-vertical>.btn:first-child:not(:last-child) {
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.btn-group-vertical>.btn:last-child:not(:first-child) {
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
}

.btn-group-vertical>.btn-group:not(:first-child):not(:last-child)>.btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.btn-group-vertical>.btn-group:first-child:not(:last-child)>.btn:last-child,
.btn-group-vertical>.btn-group:first-child:not(:last-child)>.dropdown-toggle {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.btn-group-vertical>.btn-group:last-child:not(:first-child)>.btn:first-child {
    border-top-right-radius: 0;
    border-top-left-radius: 0;
}

.btn-group-justified {
    display: table;
    width: 100%;
    table-layout: fixed;
    border-collapse: separate;
}

.btn-group-justified>.btn,
.btn-group-justified>.btn-group {
    float: none;
    display: table-cell;
    width: 1%;
}

.btn-group-justified>.btn-group .btn {
    width: 100%;
}

.btn-group-justified>.btn-group .dropdown-menu {
    left: auto;
}

[data-toggle="buttons"]>.btn-group>.btn input[type="checkbox"],
[data-toggle="buttons"]>.btn-group>.btn input[type="radio"],
[data-toggle="buttons"]>.btn input[type="checkbox"],
[data-toggle="buttons"]>.btn input[type="radio"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none;
}

.input-group {
    position: relative;
    display: table;
    border-collapse: separate;
}

.input-group[class*="col-"] {
    float: none;
    padding-left: 0;
    padding-right: 0;
}

.input-group .form-control {
    position: relative;
    z-index: 2;
    float: left;
    width: 100%;
    margin-bottom: 0;
}

.input-group .form-control:focus {
    z-index: 3;
}

.input-group-addon,
.input-group-btn,
.input-group .form-control {
    display: table-cell;
}

.input-group-addon:not(:first-child):not(:last-child),
.input-group-btn:not(:first-child):not(:last-child),
.input-group .form-control:not(:first-child):not(:last-child) {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.input-group-addon,
.input-group-btn {
    width: 1%;
    white-space: nowrap;
    vertical-align: middle;
}

.input-group-addon {
    padding-top: 6px;
    padding-right: 12px;
    padding-bottom: 6px;
    padding-left: 12px;
    font-size: 14px;
    font-weight: 400;
    line-height: 1;
    color: rgb(85, 85, 85);
    text-align: center;
    background-color: rgb(238, 238, 238);
    border-top-color: rgb(204, 208, 210);
    border-top-style: solid;
    border-top-width: 1px;
    border-right-color: rgb(204, 208, 210);
    border-right-style: solid;
    border-right-width: 1px;
    border-bottom-color: rgb(204, 208, 210);
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-left-color: rgb(204, 208, 210);
    border-left-style: solid;
    border-left-width: 1px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
}

.input-group-addon.input-sm,
.input-group-sm>.input-group-addon,
.input-group-sm>.input-group-btn>.input-group-addon.btn {
    padding-top: 5px;
    padding-right: 10px;
    padding-bottom: 5px;
    padding-left: 10px;
    font-size: 12px;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
}

.input-group-addon.input-lg,
.input-group-lg>.input-group-addon,
.input-group-lg>.input-group-btn>.input-group-addon.btn {
    padding-top: 10px;
    padding-right: 16px;
    padding-bottom: 10px;
    padding-left: 16px;
    font-size: 18px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
}

.input-group-addon input[type="checkbox"],
.input-group-addon input[type="radio"] {
    margin-top: 0;
}

.input-group-addon:first-child,
.input-group-btn:first-child>.btn,
.input-group-btn:first-child>.btn-group>.btn,
.input-group-btn:first-child>.dropdown-toggle,
.input-group-btn:last-child>.btn-group:not(:last-child)>.btn,
.input-group-btn:last-child>.btn:not(:last-child):not(.dropdown-toggle),
.input-group .form-control:first-child {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
}

.input-group-addon:first-child {
    border-right-color: initial;
    border-right-style: initial;
    border-right-width: 0;
}

.input-group-addon:last-child,
.input-group-btn:first-child>.btn-group:not(:first-child)>.btn,
.input-group-btn:first-child>.btn:not(:first-child),
.input-group-btn:last-child>.btn,
.input-group-btn:last-child>.btn-group>.btn,
.input-group-btn:last-child>.dropdown-toggle,
.input-group .form-control:last-child {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
}

.input-group-addon:last-child {
    border-left-color: initial;
    border-left-style: initial;
    border-left-width: 0;
}

.input-group-btn {
    font-size: 0;
    white-space: nowrap;
}

.input-group-btn,
.input-group-btn>.btn {
    position: relative;
}

.input-group-btn>.btn+.btn {
    margin-left: -1px;
}

.input-group-btn>.btn:active,
.input-group-btn>.btn:focus,
.input-group-btn>.btn:hover {
    z-index: 2;
}

.input-group-btn:first-child>.btn,
.input-group-btn:first-child>.btn-group {
    margin-right: -1px;
}

.input-group-btn:last-child>.btn,
.input-group-btn:last-child>.btn-group {
    z-index: 2;
    margin-left: -1px;
}

.nav {
    margin-bottom: 0;
    padding-left: 0;
    list-style-type: none;
    list-style-image: none;
}

.nav:after,
.nav:before {
    content: " ";
    display: table;
}

.nav:after {
    clear: both;
}

.nav>li,
.nav>li>a {
    position: relative;
    display: block;
}

.nav>li>a {
    padding-top: 10px;
    padding-right: 15px;
    padding-bottom: 10px;
    padding-left: 15px;
}

.nav>li>a:focus,
.nav>li>a:hover {
    text-decoration-line: none;
    background-color: rgb(238, 238, 238);
}

.nav>li.disabled>a {
    color: rgb(119, 119, 119);
}

.nav>li.disabled>a:focus,
.nav>li.disabled>a:hover {
    color: rgb(119, 119, 119);
    text-decoration-line: none;
    background-color: transparent;
}

.nav .open>a,
.nav .open>a:focus,
.nav .open>a:hover {
    background-color: rgb(238, 238, 238);
    border-top-color: rgb(48, 151, 209);
    border-right-color: rgb(48, 151, 209);
    border-bottom-color: rgb(48, 151, 209);
    border-left-color: rgb(48, 151, 209);
}

.nav .nav-divider {
    height: 1px;
    margin-top: 10px;
    margin-right: 0;
    margin-bottom: 10px;
    margin-left: 0;
    overflow-x: hidden;
    overflow-y: hidden;
    background-color: rgb(229, 229, 229);
}

.nav>li>a>img {
    max-width: none;
}

.nav-tabs {
    border-bottom-color: rgb(221, 221, 221);
    border-bottom-style: solid;
    border-bottom-width: 1px;
}

.nav-tabs>li {
    float: left;
    margin-bottom: -1px;
}

.nav-tabs>li>a {
    margin-right: 2px;
    line-height: 1.6;
    border-top-color: transparent;
    border-top-style: solid;
    border-top-width: 1px;
    border-right-color: transparent;
    border-right-style: solid;
    border-right-width: 1px;
    border-bottom-color: transparent;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-left-color: transparent;
    border-left-style: solid;
    border-left-width: 1px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.nav-tabs>li>a:hover {
    border-top-color: rgb(238, 238, 238);
    border-right-color: rgb(238, 238, 238);
    border-bottom-color: rgb(221, 221, 221);
    border-left-color: rgb(238, 238, 238);
}

.nav-tabs>li.active>a,
.nav-tabs>li.active>a:focus,
.nav-tabs>li.active>a:hover {
    color: rgb(85, 85, 85);
    background-color: rgb(245, 248, 250);
    border-top-color: rgb(221, 221, 221);
    border-top-style: solid;
    border-top-width: 1px;
    border-right-color: rgb(221, 221, 221);
    border-right-style: solid;
    border-right-width: 1px;
    border-bottom-color: transparent;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-left-color: rgb(221, 221, 221);
    border-left-style: solid;
    border-left-width: 1px;
    cursor: default;
}

.nav-pills>li {
    float: left;
}

.nav-pills>li>a {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
}

.nav-pills>li+li {
    margin-left: 2px;
}

.nav-pills>li.active>a,
.nav-pills>li.active>a:focus,
.nav-pills>li.active>a:hover {
    color: rgb(255, 255, 255);
    background-color: rgb(48, 151, 209);
}

.nav-stacked>li {
    float: none;
}

.nav-stacked>li+li {
    margin-top: 2px;
    margin-left: 0;
}

.nav-justified,
.nav-tabs.nav-justified {
    width: 100%;
}

.nav-justified>li,
.nav-tabs.nav-justified>li {
    float: none;
}

.nav-justified>li>a,
.nav-tabs.nav-justified>li>a {
    text-align: center;
    margin-bottom: 5px;
}

.nav-justified>.dropdown .dropdown-menu {
    top: auto;
    left: auto;
}

@media (min-width: 768px) {

    .nav-justified>li,
    .nav-tabs.nav-justified>li {
        display: table-cell;
        width: 1%;
    }

    .nav-justified>li>a,
    .nav-tabs.nav-justified>li>a {
        margin-bottom: 0;
    }
}

.nav-tabs-justified,
.nav-tabs.nav-justified {
    border-bottom-color: initial;
    border-bottom-style: initial;
    border-bottom-width: 0;
}

.nav-tabs-justified>li>a,
.nav-tabs.nav-justified>li>a {
    margin-right: 0;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
}

.nav-tabs-justified>.active>a,
.nav-tabs-justified>.active>a:focus,
.nav-tabs-justified>.active>a:hover,
.nav-tabs.nav-justified>.active>a,
.nav-tabs.nav-justified>.active>a:focus,
.nav-tabs.nav-justified>.active>a:hover {
    border-top-color: rgb(221, 221, 221);
    border-top-style: solid;
    border-top-width: 1px;
    border-right-color: rgb(221, 221, 221);
    border-right-style: solid;
    border-right-width: 1px;
    border-bottom-color: rgb(221, 221, 221);
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-left-color: rgb(221, 221, 221);
    border-left-style: solid;
    border-left-width: 1px;
}

@media (min-width: 768px) {

    .nav-tabs-justified>li>a,
    .nav-tabs.nav-justified>li>a {
        border-bottom-color: rgb(221, 221, 221);
        border-bottom-style: solid;
        border-bottom-width: 1px;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
    }

    .nav-tabs-justified>.active>a,
    .nav-tabs-justified>.active>a:focus,
    .nav-tabs-justified>.active>a:hover,
    .nav-tabs.nav-justified>.active>a,
    .nav-tabs.nav-justified>.active>a:focus,
    .nav-tabs.nav-justified>.active>a:hover {
        border-bottom-color: rgb(245, 248, 250);
    }
}

.tab-content>.tab-pane {
    display: none;
}

.tab-content>.active {
    display: block;
}

.nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
}

.navbar {
    position: relative;
    min-height: 50px;
    margin-bottom: 22px;
    border-top-color: transparent;
    border-top-style: solid;
    border-top-width: 1px;
    border-right-color: transparent;
    border-right-style: solid;
    border-right-width: 1px;
    border-bottom-color: transparent;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-left-color: transparent;
    border-left-style: solid;
    border-left-width: 1px;
}

.navbar:after,
.navbar:before {
    content: " ";
    display: table;
}

.navbar:after {
    clear: both;
}

@media (min-width: 768px) {
    .navbar {
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        border-bottom-left-radius: 4px;
    }
}

.navbar-header:after,
.navbar-header:before {
    content: " ";
    display: table;
}

.navbar-header:after {
    clear: both;
}

@media (min-width: 768px) {
    .navbar-header {
        float: left;
    }
}

.navbar-collapse {
    overflow-x: visible;
    padding-right: 15px;
    padding-left: 15px;
    border-top-color: transparent;
    border-top-style: solid;
    border-top-width: 1px;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);
}

.navbar-collapse:after,
.navbar-collapse:before {
    content: " ";
    display: table;
}

.navbar-collapse:after {
    clear: both;
}

.navbar-collapse.in {
    overflow-y: auto;
}

@media (min-width: 768px) {
    .navbar-collapse {
        width: auto;
        border-top-color: initial;
        border-top-style: initial;
        border-top-width: 0;
        box-shadow: none;
    }

    .navbar-collapse.collapse {
        display: block !important;
        height: auto !important;
        padding-bottom: 0;
        overflow-x: visible !important;
        overflow-y: visible !important;
    }

    .navbar-collapse.in {
        overflow-y: visible;
    }

    .navbar-fixed-bottom .navbar-collapse,
    .navbar-fixed-top .navbar-collapse,
    .navbar-static-top .navbar-collapse {
        padding-left: 0;
        padding-right: 0;
    }
}

.navbar-fixed-bottom .navbar-collapse,
.navbar-fixed-top .navbar-collapse {
    max-height: 340px;
}

@media (max-device-width: 480px) AND (orientation: landscape) {

    .navbar-fixed-bottom .navbar-collapse,
    .navbar-fixed-top .navbar-collapse {
        max-height: 200px;
    }
}

.container-fluid>.navbar-collapse,
.container-fluid>.navbar-header,
.container>.navbar-collapse,
.container>.navbar-header {
    margin-right: -15px;
    margin-left: -15px;
}

@media (min-width: 768px) {

    .container-fluid>.navbar-collapse,
    .container-fluid>.navbar-header,
    .container>.navbar-collapse,
    .container>.navbar-header {
        margin-right: 0;
        margin-left: 0;
    }
}

.navbar-static-top {
    z-index: 1000;
    border-top-width: 0;
    border-right-width: 0;
    border-bottom-width: 1px;
    border-left-width: 0;
}

@media (min-width: 768px) {
    .navbar-static-top {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
    }
}

.navbar-fixed-bottom,
.navbar-fixed-top {
    position: fixed;
    right: 0;
    left: 0;
    z-index: 1030;
}

@media (min-width: 768px) {

    .navbar-fixed-bottom,
    .navbar-fixed-top {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
    }
}

.navbar-fixed-top {
    top: 0;
    border-top-width: 0;
    border-right-width: 0;
    border-bottom-width: 1px;
    border-left-width: 0;
}

.navbar-fixed-bottom {
    bottom: 0;
    margin-bottom: 0;
    border-top-width: 1px;
    border-right-width: 0;
    border-bottom-width: 0;
    border-left-width: 0;
}

.navbar-brand {
    float: left;
    padding-top: 14px;
    padding-right: 15px;
    padding-bottom: 14px;
    padding-left: 15px;
    font-size: 18px;
    line-height: 22px;
    height: 50px;
}

.navbar-brand:focus,
.navbar-brand:hover {
    text-decoration-line: none;
}

.navbar-brand>img {
    display: block;
}

@media (min-width: 768px) {

    .navbar>.container-fluid .navbar-brand,
    .navbar>.container .navbar-brand {
        margin-left: -15px;
    }
}

.navbar-toggle {
    position: relative;
    float: right;
    margin-right: 15px;
    padding-top: 9px;
    padding-right: 10px;
    padding-bottom: 9px;
    padding-left: 10px;
    margin-top: 8px;
    margin-bottom: 8px;
    background-color: transparent;
    background-image: none;
    border-top-color: transparent;
    border-top-style: solid;
    border-top-width: 1px;
    border-right-color: transparent;
    border-right-style: solid;
    border-right-width: 1px;
    border-bottom-color: transparent;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-left-color: transparent;
    border-left-style: solid;
    border-left-width: 1px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
}

.navbar-toggle:focus {
    outline-width: 0;
}

.navbar-toggle .icon-bar {
    display: block;
    width: 22px;
    height: 2px;
    border-top-left-radius: 1px;
    border-top-right-radius: 1px;
    border-bottom-right-radius: 1px;
    border-bottom-left-radius: 1px;
}

.navbar-toggle .icon-bar+.icon-bar {
    margin-top: 4px;
}

@media (min-width: 768px) {
    .navbar-toggle {
        display: none;
    }
}

.navbar-nav {
    margin-top: 7px;
    margin-right: -15px;
    margin-bottom: 7px;
    margin-left: -15px;
}

.navbar-nav>li>a {
    padding-top: 10px;
    padding-bottom: 10px;
    line-height: 22px;
}

@media (max-width: 767px) {
    .navbar-nav .open .dropdown-menu {
        position: static;
        float: none;
        width: auto;
        margin-top: 0;
        background-color: transparent;
        border-top-color: initial;
        border-top-style: initial;
        border-top-width: 0;
        border-right-color: initial;
        border-right-style: initial;
        border-right-width: 0;
        border-bottom-color: initial;
        border-bottom-style: initial;
        border-bottom-width: 0;
        border-left-color: initial;
        border-left-style: initial;
        border-left-width: 0;
        box-shadow: none;
    }

    .navbar-nav .open .dropdown-menu .dropdown-header,
    .navbar-nav .open .dropdown-menu>li>a {
        padding-top: 5px;
        padding-right: 15px;
        padding-bottom: 5px;
        padding-left: 25px;
    }

    .navbar-nav .open .dropdown-menu>li>a {
        line-height: 22px;
    }

    .navbar-nav .open .dropdown-menu>li>a:focus,
    .navbar-nav .open .dropdown-menu>li>a:hover {
        background-image: none;
    }
}

@media (min-width: 768px) {
    .navbar-nav {
        float: left;
        margin-top: 0;
        margin-right: 0;
        margin-bottom: 0;
        margin-left: 0;
    }

    .navbar-nav>li {
        float: left;
    }

    .navbar-nav>li>a {
        padding-top: 14px;
        padding-bottom: 14px;
    }
}

.navbar-form {
    margin-top: 7px;
    margin-right: -15px;
    margin-bottom: 7px;
    margin-left: -15px;
    padding-top: 10px;
    padding-right: 15px;
    padding-bottom: 10px;
    padding-left: 15px;
    border-top-color: transparent;
    border-top-style: solid;
    border-top-width: 1px;
    border-bottom-color: transparent;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1), 0 1px 0 rgba(255, 255, 255, 0.1);
}

@media (min-width: 768px) {
    .navbar-form .form-group {
        display: inline-block;
        margin-bottom: 0;
        vertical-align: middle;
    }

    .navbar-form .form-control {
        display: inline-block;
        width: auto;
        vertical-align: middle;
    }

    .navbar-form .form-control-static {
        display: inline-block;
    }

    .navbar-form .input-group {
        display: inline-table;
        vertical-align: middle;
    }

    .navbar-form .input-group .form-control,
    .navbar-form .input-group .input-group-addon,
    .navbar-form .input-group .input-group-btn {
        width: auto;
    }

    .navbar-form .input-group>.form-control {
        width: 100%;
    }

    .navbar-form .control-label {
        margin-bottom: 0;
        vertical-align: middle;
    }

    .navbar-form .checkbox,
    .navbar-form .radio {
        display: inline-block;
        margin-top: 0;
        margin-bottom: 0;
        vertical-align: middle;
    }

    .navbar-form .checkbox label,
    .navbar-form .radio label {
        padding-left: 0;
    }

    .navbar-form .checkbox input[type="checkbox"],
    .navbar-form .radio input[type="radio"] {
        position: relative;
        margin-left: 0;
    }

    .navbar-form .has-feedback .form-control-feedback {
        top: 0;
    }
}

@media (max-width: 767px) {
    .navbar-form .form-group {
        margin-bottom: 5px;
    }

    .navbar-form .form-group:last-child {
        margin-bottom: 0;
    }
}

@media (min-width: 768px) {
    .navbar-form {
        width: auto;
        border-top-color: initial;
        border-top-style: initial;
        border-top-width: 0;
        border-right-color: initial;
        border-right-style: initial;
        border-right-width: 0;
        border-bottom-color: initial;
        border-bottom-style: initial;
        border-bottom-width: 0;
        border-left-color: initial;
        border-left-style: initial;
        border-left-width: 0;
        margin-left: 0;
        margin-right: 0;
        padding-top: 0;
        padding-bottom: 0;
        box-shadow: none;
    }
}

.navbar-nav>li>.dropdown-menu {
    margin-top: 0;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
}

.navbar-fixed-bottom .navbar-nav>li>.dropdown-menu {
    margin-bottom: 0;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.navbar-btn {
    margin-top: 7px;
    margin-bottom: 7px;
}

.btn-group-sm>.navbar-btn.btn,
.navbar-btn.btn-sm {
    margin-top: 10px;
    margin-bottom: 10px;
}

.btn-group-xs>.navbar-btn.btn,
.navbar-btn.btn-xs,
.navbar-text {
    margin-top: 14px;
    margin-bottom: 14px;
}

@media (min-width: 768px) {
    .navbar-text {
        float: left;
        margin-left: 15px;
        margin-right: 15px;
    }
}

@media (min-width: 768px) {
    .navbar-left {
        float: left !important;
    }

    .navbar-right {
        float: right !important;
        margin-right: -15px;
    }

    .navbar-right~.navbar-right {
        margin-right: 0;
    }
}

.navbar-default {
    background-color: rgb(255, 255, 255);
    border-top-color: rgb(211, 224, 233);
    border-right-color: rgb(211, 224, 233);
    border-bottom-color: rgb(211, 224, 233);
    border-left-color: rgb(211, 224, 233);
}

.navbar-default .navbar-brand {
    color: rgb(119, 119, 119);
}

.navbar-default .navbar-brand:focus,
.navbar-default .navbar-brand:hover {
    color: rgb(94, 93, 93);
    background-color: transparent;
}

.navbar-default .navbar-nav>li>a,
.navbar-default .navbar-text {
    color: rgb(119, 119, 119);
}

.navbar-default .navbar-nav>li>a:focus,
.navbar-default .navbar-nav>li>a:hover {
    color: rgb(51, 51, 51);
    background-color: transparent;
}

.navbar-default .navbar-nav>.active>a,
.navbar-default .navbar-nav>.active>a:focus,
.navbar-default .navbar-nav>.active>a:hover {
    color: rgb(85, 85, 85);
    background-color: rgb(238, 238, 238);
}

.navbar-default .navbar-nav>.disabled>a,
.navbar-default .navbar-nav>.disabled>a:focus,
.navbar-default .navbar-nav>.disabled>a:hover {
    color: rgb(204, 204, 204);
    background-color: transparent;
}

.navbar-default .navbar-toggle {
    border-top-color: rgb(221, 221, 221);
    border-right-color: rgb(221, 221, 221);
    border-bottom-color: rgb(221, 221, 221);
    border-left-color: rgb(221, 221, 221);
}

.navbar-default .navbar-toggle:focus,
.navbar-default .navbar-toggle:hover {
    background-color: rgb(221, 221, 221);
}

.navbar-default .navbar-toggle .icon-bar {
    background-color: rgb(136, 136, 136);
}

.navbar-default .navbar-collapse,
.navbar-default .navbar-form {
    border-top-color: rgb(211, 224, 233);
    border-right-color: rgb(211, 224, 233);
    border-bottom-color: rgb(211, 224, 233);
    border-left-color: rgb(211, 224, 233);
}

.navbar-default .navbar-nav>.open>a,
.navbar-default .navbar-nav>.open>a:focus,
.navbar-default .navbar-nav>.open>a:hover {
    background-color: rgb(238, 238, 238);
    color: rgb(85, 85, 85);
}

@media (max-width: 767px) {
    .navbar-default .navbar-nav .open .dropdown-menu>li>a {
        color: rgb(119, 119, 119);
    }

    .navbar-default .navbar-nav .open .dropdown-menu>li>a:focus,
    .navbar-default .navbar-nav .open .dropdown-menu>li>a:hover {
        color: rgb(51, 51, 51);
        background-color: transparent;
    }

    .navbar-default .navbar-nav .open .dropdown-menu>.active>a,
    .navbar-default .navbar-nav .open .dropdown-menu>.active>a:focus,
    .navbar-default .navbar-nav .open .dropdown-menu>.active>a:hover {
        color: rgb(85, 85, 85);
        background-color: rgb(238, 238, 238);
    }

    .navbar-default .navbar-nav .open .dropdown-menu>.disabled>a,
    .navbar-default .navbar-nav .open .dropdown-menu>.disabled>a:focus,
    .navbar-default .navbar-nav .open .dropdown-menu>.disabled>a:hover {
        color: rgb(204, 204, 204);
        background-color: transparent;
    }
}

.navbar-default .navbar-link {
    color: rgb(119, 119, 119);
}

.navbar-default .navbar-link:hover {
    color: rgb(51, 51, 51);
}

.navbar-default .btn-link {
    color: rgb(119, 119, 119);
}

.navbar-default .btn-link:focus,
.navbar-default .btn-link:hover {
    color: rgb(51, 51, 51);
}

.navbar-default .btn-link[disabled]:focus,
.navbar-default .btn-link[disabled]:hover,
fieldset[disabled] .navbar-default .btn-link:focus,
fieldset[disabled] .navbar-default .btn-link:hover {
    color: rgb(204, 204, 204);
}

.navbar-inverse {
    background-color: rgb(34, 34, 34);
    border-top-color: rgb(9, 9, 9);
    border-right-color: rgb(9, 9, 9);
    border-bottom-color: rgb(9, 9, 9);
    border-left-color: rgb(9, 9, 9);
}

.navbar-inverse .navbar-brand {
    color: rgb(157, 157, 157);
}

.navbar-inverse .navbar-brand:focus,
.navbar-inverse .navbar-brand:hover {
    color: rgb(255, 255, 255);
    background-color: transparent;
}

.navbar-inverse .navbar-nav>li>a,
.navbar-inverse .navbar-text {
    color: rgb(157, 157, 157);
}

.navbar-inverse .navbar-nav>li>a:focus,
.navbar-inverse .navbar-nav>li>a:hover {
    color: rgb(255, 255, 255);
    background-color: transparent;
}

.navbar-inverse .navbar-nav>.active>a,
.navbar-inverse .navbar-nav>.active>a:focus,
.navbar-inverse .navbar-nav>.active>a:hover {
    color: rgb(255, 255, 255);
    background-color: rgb(9, 9, 9);
}

.navbar-inverse .navbar-nav>.disabled>a,
.navbar-inverse .navbar-nav>.disabled>a:focus,
.navbar-inverse .navbar-nav>.disabled>a:hover {
    color: rgb(68, 68, 68);
    background-color: transparent;
}

.navbar-inverse .navbar-toggle {
    border-top-color: rgb(51, 51, 51);
    border-right-color: rgb(51, 51, 51);
    border-bottom-color: rgb(51, 51, 51);
    border-left-color: rgb(51, 51, 51);
}

.navbar-inverse .navbar-toggle:focus,
.navbar-inverse .navbar-toggle:hover {
    background-color: rgb(51, 51, 51);
}

.navbar-inverse .navbar-toggle .icon-bar {
    background-color: rgb(255, 255, 255);
}

.navbar-inverse .navbar-collapse,
.navbar-inverse .navbar-form {
    border-top-color: rgb(16, 16, 16);
    border-right-color: rgb(16, 16, 16);
    border-bottom-color: rgb(16, 16, 16);
    border-left-color: rgb(16, 16, 16);
}

.navbar-inverse .navbar-nav>.open>a,
.navbar-inverse .navbar-nav>.open>a:focus,
.navbar-inverse .navbar-nav>.open>a:hover {
    background-color: rgb(9, 9, 9);
    color: rgb(255, 255, 255);
}

@media (max-width: 767px) {
    .navbar-inverse .navbar-nav .open .dropdown-menu>.dropdown-header {
        border-top-color: rgb(9, 9, 9);
        border-right-color: rgb(9, 9, 9);
        border-bottom-color: rgb(9, 9, 9);
        border-left-color: rgb(9, 9, 9);
    }

    .navbar-inverse .navbar-nav .open .dropdown-menu .divider {
        background-color: rgb(9, 9, 9);
    }

    .navbar-inverse .navbar-nav .open .dropdown-menu>li>a {
        color: rgb(157, 157, 157);
    }

    .navbar-inverse .navbar-nav .open .dropdown-menu>li>a:focus,
    .navbar-inverse .navbar-nav .open .dropdown-menu>li>a:hover {
        color: rgb(255, 255, 255);
        background-color: transparent;
    }

    .navbar-inverse .navbar-nav .open .dropdown-menu>.active>a,
    .navbar-inverse .navbar-nav .open .dropdown-menu>.active>a:focus,
    .navbar-inverse .navbar-nav .open .dropdown-menu>.active>a:hover {
        color: rgb(255, 255, 255);
        background-color: rgb(9, 9, 9);
    }

    .navbar-inverse .navbar-nav .open .dropdown-menu>.disabled>a,
    .navbar-inverse .navbar-nav .open .dropdown-menu>.disabled>a:focus,
    .navbar-inverse .navbar-nav .open .dropdown-menu>.disabled>a:hover {
        color: rgb(68, 68, 68);
        background-color: transparent;
    }
}

.navbar-inverse .navbar-link {
    color: rgb(157, 157, 157);
}

.navbar-inverse .navbar-link:hover {
    color: rgb(255, 255, 255);
}

.navbar-inverse .btn-link {
    color: rgb(157, 157, 157);
}

.navbar-inverse .btn-link:focus,
.navbar-inverse .btn-link:hover {
    color: rgb(255, 255, 255);
}

.navbar-inverse .btn-link[disabled]:focus,
.navbar-inverse .btn-link[disabled]:hover,
fieldset[disabled] .navbar-inverse .btn-link:focus,
fieldset[disabled] .navbar-inverse .btn-link:hover {
    color: rgb(68, 68, 68);
}

.breadcrumb {
    padding-top: 8px;
    padding-right: 15px;
    padding-bottom: 8px;
    padding-left: 15px;
    margin-bottom: 22px;
    list-style-type: none;
    list-style-image: none;
    background-color: rgb(245, 245, 245);
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
}

.breadcrumb>li {
    display: inline-block;
}

.breadcrumb>li+li:before {
    content: "/ ";
    padding-top: 0;
    padding-right: 5px;
    padding-bottom: 0;
    padding-left: 5px;
    color: rgb(204, 204, 204);
}

.breadcrumb>.active {
    color: rgb(119, 119, 119);
}

.pagination {
    display: inline-block;
    padding-left: 0;
    margin-top: 22px;
    margin-right: 0;
    margin-bottom: 22px;
    margin-left: 0;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
}

.pagination>li {
    display: inline;
}

.pagination>li>a,
.pagination>li>span {
    position: relative;
    float: left;
    padding-top: 6px;
    padding-right: 12px;
    padding-bottom: 6px;
    padding-left: 12px;
    line-height: 1.6;
    text-decoration-line: none;
    color: rgb(48, 151, 209);
    background-color: rgb(255, 255, 255);
    border-top-color: rgb(221, 221, 221);
    border-top-style: solid;
    border-top-width: 1px;
    border-right-color: rgb(221, 221, 221);
    border-right-style: solid;
    border-right-width: 1px;
    border-bottom-color: rgb(221, 221, 221);
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-left-color: rgb(221, 221, 221);
    border-left-style: solid;
    border-left-width: 1px;
    margin-left: -1px;
}

.pagination>li:first-child>a,
.pagination>li:first-child>span {
    margin-left: 0;
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
}

.pagination>li:last-child>a,
.pagination>li:last-child>span {
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
}

.pagination>li>a:focus,
.pagination>li>a:hover,
.pagination>li>span:focus,
.pagination>li>span:hover {
    z-index: 2;
    color: rgb(33, 106, 148);
    background-color: rgb(238, 238, 238);
    border-top-color: rgb(221, 221, 221);
    border-right-color: rgb(221, 221, 221);
    border-bottom-color: rgb(221, 221, 221);
    border-left-color: rgb(221, 221, 221);
}

.pagination>.active>a,
.pagination>.active>a:focus,
.pagination>.active>a:hover,
.pagination>.active>span,
.pagination>.active>span:focus,
.pagination>.active>span:hover {
    z-index: 3;
    color: rgb(255, 255, 255);
    background-color: rgb(48, 151, 209);
    border-top-color: rgb(48, 151, 209);
    border-right-color: rgb(48, 151, 209);
    border-bottom-color: rgb(48, 151, 209);
    border-left-color: rgb(48, 151, 209);
    cursor: default;
}

.pagination>.disabled>a,
.pagination>.disabled>a:focus,
.pagination>.disabled>a:hover,
.pagination>.disabled>span,
.pagination>.disabled>span:focus,
.pagination>.disabled>span:hover {
    color: rgb(119, 119, 119);
    background-color: rgb(255, 255, 255);
    border-top-color: rgb(221, 221, 221);
    border-right-color: rgb(221, 221, 221);
    border-bottom-color: rgb(221, 221, 221);
    border-left-color: rgb(221, 221, 221);
}

.pagination-lg>li>a,
.pagination-lg>li>span {
    padding-top: 10px;
    padding-right: 16px;
    padding-bottom: 10px;
    padding-left: 16px;
    font-size: 18px;
    line-height: 1.3333333;
}

.pagination-lg>li:first-child>a,
.pagination-lg>li:first-child>span {
    border-bottom-left-radius: 6px;
    border-top-left-radius: 6px;
}

.pagination-lg>li:last-child>a,
.pagination-lg>li:last-child>span {
    border-bottom-right-radius: 6px;
    border-top-right-radius: 6px;
}

.pagination-sm>li>a,
.pagination-sm>li>span {
    padding-top: 5px;
    padding-right: 10px;
    padding-bottom: 5px;
    padding-left: 10px;
    font-size: 12px;
    line-height: 1.5;
}

.pagination-sm>li:first-child>a,
.pagination-sm>li:first-child>span {
    border-bottom-left-radius: 3px;
    border-top-left-radius: 3px;
}

.pagination-sm>li:last-child>a,
.pagination-sm>li:last-child>span {
    border-bottom-right-radius: 3px;
    border-top-right-radius: 3px;
}

.pager {
    padding-left: 0;
    margin-top: 22px;
    margin-right: 0;
    margin-bottom: 22px;
    margin-left: 0;
    list-style-type: none;
    list-style-image: none;
    text-align: center;
}

.pager:after,
.pager:before {
    content: " ";
    display: table;
}

.pager:after {
    clear: both;
}

.pager li {
    display: inline;
}

.pager li>a,
.pager li>span {
    display: inline-block;
    padding-top: 5px;
    padding-right: 14px;
    padding-bottom: 5px;
    padding-left: 14px;
    background-color: rgb(255, 255, 255);
    border-top-color: rgb(221, 221, 221);
    border-top-style: solid;
    border-top-width: 1px;
    border-right-color: rgb(221, 221, 221);
    border-right-style: solid;
    border-right-width: 1px;
    border-bottom-color: rgb(221, 221, 221);
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-left-color: rgb(221, 221, 221);
    border-left-style: solid;
    border-left-width: 1px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    border-bottom-left-radius: 15px;
}

.pager li>a:focus,
.pager li>a:hover {
    text-decoration-line: none;
    background-color: rgb(238, 238, 238);
}

.pager .next>a,
.pager .next>span {
    float: right;
}

.pager .previous>a,
.pager .previous>span {
    float: left;
}

.pager .disabled>a,
.pager .disabled>a:focus,
.pager .disabled>a:hover,
.pager .disabled>span {
    color: rgb(119, 119, 119);
    background-color: rgb(255, 255, 255);
}

.label {
    display: inline;
    padding-top: 0.2em;
    padding-right: 0.6em;
    padding-bottom: 0.3em;
    padding-left: 0.6em;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    color: rgb(255, 255, 255);
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-top-left-radius: 0.25em;
    border-top-right-radius: 0.25em;
    border-bottom-right-radius: 0.25em;
    border-bottom-left-radius: 0.25em;
}

.label:empty {
    display: none;
}

.btn .label {
    position: relative;
    top: -1px;
}

a.label:focus,
a.label:hover {
    color: rgb(255, 255, 255);
    text-decoration-line: none;
    cursor: pointer;
}

.label-default {
    background-color: rgb(119, 119, 119);
}

.label-default[href]:focus,
.label-default[href]:hover {
    background-color: rgb(94, 94, 94);
}

.label-primary {
    background-color: rgb(48, 151, 209);
}

.label-primary[href]:focus,
.label-primary[href]:hover {
    background-color: rgb(37, 121, 169);
}

.label-success {
    background-color: rgb(42, 178, 123);
}

.label-success[href]:focus,
.label-success[href]:hover {
    background-color: rgb(32, 137, 94);
}

.label-info {
    background-color: rgb(142, 180, 203);
}

.label-info[href]:focus,
.label-info[href]:hover {
    background-color: rgb(107, 157, 187);
}

.label-warning {
    background-color: rgb(203, 185, 86);
}

.label-warning[href]:focus,
.label-warning[href]:hover {
    background-color: rgb(182, 163, 56);
}

.label-danger {
    background-color: rgb(191, 83, 41);
}

.label-danger[href]:focus,
.label-danger[href]:hover {
    background-color: rgb(149, 65, 32);
}

.badge {
    display: inline-block;
    min-width: 10px;
    padding-top: 3px;
    padding-right: 7px;
    padding-bottom: 3px;
    padding-left: 7px;
    font-size: 12px;
    font-weight: 700;
    color: rgb(255, 255, 255);
    line-height: 1;
    vertical-align: middle;
    white-space: nowrap;
    text-align: center;
    background-color: rgb(119, 119, 119);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
}

.badge:empty {
    display: none;
}

.btn .badge {
    position: relative;
    top: -1px;
}

.btn-group-xs>.btn .badge,
.btn-xs .badge {
    top: 0;
    padding-top: 1px;
    padding-right: 5px;
    padding-bottom: 1px;
    padding-left: 5px;
}

.list-group-item.active>.badge,
.nav-pills>.active>a>.badge {
    color: rgb(48, 151, 209);
    background-color: rgb(255, 255, 255);
}

.list-group-item>.badge {
    float: right;
}

.list-group-item>.badge+.badge {
    margin-right: 5px;
}

.nav-pills>li>a>.badge {
    margin-left: 3px;
}

a.badge:focus,
a.badge:hover {
    color: rgb(255, 255, 255);
    text-decoration-line: none;
    cursor: pointer;
}

.jumbotron {
    padding-top: 30px;
    padding-bottom: 30px;
    margin-bottom: 30px;
    background-color: rgb(238, 238, 238);
}

.jumbotron,
.jumbotron .h1,
.jumbotron h1 {
    color: inherit;
}

.jumbotron p {
    margin-bottom: 15px;
    font-size: 21px;
    font-weight: 200;
}

.jumbotron>hr {
    border-top-color: rgb(213, 213, 213);
}

.container-fluid .jumbotron,
.container .jumbotron {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
    padding-left: 15px;
    padding-right: 15px;
}

.jumbotron .container {
    max-width: 100%;
}

@media screen AND (min-width: 768px) {
    .jumbotron {
        padding-top: 48px;
        padding-bottom: 48px;
    }

    .container-fluid .jumbotron,
    .container .jumbotron {
        padding-left: 60px;
        padding-right: 60px;
    }

    .jumbotron .h1,
    .jumbotron h1 {
        font-size: 63px;
    }
}

.thumbnail {
    display: block;
    padding-top: 4px;
    padding-right: 4px;
    padding-bottom: 4px;
    padding-left: 4px;
    margin-bottom: 22px;
    line-height: 1.6;
    background-color: rgb(245, 248, 250);
    border-top-color: rgb(221, 221, 221);
    border-top-style: solid;
    border-top-width: 1px;
    border-right-color: rgb(221, 221, 221);
    border-right-style: solid;
    border-right-width: 1px;
    border-bottom-color: rgb(221, 221, 221);
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-left-color: rgb(221, 221, 221);
    border-left-style: solid;
    border-left-width: 1px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
}

.thumbnail>img,
.thumbnail a>img {
    display: block;
    max-width: 100%;
    height: auto;
    margin-left: auto;
    margin-right: auto;
}

.thumbnail .caption {
    padding-top: 9px;
    padding-right: 9px;
    padding-bottom: 9px;
    padding-left: 9px;
    color: rgb(99, 107, 111);
}

a.thumbnail.active,
a.thumbnail:focus,
a.thumbnail:hover {
    border-top-color: rgb(48, 151, 209);
    border-right-color: rgb(48, 151, 209);
    border-bottom-color: rgb(48, 151, 209);
    border-left-color: rgb(48, 151, 209);
}

.alert {
    padding-top: 15px;
    padding-right: 15px;
    padding-bottom: 15px;
    padding-left: 15px;
    margin-bottom: 22px;
    border-top-color: transparent;
    border-top-style: solid;
    border-top-width: 1px;
    border-right-color: transparent;
    border-right-style: solid;
    border-right-width: 1px;
    border-bottom-color: transparent;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-left-color: transparent;
    border-left-style: solid;
    border-left-width: 1px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
}

.alert h4 {
    margin-top: 0;
    color: inherit;
}

.alert .alert-link {
    font-weight: 700;
}

.alert>p,
.alert>ul {
    margin-bottom: 0;
}

.alert>p+p {
    margin-top: 5px;
}

.alert-dismissable,
.alert-dismissible {
    padding-right: 35px;
}

.alert-dismissable .close,
.alert-dismissible .close {
    position: relative;
    top: -2px;
    right: -21px;
    color: inherit;
}

.alert-success {
    background-color: rgb(223, 240, 216);
    border-top-color: rgb(214, 233, 198);
    border-right-color: rgb(214, 233, 198);
    border-bottom-color: rgb(214, 233, 198);
    border-left-color: rgb(214, 233, 198);
    color: rgb(60, 118, 61);
}

.alert-success hr {
    border-top-color: rgb(201, 226, 179);
}

.alert-success .alert-link {
    color: rgb(43, 84, 44);
}

.alert-info {
    background-color: rgb(217, 237, 247);
    border-top-color: rgb(188, 232, 241);
    border-right-color: rgb(188, 232, 241);
    border-bottom-color: rgb(188, 232, 241);
    border-left-color: rgb(188, 232, 241);
    color: rgb(49, 112, 143);
}

.alert-info hr {
    border-top-color: rgb(166, 225, 236);
}

.alert-info .alert-link {
    color: rgb(36, 82, 105);
}

.alert-warning {
    background-color: rgb(252, 248, 227);
    border-top-color: rgb(250, 235, 204);
    border-right-color: rgb(250, 235, 204);
    border-bottom-color: rgb(250, 235, 204);
    border-left-color: rgb(250, 235, 204);
    color: rgb(138, 109, 59);
}

.alert-warning hr {
    border-top-color: rgb(247, 225, 181);
}

.alert-warning .alert-link {
    color: rgb(102, 81, 44);
}

.alert-danger {
    background-color: rgb(242, 222, 222);
    border-top-color: rgb(235, 204, 209);
    border-right-color: rgb(235, 204, 209);
    border-bottom-color: rgb(235, 204, 209);
    border-left-color: rgb(235, 204, 209);
    color: rgb(169, 68, 66);
}

.alert-danger hr {
    border-top-color: rgb(228, 185, 192);
}

.alert-danger .alert-link {
    color: rgb(132, 53, 52);
}

@keyframes progress-bar-stripes {}

@keyframes progress-bar-stripes {}

.progress {
    overflow-x: hidden;
    overflow-y: hidden;
    height: 22px;
    margin-bottom: 22px;
    background-color: rgb(245, 245, 245);
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
}

.progress-bar {
    float: left;
    width: 0px;
    height: 100%;
    font-size: 12px;
    line-height: 22px;
    color: rgb(255, 255, 255);
    text-align: center;
    background-color: rgb(48, 151, 209);
    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
    transition-property: width;
    transition-duration: 0.6s;
    transition-timing-function: ease;
    transition-delay: initial;
}

.progress-bar-striped,
.progress-striped .progress-bar {
    background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 0, transparent 50%, rgba(255, 255, 255, 0.15) 0, rgba(255, 255, 255, 0.15) 75%, transparent 0, transparent);
    background-size: 40px 40px;
}

.progress-bar.active,
.progress.active .progress-bar {}

.progress-bar-success {
    background-color: rgb(42, 178, 123);
}

.progress-striped .progress-bar-success {
    background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 0, transparent 50%, rgba(255, 255, 255, 0.15) 0, rgba(255, 255, 255, 0.15) 75%, transparent 0, transparent);
}

.progress-bar-info {
    background-color: rgb(142, 180, 203);
}

.progress-striped .progress-bar-info {
    background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 0, transparent 50%, rgba(255, 255, 255, 0.15) 0, rgba(255, 255, 255, 0.15) 75%, transparent 0, transparent);
}

.progress-bar-warning {
    background-color: rgb(203, 185, 86);
}

.progress-striped .progress-bar-warning {
    background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 0, transparent 50%, rgba(255, 255, 255, 0.15) 0, rgba(255, 255, 255, 0.15) 75%, transparent 0, transparent);
}

.progress-bar-danger {
    background-color: rgb(191, 83, 41);
}

.progress-striped .progress-bar-danger {
    background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 0, transparent 50%, rgba(255, 255, 255, 0.15) 0, rgba(255, 255, 255, 0.15) 75%, transparent 0, transparent);
}

.media {
    margin-top: 15px;
}

.media:first-child {
    margin-top: 0;
}

.media,
.media-body {
    zoom: 1;
    overflow-x: hidden;
    overflow-y: hidden;
}

.media-body {
    width: 10000px;
}

.media-object {
    display: block;
}

.media-object.img-thumbnail {
    max-width: none;
}

.media-right,
.media>.pull-right {
    padding-left: 10px;
}

.media-left,
.media>.pull-left {
    padding-right: 10px;
}

.media-body,
.media-left,
.media-right {
    display: table-cell;
    vertical-align: top;
}

.media-middle {
    vertical-align: middle;
}

.media-bottom {
    vertical-align: bottom;
}

.media-heading {
    margin-top: 0;
    margin-bottom: 5px;
}

.media-list {
    padding-left: 0;
    list-style-type: none;
    list-style-image: none;
}

.list-group {
    margin-bottom: 20px;
    padding-left: 0;
}

.list-group-item {
    position: relative;
    display: block;
    padding-top: 10px;
    padding-right: 15px;
    padding-bottom: 10px;
    padding-left: 15px;
    margin-bottom: -1px;
    background-color: rgb(255, 255, 255);
    border-top-color: rgb(211, 224, 233);
    border-top-style: solid;
    border-top-width: 1px;
    border-right-color: rgb(211, 224, 233);
    border-right-style: solid;
    border-right-width: 1px;
    border-bottom-color: rgb(211, 224, 233);
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-left-color: rgb(211, 224, 233);
    border-left-style: solid;
    border-left-width: 1px;
}

.list-group-item:first-child {
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
}

.list-group-item:last-child {
    margin-bottom: 0;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
}

a.list-group-item,
button.list-group-item {
    color: rgb(85, 85, 85);
}

a.list-group-item .list-group-item-heading,
button.list-group-item .list-group-item-heading {
    color: rgb(51, 51, 51);
}

a.list-group-item:focus,
a.list-group-item:hover,
button.list-group-item:focus,
button.list-group-item:hover {
    text-decoration-line: none;
    color: rgb(85, 85, 85);
    background-color: rgb(245, 245, 245);
}

button.list-group-item {
    width: 100%;
    text-align: left;
}

.list-group-item.disabled,
.list-group-item.disabled:focus,
.list-group-item.disabled:hover {
    background-color: rgb(238, 238, 238);
    color: rgb(119, 119, 119);
}

.list-group-item.disabled .list-group-item-heading,
.list-group-item.disabled:focus .list-group-item-heading,
.list-group-item.disabled:hover .list-group-item-heading {
    color: inherit;
}

.list-group-item.disabled .list-group-item-text,
.list-group-item.disabled:focus .list-group-item-text,
.list-group-item.disabled:hover .list-group-item-text {
    color: rgb(119, 119, 119);
}

.list-group-item.active,
.list-group-item.active:focus,
.list-group-item.active:hover {
    z-index: 2;
    color: rgb(255, 255, 255);
    background-color: rgb(48, 151, 209);
    border-top-color: rgb(48, 151, 209);
    border-right-color: rgb(48, 151, 209);
    border-bottom-color: rgb(48, 151, 209);
    border-left-color: rgb(48, 151, 209);
}

.list-group-item.active .list-group-item-heading,
.list-group-item.active .list-group-item-heading>.small,
.list-group-item.active .list-group-item-heading>small,
.list-group-item.active:focus .list-group-item-heading,
.list-group-item.active:focus .list-group-item-heading>.small,
.list-group-item.active:focus .list-group-item-heading>small,
.list-group-item.active:hover .list-group-item-heading,
.list-group-item.active:hover .list-group-item-heading>.small,
.list-group-item.active:hover .list-group-item-heading>small {
    color: inherit;
}

.list-group-item.active .list-group-item-text,
.list-group-item.active:focus .list-group-item-text,
.list-group-item.active:hover .list-group-item-text {
    color: rgb(215, 235, 246);
}

.list-group-item-success {
    color: rgb(60, 118, 61);
    background-color: rgb(223, 240, 216);
}

a.list-group-item-success,
button.list-group-item-success {
    color: rgb(60, 118, 61);
}

a.list-group-item-success .list-group-item-heading,
button.list-group-item-success .list-group-item-heading {
    color: inherit;
}

a.list-group-item-success:focus,
a.list-group-item-success:hover,
button.list-group-item-success:focus,
button.list-group-item-success:hover {
    color: rgb(60, 118, 61);
    background-color: rgb(208, 233, 198);
}

a.list-group-item-success.active,
a.list-group-item-success.active:focus,
a.list-group-item-success.active:hover,
button.list-group-item-success.active,
button.list-group-item-success.active:focus,
button.list-group-item-success.active:hover {
    color: rgb(255, 255, 255);
    background-color: rgb(60, 118, 61);
    border-top-color: rgb(60, 118, 61);
    border-right-color: rgb(60, 118, 61);
    border-bottom-color: rgb(60, 118, 61);
    border-left-color: rgb(60, 118, 61);
}

.list-group-item-info {
    color: rgb(49, 112, 143);
    background-color: rgb(217, 237, 247);
}

a.list-group-item-info,
button.list-group-item-info {
    color: rgb(49, 112, 143);
}

a.list-group-item-info .list-group-item-heading,
button.list-group-item-info .list-group-item-heading {
    color: inherit;
}

a.list-group-item-info:focus,
a.list-group-item-info:hover,
button.list-group-item-info:focus,
button.list-group-item-info:hover {
    color: rgb(49, 112, 143);
    background-color: rgb(196, 227, 243);
}

a.list-group-item-info.active,
a.list-group-item-info.active:focus,
a.list-group-item-info.active:hover,
button.list-group-item-info.active,
button.list-group-item-info.active:focus,
button.list-group-item-info.active:hover {
    color: rgb(255, 255, 255);
    background-color: rgb(49, 112, 143);
    border-top-color: rgb(49, 112, 143);
    border-right-color: rgb(49, 112, 143);
    border-bottom-color: rgb(49, 112, 143);
    border-left-color: rgb(49, 112, 143);
}

.list-group-item-warning {
    color: rgb(138, 109, 59);
    background-color: rgb(252, 248, 227);
}

a.list-group-item-warning,
button.list-group-item-warning {
    color: rgb(138, 109, 59);
}

a.list-group-item-warning .list-group-item-heading,
button.list-group-item-warning .list-group-item-heading {
    color: inherit;
}

a.list-group-item-warning:focus,
a.list-group-item-warning:hover,
button.list-group-item-warning:focus,
button.list-group-item-warning:hover {
    color: rgb(138, 109, 59);
    background-color: rgb(250, 242, 204);
}

a.list-group-item-warning.active,
a.list-group-item-warning.active:focus,
a.list-group-item-warning.active:hover,
button.list-group-item-warning.active,
button.list-group-item-warning.active:focus,
button.list-group-item-warning.active:hover {
    color: rgb(255, 255, 255);
    background-color: rgb(138, 109, 59);
    border-top-color: rgb(138, 109, 59);
    border-right-color: rgb(138, 109, 59);
    border-bottom-color: rgb(138, 109, 59);
    border-left-color: rgb(138, 109, 59);
}

.list-group-item-danger {
    color: rgb(169, 68, 66);
    background-color: rgb(242, 222, 222);
}

a.list-group-item-danger,
button.list-group-item-danger {
    color: rgb(169, 68, 66);
}

a.list-group-item-danger .list-group-item-heading,
button.list-group-item-danger .list-group-item-heading {
    color: inherit;
}

a.list-group-item-danger:focus,
a.list-group-item-danger:hover,
button.list-group-item-danger:focus,
button.list-group-item-danger:hover {
    color: rgb(169, 68, 66);
    background-color: rgb(235, 204, 204);
}

a.list-group-item-danger.active,
a.list-group-item-danger.active:focus,
a.list-group-item-danger.active:hover,
button.list-group-item-danger.active,
button.list-group-item-danger.active:focus,
button.list-group-item-danger.active:hover {
    color: rgb(255, 255, 255);
    background-color: rgb(169, 68, 66);
    border-top-color: rgb(169, 68, 66);
    border-right-color: rgb(169, 68, 66);
    border-bottom-color: rgb(169, 68, 66);
    border-left-color: rgb(169, 68, 66);
}

.list-group-item-heading {
    margin-top: 0;
    margin-bottom: 5px;
}

.list-group-item-text {
    margin-bottom: 0;
    line-height: 1.3;
}

.panel {
    margin-bottom: 22px;
    background-color: rgb(255, 255, 255);
    border-top-color: transparent;
    border-top-style: solid;
    border-top-width: 1px;
    border-right-color: transparent;
    border-right-style: solid;
    border-right-width: 1px;
    border-bottom-color: transparent;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-left-color: transparent;
    border-left-style: solid;
    border-left-width: 1px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
}

.panel-body {
    padding-top: 15px;
    padding-right: 15px;
    padding-bottom: 15px;
    padding-left: 15px;
}

.panel-body:after,
.panel-body:before {
    content: " ";
    display: table;
}

.panel-body:after {
    clear: both;
}

.panel-heading {
    padding-top: 10px;
    padding-right: 15px;
    padding-bottom: 10px;
    padding-left: 15px;
    border-bottom-color: transparent;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
}

.panel-heading>.dropdown .dropdown-toggle,
.panel-title {
    color: inherit;
}

.panel-title {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 16px;
}

.panel-title>.small,
.panel-title>.small>a,
.panel-title>a,
.panel-title>small,
.panel-title>small>a {
    color: inherit;
}

.panel-footer {
    padding-top: 10px;
    padding-right: 15px;
    padding-bottom: 10px;
    padding-left: 15px;
    background-color: rgb(245, 245, 245);
    border-top-color: rgb(211, 224, 233);
    border-top-style: solid;
    border-top-width: 1px;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
}

.panel>.list-group,
.panel>.panel-collapse>.list-group {
    margin-bottom: 0;
}

.panel>.list-group .list-group-item,
.panel>.panel-collapse>.list-group .list-group-item {
    border-top-width: 1px;
    border-right-width: 0;
    border-bottom-width: 1px;
    border-left-width: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.panel>.list-group:first-child .list-group-item:first-child,
.panel>.panel-collapse>.list-group:first-child .list-group-item:first-child {
    border-top-color: initial;
    border-top-style: initial;
    border-top-width: 0;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
}

.panel>.list-group:last-child .list-group-item:last-child,
.panel>.panel-collapse>.list-group:last-child .list-group-item:last-child {
    border-bottom-color: initial;
    border-bottom-style: initial;
    border-bottom-width: 0;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
}

.panel>.panel-heading+.panel-collapse>.list-group .list-group-item:first-child {
    border-top-right-radius: 0;
    border-top-left-radius: 0;
}

.list-group+.panel-footer,
.panel-heading+.list-group .list-group-item:first-child {
    border-top-width: 0;
}

.panel>.panel-collapse>.table,
.panel>.table,
.panel>.table-responsive>.table {
    margin-bottom: 0;
}

.panel>.panel-collapse>.table caption,
.panel>.table-responsive>.table caption,
.panel>.table caption {
    padding-left: 15px;
    padding-right: 15px;
}

.panel>.table-responsive:first-child>.table:first-child,
.panel>.table-responsive:first-child>.table:first-child>tbody:first-child>tr:first-child,
.panel>.table-responsive:first-child>.table:first-child>thead:first-child>tr:first-child,
.panel>.table:first-child,
.panel>.table:first-child>tbody:first-child>tr:first-child,
.panel>.table:first-child>thead:first-child>tr:first-child {
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
}

.panel>.table-responsive:first-child>.table:first-child>tbody:first-child>tr:first-child td:first-child,
.panel>.table-responsive:first-child>.table:first-child>tbody:first-child>tr:first-child th:first-child,
.panel>.table-responsive:first-child>.table:first-child>thead:first-child>tr:first-child td:first-child,
.panel>.table-responsive:first-child>.table:first-child>thead:first-child>tr:first-child th:first-child,
.panel>.table:first-child>tbody:first-child>tr:first-child td:first-child,
.panel>.table:first-child>tbody:first-child>tr:first-child th:first-child,
.panel>.table:first-child>thead:first-child>tr:first-child td:first-child,
.panel>.table:first-child>thead:first-child>tr:first-child th:first-child {
    border-top-left-radius: 3px;
}

.panel>.table-responsive:first-child>.table:first-child>tbody:first-child>tr:first-child td:last-child,
.panel>.table-responsive:first-child>.table:first-child>tbody:first-child>tr:first-child th:last-child,
.panel>.table-responsive:first-child>.table:first-child>thead:first-child>tr:first-child td:last-child,
.panel>.table-responsive:first-child>.table:first-child>thead:first-child>tr:first-child th:last-child,
.panel>.table:first-child>tbody:first-child>tr:first-child td:last-child,
.panel>.table:first-child>tbody:first-child>tr:first-child th:last-child,
.panel>.table:first-child>thead:first-child>tr:first-child td:last-child,
.panel>.table:first-child>thead:first-child>tr:first-child th:last-child {
    border-top-right-radius: 3px;
}

.panel>.table-responsive:last-child>.table:last-child,
.panel>.table-responsive:last-child>.table:last-child>tbody:last-child>tr:last-child,
.panel>.table-responsive:last-child>.table:last-child>tfoot:last-child>tr:last-child,
.panel>.table:last-child,
.panel>.table:last-child>tbody:last-child>tr:last-child,
.panel>.table:last-child>tfoot:last-child>tr:last-child {
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
}

.panel>.table-responsive:last-child>.table:last-child>tbody:last-child>tr:last-child td:first-child,
.panel>.table-responsive:last-child>.table:last-child>tbody:last-child>tr:last-child th:first-child,
.panel>.table-responsive:last-child>.table:last-child>tfoot:last-child>tr:last-child td:first-child,
.panel>.table-responsive:last-child>.table:last-child>tfoot:last-child>tr:last-child th:first-child,
.panel>.table:last-child>tbody:last-child>tr:last-child td:first-child,
.panel>.table:last-child>tbody:last-child>tr:last-child th:first-child,
.panel>.table:last-child>tfoot:last-child>tr:last-child td:first-child,
.panel>.table:last-child>tfoot:last-child>tr:last-child th:first-child {
    border-bottom-left-radius: 3px;
}

.panel>.table-responsive:last-child>.table:last-child>tbody:last-child>tr:last-child td:last-child,
.panel>.table-responsive:last-child>.table:last-child>tbody:last-child>tr:last-child th:last-child,
.panel>.table-responsive:last-child>.table:last-child>tfoot:last-child>tr:last-child td:last-child,
.panel>.table-responsive:last-child>.table:last-child>tfoot:last-child>tr:last-child th:last-child,
.panel>.table:last-child>tbody:last-child>tr:last-child td:last-child,
.panel>.table:last-child>tbody:last-child>tr:last-child th:last-child,
.panel>.table:last-child>tfoot:last-child>tr:last-child td:last-child,
.panel>.table:last-child>tfoot:last-child>tr:last-child th:last-child {
    border-bottom-right-radius: 3px;
}

.panel>.panel-body+.table,
.panel>.panel-body+.table-responsive,
.panel>.table+.panel-body,
.panel>.table-responsive+.panel-body {
    border-top-color: rgb(221, 221, 221);
    border-top-style: solid;
    border-top-width: 1px;
}

.panel>.table>tbody:first-child>tr:first-child td,
.panel>.table>tbody:first-child>tr:first-child th {
    border-top-color: initial;
    border-top-style: initial;
    border-top-width: 0;
}

.panel>.table-bordered,
.panel>.table-responsive>.table-bordered {
    border-top-color: initial;
    border-top-style: initial;
    border-top-width: 0;
    border-right-color: initial;
    border-right-style: initial;
    border-right-width: 0;
    border-bottom-color: initial;
    border-bottom-style: initial;
    border-bottom-width: 0;
    border-left-color: initial;
    border-left-style: initial;
    border-left-width: 0;
}

.panel>.table-bordered>tbody>tr>td:first-child,
.panel>.table-bordered>tbody>tr>th:first-child,
.panel>.table-bordered>tfoot>tr>td:first-child,
.panel>.table-bordered>tfoot>tr>th:first-child,
.panel>.table-bordered>thead>tr>td:first-child,
.panel>.table-bordered>thead>tr>th:first-child,
.panel>.table-responsive>.table-bordered>tbody>tr>td:first-child,
.panel>.table-responsive>.table-bordered>tbody>tr>th:first-child,
.panel>.table-responsive>.table-bordered>tfoot>tr>td:first-child,
.panel>.table-responsive>.table-bordered>tfoot>tr>th:first-child,
.panel>.table-responsive>.table-bordered>thead>tr>td:first-child,
.panel>.table-responsive>.table-bordered>thead>tr>th:first-child {
    border-left-color: initial;
    border-left-style: initial;
    border-left-width: 0;
}

.panel>.table-bordered>tbody>tr>td:last-child,
.panel>.table-bordered>tbody>tr>th:last-child,
.panel>.table-bordered>tfoot>tr>td:last-child,
.panel>.table-bordered>tfoot>tr>th:last-child,
.panel>.table-bordered>thead>tr>td:last-child,
.panel>.table-bordered>thead>tr>th:last-child,
.panel>.table-responsive>.table-bordered>tbody>tr>td:last-child,
.panel>.table-responsive>.table-bordered>tbody>tr>th:last-child,
.panel>.table-responsive>.table-bordered>tfoot>tr>td:last-child,
.panel>.table-responsive>.table-bordered>tfoot>tr>th:last-child,
.panel>.table-responsive>.table-bordered>thead>tr>td:last-child,
.panel>.table-responsive>.table-bordered>thead>tr>th:last-child {
    border-right-color: initial;
    border-right-style: initial;
    border-right-width: 0;
}

.panel>.table-bordered>tbody>tr:first-child>td,
.panel>.table-bordered>tbody>tr:first-child>th,
.panel>.table-bordered>tbody>tr:last-child>td,
.panel>.table-bordered>tbody>tr:last-child>th,
.panel>.table-bordered>tfoot>tr:last-child>td,
.panel>.table-bordered>tfoot>tr:last-child>th,
.panel>.table-bordered>thead>tr:first-child>td,
.panel>.table-bordered>thead>tr:first-child>th,
.panel>.table-responsive>.table-bordered>tbody>tr:first-child>td,
.panel>.table-responsive>.table-bordered>tbody>tr:first-child>th,
.panel>.table-responsive>.table-bordered>tbody>tr:last-child>td,
.panel>.table-responsive>.table-bordered>tbody>tr:last-child>th,
.panel>.table-responsive>.table-bordered>tfoot>tr:last-child>td,
.panel>.table-responsive>.table-bordered>tfoot>tr:last-child>th,
.panel>.table-responsive>.table-bordered>thead>tr:first-child>td,
.panel>.table-responsive>.table-bordered>thead>tr:first-child>th {
    border-bottom-color: initial;
    border-bottom-style: initial;
    border-bottom-width: 0;
}

.panel>.table-responsive {
    border-top-color: initial;
    border-top-style: initial;
    border-top-width: 0;
    border-right-color: initial;
    border-right-style: initial;
    border-right-width: 0;
    border-bottom-color: initial;
    border-bottom-style: initial;
    border-bottom-width: 0;
    border-left-color: initial;
    border-left-style: initial;
    border-left-width: 0;
    margin-bottom: 0;
}

.panel-group {
    margin-bottom: 22px;
}

.panel-group .panel {
    margin-bottom: 0;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
}

.panel-group .panel+.panel {
    margin-top: 5px;
}

.panel-group .panel-heading {
    border-bottom-color: initial;
    border-bottom-style: initial;
    border-bottom-width: 0;
}

.panel-group .panel-heading+.panel-collapse>.list-group,
.panel-group .panel-heading+.panel-collapse>.panel-body {
    border-top-color: rgb(211, 224, 233);
    border-top-style: solid;
    border-top-width: 1px;
}

.panel-group .panel-footer {
    border-top-color: initial;
    border-top-style: initial;
    border-top-width: 0;
}

.panel-group .panel-footer+.panel-collapse .panel-body {
    border-bottom-color: rgb(211, 224, 233);
    border-bottom-style: solid;
    border-bottom-width: 1px;
}

.panel-default {
    border-top-color: rgb(211, 224, 233);
    border-right-color: rgb(211, 224, 233);
    border-bottom-color: rgb(211, 224, 233);
    border-left-color: rgb(211, 224, 233);
}

.panel-default>.panel-heading {
    color: rgb(51, 51, 51);
    background-color: rgb(255, 255, 255);
    border-top-color: rgb(211, 224, 233);
    border-right-color: rgb(211, 224, 233);
    border-bottom-color: rgb(211, 224, 233);
    border-left-color: rgb(211, 224, 233);
}

.panel-default>.panel-heading+.panel-collapse>.panel-body {
    border-top-color: rgb(211, 224, 233);
}

.panel-default>.panel-heading .badge {
    color: rgb(255, 255, 255);
    background-color: rgb(51, 51, 51);
}

.panel-default>.panel-footer+.panel-collapse>.panel-body {
    border-bottom-color: rgb(211, 224, 233);
}

.panel-primary {
    border-top-color: rgb(48, 151, 209);
    border-right-color: rgb(48, 151, 209);
    border-bottom-color: rgb(48, 151, 209);
    border-left-color: rgb(48, 151, 209);
}

.panel-primary>.panel-heading {
    color: rgb(255, 255, 255);
    background-color: rgb(48, 151, 209);
    border-top-color: rgb(48, 151, 209);
    border-right-color: rgb(48, 151, 209);
    border-bottom-color: rgb(48, 151, 209);
    border-left-color: rgb(48, 151, 209);
}

.panel-primary>.panel-heading+.panel-collapse>.panel-body {
    border-top-color: rgb(48, 151, 209);
}

.panel-primary>.panel-heading .badge {
    color: rgb(48, 151, 209);
    background-color: rgb(255, 255, 255);
}

.panel-primary>.panel-footer+.panel-collapse>.panel-body {
    border-bottom-color: rgb(48, 151, 209);
}

.panel-success {
    border-top-color: rgb(214, 233, 198);
    border-right-color: rgb(214, 233, 198);
    border-bottom-color: rgb(214, 233, 198);
    border-left-color: rgb(214, 233, 198);
}

.panel-success>.panel-heading {
    color: rgb(60, 118, 61);
    background-color: rgb(223, 240, 216);
    border-top-color: rgb(214, 233, 198);
    border-right-color: rgb(214, 233, 198);
    border-bottom-color: rgb(214, 233, 198);
    border-left-color: rgb(214, 233, 198);
}

.panel-success>.panel-heading+.panel-collapse>.panel-body {
    border-top-color: rgb(214, 233, 198);
}

.panel-success>.panel-heading .badge {
    color: rgb(223, 240, 216);
    background-color: rgb(60, 118, 61);
}

.panel-success>.panel-footer+.panel-collapse>.panel-body {
    border-bottom-color: rgb(214, 233, 198);
}

.panel-info {
    border-top-color: rgb(188, 232, 241);
    border-right-color: rgb(188, 232, 241);
    border-bottom-color: rgb(188, 232, 241);
    border-left-color: rgb(188, 232, 241);
}

.panel-info>.panel-heading {
    color: rgb(49, 112, 143);
    background-color: rgb(217, 237, 247);
    border-top-color: rgb(188, 232, 241);
    border-right-color: rgb(188, 232, 241);
    border-bottom-color: rgb(188, 232, 241);
    border-left-color: rgb(188, 232, 241);
}

.panel-info>.panel-heading+.panel-collapse>.panel-body {
    border-top-color: rgb(188, 232, 241);
}

.panel-info>.panel-heading .badge {
    color: rgb(217, 237, 247);
    background-color: rgb(49, 112, 143);
}

.panel-info>.panel-footer+.panel-collapse>.panel-body {
    border-bottom-color: rgb(188, 232, 241);
}

.panel-warning {
    border-top-color: rgb(250, 235, 204);
    border-right-color: rgb(250, 235, 204);
    border-bottom-color: rgb(250, 235, 204);
    border-left-color: rgb(250, 235, 204);
}

.panel-warning>.panel-heading {
    color: rgb(138, 109, 59);
    background-color: rgb(252, 248, 227);
    border-top-color: rgb(250, 235, 204);
    border-right-color: rgb(250, 235, 204);
    border-bottom-color: rgb(250, 235, 204);
    border-left-color: rgb(250, 235, 204);
}

.panel-warning>.panel-heading+.panel-collapse>.panel-body {
    border-top-color: rgb(250, 235, 204);
}

.panel-warning>.panel-heading .badge {
    color: rgb(252, 248, 227);
    background-color: rgb(138, 109, 59);
}

.panel-warning>.panel-footer+.panel-collapse>.panel-body {
    border-bottom-color: rgb(250, 235, 204);
}

.panel-danger {
    border-top-color: rgb(235, 204, 209);
    border-right-color: rgb(235, 204, 209);
    border-bottom-color: rgb(235, 204, 209);
    border-left-color: rgb(235, 204, 209);
}

.panel-danger>.panel-heading {
    color: rgb(169, 68, 66);
    background-color: rgb(242, 222, 222);
    border-top-color: rgb(235, 204, 209);
    border-right-color: rgb(235, 204, 209);
    border-bottom-color: rgb(235, 204, 209);
    border-left-color: rgb(235, 204, 209);
}

.panel-danger>.panel-heading+.panel-collapse>.panel-body {
    border-top-color: rgb(235, 204, 209);
}

.panel-danger>.panel-heading .badge {
    color: rgb(242, 222, 222);
    background-color: rgb(169, 68, 66);
}

.panel-danger>.panel-footer+.panel-collapse>.panel-body {
    border-bottom-color: rgb(235, 204, 209);
}

.embed-responsive {
    position: relative;
    display: block;
    height: 0px;
    padding-top: 0;
    padding-right: 0;
    padding-bottom: 0;
    padding-left: 0;
    overflow-x: hidden;
    overflow-y: hidden;
}

.embed-responsive .embed-responsive-item,
.embed-responsive embed,
.embed-responsive iframe,
.embed-responsive object,
.embed-responsive video {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    border-top-color: initial;
    border-top-style: initial;
    border-top-width: 0;
    border-right-color: initial;
    border-right-style: initial;
    border-right-width: 0;
    border-bottom-color: initial;
    border-bottom-style: initial;
    border-bottom-width: 0;
    border-left-color: initial;
    border-left-style: initial;
    border-left-width: 0;
}

.embed-responsive-16by9 {
    padding-bottom: 56.25%;
}

.embed-responsive-4by3 {
    padding-bottom: 75%;
}

.well {
    min-height: 20px;
    padding-top: 19px;
    padding-right: 19px;
    padding-bottom: 19px;
    padding-left: 19px;
    margin-bottom: 20px;
    background-color: rgb(245, 245, 245);
    border-top-color: rgb(227, 227, 227);
    border-top-style: solid;
    border-top-width: 1px;
    border-right-color: rgb(227, 227, 227);
    border-right-style: solid;
    border-right-width: 1px;
    border-bottom-color: rgb(227, 227, 227);
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-left-color: rgb(227, 227, 227);
    border-left-style: solid;
    border-left-width: 1px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
}

.well blockquote {
    border-top-color: rgba(0, 0, 0, 0.15);
    border-right-color: rgba(0, 0, 0, 0.15);
    border-bottom-color: rgba(0, 0, 0, 0.15);
    border-left-color: rgba(0, 0, 0, 0.15);
}

.well-lg {
    padding-top: 24px;
    padding-right: 24px;
    padding-bottom: 24px;
    padding-left: 24px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
}

.well-sm {
    padding-top: 9px;
    padding-right: 9px;
    padding-bottom: 9px;
    padding-left: 9px;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
}

.close {
    float: right;
    font-size: 21px;
    font-weight: 700;
    line-height: 1;
    color: rgb(0, 0, 0);
    text-shadow: 0 1px 0 rgb(255, 255, 255);
    opacity: 0.2;
}

.close:focus,
.close:hover {
    color: rgb(0, 0, 0);
    text-decoration-line: none;
    cursor: pointer;
    opacity: 0.5;
}

button.close {
    padding-top: 0;
    padding-right: 0;
    padding-bottom: 0;
    padding-left: 0;
    cursor: pointer;
    background-color: transparent;
    background-image: initial;
    border-top-color: initial;
    border-top-style: initial;
    border-top-width: 0;
    border-right-color: initial;
    border-right-style: initial;
    border-right-width: 0;
    border-bottom-color: initial;
    border-bottom-style: initial;
    border-bottom-width: 0;
    border-left-color: initial;
    border-left-style: initial;
    border-left-width: 0;
}

.modal,
.modal-open {
    overflow-x: hidden;
    overflow-y: hidden;
}

.modal {
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1050;
    outline-width: 0;
}

.modal.fade .modal-dialog {
    transform: translateY(-25%);
}

.modal.in .modal-dialog {
    transform: translate(0);
}

.modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto;
}

.modal-dialog {
    position: relative;
    width: auto;
    margin-top: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
    margin-left: 10px;
}

.modal-content {
    position: relative;
    background-color: rgb(255, 255, 255);
    border-top-color: rgba(0, 0, 0, 0.2);
    border-top-style: solid;
    border-top-width: 1px;
    border-right-color: rgba(0, 0, 0, 0.2);
    border-right-style: solid;
    border-right-width: 1px;
    border-bottom-color: rgba(0, 0, 0, 0.2);
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-left-color: rgba(0, 0, 0, 0.2);
    border-left-style: solid;
    border-left-width: 1px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
    box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
    background-clip: padding-box;
    outline-width: 0;
}

.modal-backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1040;
    background-color: rgb(0, 0, 0);
}

.modal-backdrop.fade {
    opacity: 0;
}

.modal-backdrop.in {
    opacity: 0.5;
}

.modal-header {
    padding-top: 15px;
    padding-right: 15px;
    padding-bottom: 15px;
    padding-left: 15px;
    border-bottom-color: rgb(229, 229, 229);
    border-bottom-style: solid;
    border-bottom-width: 1px;
}

.modal-header:after,
.modal-header:before {
    content: " ";
    display: table;
}

.modal-header:after {
    clear: both;
}

.modal-header .close {
    margin-top: -2px;
}

.modal-title {
    margin-top: 0;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 0;
    line-height: 1.6;
}

.modal-body {
    position: relative;
    padding-top: 15px;
    padding-right: 15px;
    padding-bottom: 15px;
    padding-left: 15px;
}

.modal-footer {
    padding-top: 15px;
    padding-right: 15px;
    padding-bottom: 15px;
    padding-left: 15px;
    text-align: right;
    border-top-color: rgb(229, 229, 229);
    border-top-style: solid;
    border-top-width: 1px;
}

.modal-footer:after,
.modal-footer:before {
    content: " ";
    display: table;
}

.modal-footer:after {
    clear: both;
}

.modal-footer .btn+.btn {
    margin-left: 5px;
    margin-bottom: 0;
}

.modal-footer .btn-group .btn+.btn {
    margin-left: -1px;
}

.modal-footer .btn-block+.btn-block {
    margin-left: 0;
}

.modal-scrollbar-measure {
    position: absolute;
    top: -9999px;
    width: 50px;
    height: 50px;
    overflow-x: scroll;
    overflow-y: scroll;
}

@media (min-width: 768px) {
    .modal-dialog {
        width: 600px;
        margin-top: 30px;
        margin-right: auto;
        margin-bottom: 30px;
        margin-left: auto;
    }

    .modal-content {
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
    }

    .modal-sm {
        width: 300px;
    }
}

@media (min-width: 992px) {
    .modal-lg {
        width: 900px;
    }
}

.tooltip {
    position: absolute;
    z-index: 1070;
    display: block;
    font-family: Raleway, sans-serif;
    font-style: normal;
    font-weight: 400;
    letter-spacing: normal;
    line-break: auto;
    line-height: 1.6;
    text-align: start;
    text-decoration-line: none;
    text-shadow: none;
    text-transform: none;
    white-space: normal;
    word-break: normal;
    word-spacing: normal;
    word-wrap: normal;
    font-size: 12px;
    opacity: 0;
}

.tooltip.in {
    opacity: 0.9;
}

.tooltip.top {
    margin-top: -3px;
    padding-top: 5px;
    padding-right: 0;
    padding-bottom: 5px;
    padding-left: 0;
}

.tooltip.right {
    margin-left: 3px;
    padding-top: 0;
    padding-right: 5px;
    padding-bottom: 0;
    padding-left: 5px;
}

.tooltip.bottom {
    margin-top: 3px;
    padding-top: 5px;
    padding-right: 0;
    padding-bottom: 5px;
    padding-left: 0;
}

.tooltip.left {
    margin-left: -3px;
    padding-top: 0;
    padding-right: 5px;
    padding-bottom: 0;
    padding-left: 5px;
}

.tooltip-inner {
    max-width: 200px;
    padding-top: 3px;
    padding-right: 8px;
    padding-bottom: 3px;
    padding-left: 8px;
    color: rgb(255, 255, 255);
    text-align: center;
    background-color: rgb(0, 0, 0);
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
}

.tooltip-arrow {
    position: absolute;
    width: 0px;
    height: 0px;
    border-top-color: transparent;
    border-right-color: transparent;
    border-bottom-color: transparent;
    border-left-color: transparent;
    border-top-style: solid;
    border-right-style: solid;
    border-bottom-style: solid;
    border-left-style: solid;
}

.tooltip.top .tooltip-arrow {
    bottom: 0;
    left: 50%;
    margin-left: -5px;
    border-top-width: 5px;
    border-right-width: 5px;
    border-bottom-width: 0;
    border-left-width: 5px;
    border-top-color: rgb(0, 0, 0);
}

.tooltip.top-left .tooltip-arrow {
    right: 5px;
}

.tooltip.top-left .tooltip-arrow,
.tooltip.top-right .tooltip-arrow {
    bottom: 0;
    margin-bottom: -5px;
    border-top-width: 5px;
    border-right-width: 5px;
    border-bottom-width: 0;
    border-left-width: 5px;
    border-top-color: rgb(0, 0, 0);
}

.tooltip.top-right .tooltip-arrow {
    left: 5px;
}

.tooltip.right .tooltip-arrow {
    top: 50%;
    left: 0;
    margin-top: -5px;
    border-top-width: 5px;
    border-right-width: 5px;
    border-bottom-width: 5px;
    border-left-width: 0;
    border-right-color: rgb(0, 0, 0);
}

.tooltip.left .tooltip-arrow {
    top: 50%;
    right: 0;
    margin-top: -5px;
    border-top-width: 5px;
    border-right-width: 0;
    border-bottom-width: 5px;
    border-left-width: 5px;
    border-left-color: rgb(0, 0, 0);
}

.tooltip.bottom .tooltip-arrow {
    top: 0;
    left: 50%;
    margin-left: -5px;
    border-top-width: 0;
    border-right-width: 5px;
    border-bottom-width: 5px;
    border-left-width: 5px;
    border-bottom-color: rgb(0, 0, 0);
}

.tooltip.bottom-left .tooltip-arrow {
    top: 0;
    right: 5px;
    margin-top: -5px;
    border-top-width: 0;
    border-right-width: 5px;
    border-bottom-width: 5px;
    border-left-width: 5px;
    border-bottom-color: rgb(0, 0, 0);
}

.tooltip.bottom-right .tooltip-arrow {
    top: 0;
    left: 5px;
    margin-top: -5px;
    border-top-width: 0;
    border-right-width: 5px;
    border-bottom-width: 5px;
    border-left-width: 5px;
    border-bottom-color: rgb(0, 0, 0);
}

.popover {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1060;
    display: none;
    max-width: 276px;
    padding-top: 1px;
    padding-right: 1px;
    padding-bottom: 1px;
    padding-left: 1px;
    font-family: Raleway, sans-serif;
    font-style: normal;
    font-weight: 400;
    letter-spacing: normal;
    line-break: auto;
    line-height: 1.6;
    text-align: start;
    text-decoration-line: none;
    text-shadow: none;
    text-transform: none;
    white-space: normal;
    word-break: normal;
    word-spacing: normal;
    word-wrap: normal;
    font-size: 14px;
    background-color: rgb(255, 255, 255);
    background-clip: padding-box;
    border-top-color: rgba(0, 0, 0, 0.2);
    border-top-style: solid;
    border-top-width: 1px;
    border-right-color: rgba(0, 0, 0, 0.2);
    border-right-style: solid;
    border-right-width: 1px;
    border-bottom-color: rgba(0, 0, 0, 0.2);
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-left-color: rgba(0, 0, 0, 0.2);
    border-left-style: solid;
    border-left-width: 1px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}

.popover.top {
    margin-top: -10px;
}

.popover.right {
    margin-left: 10px;
}

.popover.bottom {
    margin-top: 10px;
}

.popover.left {
    margin-left: -10px;
}

.popover-title {
    margin-top: 0;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 0;
    padding-top: 8px;
    padding-right: 14px;
    padding-bottom: 8px;
    padding-left: 14px;
    font-size: 14px;
    background-color: rgb(247, 247, 247);
    border-bottom-color: rgb(235, 235, 235);
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.popover-content {
    padding-top: 9px;
    padding-right: 14px;
    padding-bottom: 9px;
    padding-left: 14px;
}

.popover>.arrow,
.popover>.arrow:after {
    position: absolute;
    display: block;
    width: 0px;
    height: 0px;
    border-top-color: transparent;
    border-right-color: transparent;
    border-bottom-color: transparent;
    border-left-color: transparent;
    border-top-style: solid;
    border-right-style: solid;
    border-bottom-style: solid;
    border-left-style: solid;
}

.popover>.arrow {
    border-top-width: 11px;
    border-right-width: 11px;
    border-bottom-width: 11px;
    border-left-width: 11px;
}

.popover>.arrow:after {
    border-top-width: 10px;
    border-right-width: 10px;
    border-bottom-width: 10px;
    border-left-width: 10px;
    content: "";
}

.popover.top>.arrow {
    left: 50%;
    margin-left: -11px;
    border-bottom-width: 0;
    border-top-color: rgba(0, 0, 0, 0.25);
    bottom: -11px;
}

.popover.top>.arrow:after {
    content: " ";
    bottom: 1px;
    margin-left: -10px;
    border-bottom-width: 0;
    border-top-color: rgb(255, 255, 255);
}

.popover.right>.arrow {
    top: 50%;
    left: -11px;
    margin-top: -11px;
    border-left-width: 0;
    border-right-color: rgba(0, 0, 0, 0.25);
}

.popover.right>.arrow:after {
    content: " ";
    left: 1px;
    bottom: -10px;
    border-left-width: 0;
    border-right-color: rgb(255, 255, 255);
}

.popover.bottom>.arrow {
    left: 50%;
    margin-left: -11px;
    border-top-width: 0;
    border-bottom-color: rgba(0, 0, 0, 0.25);
    top: -11px;
}

.popover.bottom>.arrow:after {
    content: " ";
    top: 1px;
    margin-left: -10px;
    border-top-width: 0;
    border-bottom-color: rgb(255, 255, 255);
}

.popover.left>.arrow {
    top: 50%;
    right: -11px;
    margin-top: -11px;
    border-right-width: 0;
    border-left-color: rgba(0, 0, 0, 0.25);
}

.popover.left>.arrow:after {
    content: " ";
    right: 1px;
    border-right-width: 0;
    border-left-color: rgb(255, 255, 255);
    bottom: -10px;
}

.carousel,
.carousel-inner {
    position: relative;
}

.carousel-inner {
    overflow-x: hidden;
    overflow-y: hidden;
    width: 100%;
}

.carousel-inner>.item {
    display: none;
    position: relative;
}

.carousel-inner>.item>a>img,
.carousel-inner>.item>img {
    display: block;
    max-width: 100%;
    height: auto;
    line-height: 1;
}

@media NOT ALL,
NOT ALL {
    .carousel-inner>.item {
        backface-visibility: hidden;
    }

    .carousel-inner>.item.active.right,
    .carousel-inner>.item.next {
        transform: translate3d(100%, 0, 0);
        left: 0;
    }

    .carousel-inner>.item.active.left,
    .carousel-inner>.item.prev {
        transform: translate3d(-100%, 0, 0);
        left: 0;
    }

    .carousel-inner>.item.active,
    .carousel-inner>.item.next.left,
    .carousel-inner>.item.prev.right {
        transform: translateZ(0);
        left: 0;
    }
}

.carousel-inner>.active,
.carousel-inner>.next,
.carousel-inner>.prev {
    display: block;
}

.carousel-inner>.active {
    left: 0;
}

.carousel-inner>.next,
.carousel-inner>.prev {
    position: absolute;
    top: 0;
    width: 100%;
}

.carousel-inner>.next {
    left: 100%;
}

.carousel-inner>.prev {
    left: -100%;
}

.carousel-inner>.next.left,
.carousel-inner>.prev.right {
    left: 0;
}

.carousel-inner>.active.left {
    left: -100%;
}

.carousel-inner>.active.right {
    left: 100%;
}

.carousel-control {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 15%;
    opacity: 0.5;
    font-size: 20px;
    color: rgb(255, 255, 255);
    text-align: center;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
    background-color: transparent;
}

.carousel-control.left {
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.5) 0, rgba(0, 0, 0, 0.0001));
    background-repeat: repeat-x;
}

.carousel-control.right {
    left: auto;
    right: 0;
    background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.0001) 0, rgba(0, 0, 0, 0.5));
    background-repeat: repeat-x;
}

.carousel-control:focus,
.carousel-control:hover {
    outline-width: 0;
    color: rgb(255, 255, 255);
    text-decoration-line: none;
    opacity: 0.9;
}

.carousel-control .glyphicon-chevron-left,
.carousel-control .glyphicon-chevron-right,
.carousel-control .icon-next,
.carousel-control .icon-prev {
    position: absolute;
    top: 50%;
    margin-top: -10px;
    z-index: 5;
    display: inline-block;
}

.carousel-control .glyphicon-chevron-left,
.carousel-control .icon-prev {
    left: 50%;
    margin-left: -10px;
}

.carousel-control .glyphicon-chevron-right,
.carousel-control .icon-next {
    right: 50%;
    margin-right: -10px;
}

.carousel-control .icon-next,
.carousel-control .icon-prev {
    width: 20px;
    height: 20px;
    line-height: 1;
    font-family: serif;
}

.carousel-control .icon-prev:before {
    content: "‹";
}

.carousel-control .icon-next:before {
    content: "›";
}

.carousel-indicators {
    position: absolute;
    bottom: 10px;
    left: 50%;
    z-index: 15;
    width: 60%;
    margin-left: -30%;
    padding-left: 0;
    list-style-type: none;
    list-style-image: none;
    text-align: center;
}

.carousel-indicators li {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin-top: 1px;
    margin-right: 1px;
    margin-bottom: 1px;
    margin-left: 1px;
    text-indent: -999px;
    border-top-color: rgb(255, 255, 255);
    border-top-style: solid;
    border-top-width: 1px;
    border-right-color: rgb(255, 255, 255);
    border-right-style: solid;
    border-right-width: 1px;
    border-bottom-color: rgb(255, 255, 255);
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-left-color: rgb(255, 255, 255);
    border-left-style: solid;
    border-left-width: 1px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    cursor: pointer;
    background-color: transparent;
}

.carousel-indicators .active {
    margin-top: 0;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 0;
    width: 12px;
    height: 12px;
    background-color: rgb(255, 255, 255);
}

.carousel-caption {
    position: absolute;
    left: 15%;
    right: 15%;
    bottom: 20px;
    z-index: 10;
    padding-top: 20px;
    padding-bottom: 20px;
    color: rgb(255, 255, 255);
    text-align: center;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
}

.carousel-caption .btn {
    text-shadow: none;
}

@media screen AND (min-width: 768px) {

    .carousel-control .glyphicon-chevron-left,
    .carousel-control .glyphicon-chevron-right,
    .carousel-control .icon-next,
    .carousel-control .icon-prev {
        width: 30px;
        height: 30px;
        margin-top: -10px;
        font-size: 30px;
    }

    .carousel-control .glyphicon-chevron-left,
    .carousel-control .icon-prev {
        margin-left: -10px;
    }

    .carousel-control .glyphicon-chevron-right,
    .carousel-control .icon-next {
        margin-right: -10px;
    }

    .carousel-caption {
        left: 20%;
        right: 20%;
        padding-bottom: 30px;
    }

    .carousel-indicators {
        bottom: 20px;
    }
}

.clearfix:after,
.clearfix:before {
    content: " ";
    display: table;
}

.clearfix:after {
    clear: both;
}

.center-block {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.pull-right {
    float: right !important;
}

.pull-left {
    float: left !important;
}

.hide {
    display: none !important;
}

.show {
    display: block !important;
}

.invisible {
    visibility: hidden;
}

.text-hide {
    font-family: a;
    line-height: 0;
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border-top-color: initial;
    border-top-style: initial;
    border-top-width: 0;
    border-right-color: initial;
    border-right-style: initial;
    border-right-width: 0;
    border-bottom-color: initial;
    border-bottom-style: initial;
    border-bottom-width: 0;
    border-left-color: initial;
    border-left-style: initial;
    border-left-width: 0;
}

.hidden {
    display: none !important;
}

.affix {
    position: fixed;
}

.visible-lg,
.visible-lg-block,
.visible-lg-inline,
.visible-lg-inline-block,
.visible-md,
.visible-md-block,
.visible-md-inline,
.visible-md-inline-block,
.visible-sm,
.visible-sm-block,
.visible-sm-inline,
.visible-sm-inline-block,
.visible-xs,
.visible-xs-block,
.visible-xs-inline,
.visible-xs-inline-block {
    display: none !important;
}

@media (max-width: 767px) {
    .visible-xs {
        display: block !important;
    }

    table.visible-xs {
        display: table !important;
    }

    tr.visible-xs {
        display: table-row !important;
    }

    td.visible-xs,
    th.visible-xs {
        display: table-cell !important;
    }
}

@media (max-width: 767px) {
    .visible-xs-block {
        display: block !important;
    }
}

@media (max-width: 767px) {
    .visible-xs-inline {
        display: inline !important;
    }
}

@media (max-width: 767px) {
    .visible-xs-inline-block {
        display: inline-block !important;
    }
}

@media (min-width: 768px) AND (max-width: 991px) {
    .visible-sm {
        display: block !important;
    }

    table.visible-sm {
        display: table !important;
    }

    tr.visible-sm {
        display: table-row !important;
    }

    td.visible-sm,
    th.visible-sm {
        display: table-cell !important;
    }
}

@media (min-width: 768px) AND (max-width: 991px) {
    .visible-sm-block {
        display: block !important;
    }
}

@media (min-width: 768px) AND (max-width: 991px) {
    .visible-sm-inline {
        display: inline !important;
    }
}

@media (min-width: 768px) AND (max-width: 991px) {
    .visible-sm-inline-block {
        display: inline-block !important;
    }
}

@media (min-width: 992px) AND (max-width: 1199px) {
    .visible-md {
        display: block !important;
    }

    table.visible-md {
        display: table !important;
    }

    tr.visible-md {
        display: table-row !important;
    }

    td.visible-md,
    th.visible-md {
        display: table-cell !important;
    }
}

@media (min-width: 992px) AND (max-width: 1199px) {
    .visible-md-block {
        display: block !important;
    }
}

@media (min-width: 992px) AND (max-width: 1199px) {
    .visible-md-inline {
        display: inline !important;
    }
}

@media (min-width: 992px) AND (max-width: 1199px) {
    .visible-md-inline-block {
        display: inline-block !important;
    }
}

@media (min-width: 1200px) {
    .visible-lg {
        display: block !important;
    }

    table.visible-lg {
        display: table !important;
    }

    tr.visible-lg {
        display: table-row !important;
    }

    td.visible-lg,
    th.visible-lg {
        display: table-cell !important;
    }
}

@media (min-width: 1200px) {
    .visible-lg-block {
        display: block !important;
    }
}

@media (min-width: 1200px) {
    .visible-lg-inline {
        display: inline !important;
    }
}

@media (min-width: 1200px) {
    .visible-lg-inline-block {
        display: inline-block !important;
    }
}

@media (max-width: 767px) {
    .hidden-xs {
        display: none !important;
    }
}

@media (min-width: 768px) AND (max-width: 991px) {
    .hidden-sm {
        display: none !important;
    }
}

@media (min-width: 992px) AND (max-width: 1199px) {
    .hidden-md {
        display: none !important;
    }
}

@media (min-width: 1200px) {
    .hidden-lg {
        display: none !important;
    }
}

.visible-print {
    display: none !important;
}

@media print {
    .visible-print {
        display: block !important;
    }

    table.visible-print {
        display: table !important;
    }

    tr.visible-print {
        display: table-row !important;
    }

    td.visible-print,
    th.visible-print {
        display: table-cell !important;
    }
}

.visible-print-block {
    display: none !important;
}

@media print {
    .visible-print-block {
        display: block !important;
    }
}

.visible-print-inline {
    display: none !important;
}

@media print {
    .visible-print-inline {
        display: inline !important;
    }
}

.visible-print-inline-block {
    display: none !important;
}

@media print {
    .visible-print-inline-block {
        display: inline-block !important;
    }
}

@media print {
    .hidden-print {
        display: none !important;
    }
}


.bs-modal {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 0;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 0;
    padding-top: 30px;
    padding-right: 10px;
    padding-bottom: 30px;
    padding-left: 10px;
    /* overflow-y: scroll; */
}

.bs-modal:scrollbar {
    width: 0px;
    background-color: transparent;
}

.bs-modal p {
    margin-top: 0;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 0;
}

.bs-modal button {
    padding-top: 0;
    padding-right: 0;
    padding-bottom: 0;
    padding-left: 0;
}



.bsm-cont {
    width: 100%;
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    margin-top: auto;
    margin-right: 0;
    margin-bottom: auto;
    margin-left: 0;    
}

.bsm-inner {
    width: 100%;
    float: left;
    position: relative;
    padding-top: 0;
    padding-right: 0;
    padding-bottom: 0;
    padding-left: 130px;
    overflow-x: hidden;
    overflow-y: hidden;
    border-top-color: rgb(101, 101, 101);
    border-top-style: solid;
    border-top-width: 1px;
    border-right-color: rgb(101, 101, 101);
    border-right-style: solid;
    border-right-width: 1px;
    border-bottom-color: rgb(101, 101, 101);
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-left-color: rgb(101, 101, 101);
    border-left-style: solid;
    border-left-width: 1px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
}

.modal-body {
    width: 100%;
    float: left;
    text-align: center;
    position: relative;
    background-image: url("../../images/modal/modal-bg.png");
    background-repeat: no-repeat;
    background-position: top 2px center;
    background-color: rgb(18, 18, 18);
    border-left-color: initial;
    border-left-style: none;
    border-left-width: initial;
    padding-top: 0;
    padding-right: 0;
    padding-bottom: 0;
    padding-left: 0;
    z-index: 1;
}

.modal-body:before,
.modal-body:after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-repeat: no-repeat;
    pointer-events: none;
}

.modal-body:before {
    background-image: url("../../images/modal/modal-bg-left.png");
    background-position: top left;
}

.modal-body:after {
    background-image: url("../../images/modal/modal-bg-right.png");
    background-position: bottom right;
}

.modal-body .mdl-head {
    width: 100%;
    float: left;
    position: relative;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-top: 0;
    padding-right: 10px;
    padding-bottom: 0;
    padding-left: 10px;
    margin-top: 40px;
}

.modal-body .mdl-head .mdl-title {
    width: 100%;
    float: left;
    text-align: center;
}

.modal-body .mdl-head .mdl-title div {
    width: auto;
    display: none;
    vertical-align: top;
}

@keyframes mdlTitleAnim {}

.modal-body .mdl-head .mdl-title .active {
    display: block;
}

.modal-body .mdl-head .mdl-title div p {
    font-family: SCDream6;
    text-shadow: 0 3px 2px rgb(0, 0, 0);
}

.modal-body .mdl-head .mdl-title div .en {
    color: rgb(255, 255, 255);
    font-size: 22px;
}

.modal-body .mdl-head .mdl-title div .kr {
    color: rgb(255, 187, 127);
    font-size: 35px;
    margin-top: -5px;
}


.modal-body .tab-mdl {
    width: 100%;
    float: left;
    padding-top: 40px;
    padding-right: 40px;
    padding-bottom: 40px;
    padding-left: 40px;
    position: relative;
    z-index: 1;
    display: none;
}

.subpgModal .modal-body .tab-mdl {
    min-height: 680px;
}

@keyframes modalTabAnim {}

.modal-body .tab-mdl:before,
.modal-body .tab-mdl:after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
}

.modal-body .tab-mdl.deposit {
    padding-bottom: 90px;
}

.modal-body .tab-mdl.active {
    display: block;
}




.loginModal .form-container .form-group {
    padding-left: 0;
}

.loginModal .modal-body .tab-mdl {
    padding-top: 40px;
    padding-right: 75px;
    padding-bottom: 40px;
    padding-left: 75px;
}

.joinModal .bsm-cont {
    width: 800px;
}

.joinModal .bsm-inner {
    padding-left: 0;
}

.joinModal .modal-body .tab-mdl {
    padding-top: 40px;
    padding-right: 75px;
    padding-bottom: 40px;
    padding-left: 75px;
}


.mypageModal .form-container .form-group {
    padding-left: 150px;
}

.mypageModal .form-container .form-group .labels {
    width: 150px;
}




.mypageModal .form-container .form-group {
    padding-left: 0;
}



.over-style {
    position: relative;
    overflow-x: hidden;
    overflow-y: hidden;
    border-top-left-radius: 8px;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 2px;
    box-shadow: 0 0 0 rgb(0, 0, 0);
    transition-property: initial;
    transition-duration: 0.3s;
    transition-timing-function: initial;
    transition-delay: initial;
    z-index: 1;
}

.over-style:hover {
    box-shadow: 0 0 3px rgb(0, 0, 0);
}

.over-style:before,
.over-style:after {
    content: "";
    position: absolute;
    background-color: rgba(0, 0, 0, 0.5);
    transition-property: all;
    transition-duration: 0.5s;
    transition-timing-function: ease;
    transition-delay: initial;
    box-sizing: border-box;
    width: 50%;
    height: 50%;
    opacity: 0;
    z-index: -1;
}

.over-style:before {
    top: 0;
    left: 0;
}

.over-style:after {
    bottom: 0;
    right: 0;
}

.over-style:hover:before {
    left: 50%;
    opacity: 0.9;
}

.over-style:hover:after {
    right: 50%;
    opacity: 0.9;
}

.over-style .os-cont {
    width: 100%;
    height: 100%;
    float: left;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    border-top-color: initial;
    border-top-style: none;
    border-top-width: initial;
    border-right-color: initial;
    border-right-style: none;
    border-right-width: initial;
    border-bottom-color: initial;
    border-bottom-style: none;
    border-bottom-width: initial;
    border-left-color: initial;
    border-left-style: none;
    border-left-width: initial;
    z-index: 1;
}

.over-style .os-cont:before,
.over-style .os-cont:after {
    content: "";
    position: absolute;
    background-color: rgba(0, 0, 0, 0.5);
    transition-property: all;
    transition-duration: 0.5s;
    transition-timing-function: ease;
    transition-delay: initial;
    box-sizing: border-box;
    width: 50%;
    height: 50%;
    opacity: 0;
    z-index: -1;
}

.over-style .os-cont:before {
    top: 0;
    right: 0;
}

.over-style .os-cont:after {
    bottom: 0;
    left: 0;
}

.over-style:hover .os-cont:before {
    top: 50%;
    opacity: 0.9;
}

.over-style:hover .os-cont:after {
    bottom: 50%;
    opacity: 0.9;
}



.modal-btn-foot {
    width: 100%;
    height: auto;
    float: left;
    margin-top: 30px;
    margin-right: 0;
    margin-bottom: 20px;
    margin-left: 0;
    text-align: center;
}

.modal-btn-foot .btn-cont {
    width: auto;
    display: inline-block;
}

.modal-btn-foot .btn-cont button {
    width: 150px;
    height: 60px;
    float: left;
    border-top-color: rgb(220, 191, 157);
    border-top-style: solid;
    border-top-width: 1px;
    border-right-color: rgb(182, 154, 118);
    border-right-style: solid;
    border-right-width: 1px;
    border-bottom-color: rgb(127, 100, 62);
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-left-color: rgb(182, 154, 118);
    border-left-style: solid;
    border-left-width: 1px;
    background-color: rgb(223, 128, 31);
    margin-top: 0;
    margin-right: 8px;
    margin-bottom: 0;
    margin-left: 8px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    overflow-x: hidden;
    overflow-y: hidden;
    position: relative;
    z-index: 1;
    color: rgb(78, 40, 0);
    font-size: 18px;
    font-family: SCDream6;
    transition-property: initial;
    transition-duration: 0.3s;
    transition-timing-function: initial;
    transition-delay: initial;
    box-shadow: 0 3px 3px rgb(0, 0, 0);
}

.modal-btn-foot .btn-cont button span {
    font-family: SCDream6;
    z-index: 1;
}

.modal-btn-foot .btn-cont button.red {
    background-color: rgb(189, 23, 19);
    color: rgb(255, 255, 255);
    border-top-color: rgb(254, 131, 131);
    border-left-color: rgb(219, 79, 79);
    border-right-color: rgb(219, 79, 79);
    border-bottom-color: rgb(166, 2, 2);
}

.modal-btn-foot .btn-cont button.green {
    background-color: rgb(59, 179, 59);
    color: rgb(7, 56, 7);
    border-top-color: rgb(141, 255, 141);
    border-left-color: rgb(105, 234, 105);
    border-right-color: rgb(105, 234, 105);
    border-bottom-color: rgb(17, 97, 17);
}

.modal-btn-foot .btn-cont button i {
    margin-right: 3px;
}

.modal-btn-foot .btn-cont button:hover {
    color: rgb(255, 255, 255);
}

.loginModal .bsm-cont {
    width: 800px;
}

.bsm-inner {
    padding-left: 0;
}


/* 
.modal-btn-foot .btn-cont {
    width: 100%;
}

.modal-btn-foot .btn-cont button {
    width: 48%;
    height: 30px;
    margin-top: 0;
    margin-right: 1%;
    margin-bottom: 0;
    margin-left: 1%;
    font-size: 14px;
}

.joinModal .modal-btn-foot .btn-cont button {
    width: 100%;
    margin-top: 0;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 0;
}

.modal-btn-foot.three .btn-cont button {
    width: 32%;
}

.modal-btn-foot.three .btn-cont button:first-child {
    margin-left: 0;
}

.modal-btn-foot.three .btn-cont button:last-child {
    margin-right: 0;
} */
