.alertify .ajs-dialog {
    background-color: rgb(255, 255, 255);
    box-shadow: 0 15px 20px 0 rgba(0, 0, 0, 0.25);
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
    border-bottom-left-radius: 2px;
}

.alertify .ajs-header {
    color: rgb(0, 0, 0);
    font-weight: 700;
    background-color: rgb(250, 250, 250);
    background-image: initial;
    border-bottom-color: rgb(238, 238, 238);
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.alertify .ajs-body {
    color: rgb(0, 0, 0);
}

.alertify .ajs-body .ajs-content .ajs-input {
    display: block;
    width: 100%;
    padding-top: 8px;
    padding-right: 8px;
    padding-bottom: 8px;
    padding-left: 8px;
    margin-top: 4px;
    margin-right: 4px;
    margin-bottom: 4px;
    margin-left: 4px;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
    border-bottom-left-radius: 2px;
    border-top-color: rgb(204, 204, 204);
    border-top-style: solid;
    border-top-width: 1px;
    border-right-color: rgb(204, 204, 204);
    border-right-style: solid;
    border-right-width: 1px;
    border-bottom-color: rgb(204, 204, 204);
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-left-color: rgb(204, 204, 204);
    border-left-style: solid;
    border-left-width: 1px;
}

.alertify .ajs-body .ajs-content p {
    margin-top: 0;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 0;
}

.alertify .ajs-footer {
    background-color: rgb(251, 251, 251);
    background-image: initial;
    border-top-color: rgb(238, 238, 238);
    border-top-style: solid;
    border-top-width: 1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 2px;
    border-bottom-left-radius: 2px;
}

.alertify .ajs-footer .ajs-buttons .ajs-button {
    background-color: transparent;
    color: rgb(0, 0, 0);
    border-top-color: initial;
    border-top-style: initial;
    border-top-width: 0;
    border-right-color: initial;
    border-right-style: initial;
    border-right-width: 0;
    border-bottom-color: initial;
    border-bottom-style: initial;
    border-bottom-width: 0;
    border-left-color: initial;
    border-left-style: initial;
    border-left-width: 0;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
}

.alertify .ajs-footer .ajs-buttons .ajs-button.ajs-ok {
    color: rgb(53, 147, 210);
}

.alertify-notifier .ajs-message {
    background-color: rgba(255, 255, 255, 0.95);
    background-image: initial;
    color: rgb(0, 0, 0);
    text-align: center;
    border-top-color: rgb(221, 221, 221);
    border-top-style: solid;
    border-top-width: 1px;
    border-right-color: rgb(221, 221, 221);
    border-right-style: solid;
    border-right-width: 1px;
    border-bottom-color: rgb(221, 221, 221);
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-left-color: rgb(221, 221, 221);
    border-left-style: solid;
    border-left-width: 1px;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
    border-bottom-left-radius: 2px;
}

.alertify-notifier .ajs-message.ajs-success {
    color: rgb(255, 255, 255);
    background-color: rgba(91, 189, 114, 0.95);
    background-image: initial;
    text-shadow: -1px -1px 0 rgba(0, 0, 0, 0.5);
}

.alertify-notifier .ajs-message.ajs-error {
    color: rgb(255, 255, 255);
    background-color: rgba(217, 92, 92, 0.95);
    background-image: initial;
    text-shadow: -1px -1px 0 rgba(0, 0, 0, 0.5);
}

.alertify-notifier .ajs-message.ajs-warning {
    background-color: rgba(252, 248, 215, 0.95);
    background-image: initial;
    border-top-color: rgb(153, 153, 153);
    border-right-color: rgb(153, 153, 153);
    border-bottom-color: rgb(153, 153, 153);
    border-left-color: rgb(153, 153, 153);
}