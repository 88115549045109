.navigation-page {
    z-index: 3;
    width: 100%;
    position: absolute;
}

.navigation-page,
.navigation-page .header-top .list-inline.pull-right>li>a,
.navigation-page .nav-container .list-inline.nav-list>li>a {
    color: rgb(153, 153, 153);
    font-weight: bold;
}

.navigation-page .header-top {
    background-color: rgb(0, 0, 0);
    background-image: initial;
    font-size: 1em;
    min-width: 1260px;
    position: relative;
}

.navigation-page .header-top ul.list-inline.pull-right {
    position: relative;
    top: 6px;
}

.navigation-page .header-top .list-inline.pull-right>li {
    padding-top: 0;
    padding-right: 10px;
    padding-bottom: 0;
    padding-left: 10px;
    position: relative;
}

.navigation-page .header-top .list-inline.pull-right>li>a:hover {
    color: rgb(193, 173, 139);
}

.navigation-page .header-top .list-inline.pull-right>li:first-child:after,
.navigation-page .header-top .list-inline.pull-right>li:nth-child(+2):after {
    width: 1px;
    height: 50%;
    content: "";
    position: absolute;
    right: 0;
    top: 50%;
    background-color: rgb(54, 54, 54);
    background-image: initial;
    transform: translateY(-50%);
}

.navigation-page .logo {
    display: inline-block;
    width: 148px;
    margin-right: 20px;
}

.navigation-page .nav-container {
    width: 100%;
    height: 69px;
    min-width: 1260px;
    background-color: rgb(0, 0, 0);
    background-image: initial;
    font-size: 1em;
    font-weight: bold;
}

.navigation-page .nav-container nav,
.navigation-page .nav-container .form {
    display: inline-block;
    font-size: 1em;
    position: relative;
}

.navigation-page .nav-container .form {
    left: 145px;
    top: 2px;
}

.navigation-page .nav-container form.loggedIn {
    left: 0;
    top: 20px;
}

.navigation-page .nav-container .list-inline.nav-list>li {
    padding-top: 0;
    padding-right: 20px;
    padding-bottom: 0;
    padding-left: 0;
    position: relative;
    font-size: 1em;
}

.navigation-page .nav-container .list-inline.nav-list>li>a:hover {
    color: rgb(193, 173, 139);
    border-bottom-color: rgb(193, 173, 139);
    border-bottom-style: solid;
    border-bottom-width: 2px;
    padding-bottom: 14px;
}

.navigation-page .nav-container .form-control {
    display: inline-block;
    width: 115px;
    height: 26px;
    background-color: rgb(0, 0, 0);
    background-image: initial;
    border-top-color: rgb(88, 88, 88);
    border-top-style: solid;
    border-top-width: 1px;
    border-right-color: rgb(88, 88, 88);
    border-right-style: solid;
    border-right-width: 1px;
    border-bottom-color: rgb(88, 88, 88);
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-left-color: rgb(88, 88, 88);
    border-left-style: solid;
    border-left-width: 1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    margin-right: 10px;
    font-size: 12px;
    padding-top: 0;
    padding-right: 10px;
    padding-bottom: 0;
    padding-left: 10px;
    color: rgb(153, 153, 153);
}

.navigation-page .nav-container .btn-header,
.navigation-page .nav-container .btn-signup {
    width: 105px;
    height: 26px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    vertical-align: top;
    padding-top: 0;
    padding-right: 0;
    padding-bottom: 0;
    padding-left: 0;
    font-size: 12px;
    margin-right: 10px;
    font-weight: bold;
    color: rgb(153, 153, 153);
    line-height: 2;
    transition-property: all;
    transition-duration: 0.5s;
    transition-timing-function: initial;
    transition-delay: initial;
}

.navigation-page .nav-container .btn-header,
.navigation-page .nav-container .btn-signup {
    background-color: initial;
    background-image: url("../images/btn-frame-login.jpg");
    background-position: center 100%;
    background-repeat: no-repeat;
}

.navigation-page .nav-container .btn-header:hover,
.navigation-page .nav-container .btn-signup:hover,
.navigation-page .nav-container .btn-header:focus,
.navigation-page .nav-container .btn-signup:focus {
    color: rgb(193, 173, 139);
}

.navigation-page .balance-container {
    display: inline-block;
    height: 26px;
    min-width: 135px;
    border-top-color: rgb(88, 88, 88);
    border-top-style: solid;
    border-top-width: 1px;
    border-right-color: rgb(88, 88, 88);
    border-right-style: solid;
    border-right-width: 1px;
    border-bottom-color: rgb(88, 88, 88);
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-left-color: rgb(88, 88, 88);
    border-left-style: solid;
    border-left-width: 1px;
    margin-right: 7px;
    vertical-align: top;
}

.login-container.wallet-part {
    width: 800px;
    position: relative;
    color: rgb(102, 102, 102);
}

.navigation-page .reload-balance {
    display: inline-block;
    width: 24px;
    height: 24px;
    cursor: pointer;
    border-right-color: rgb(88, 88, 88);
    border-right-style: solid;
    border-right-width: 1px;
    float: left;
    background-color: initial;
    background-image: url("https://bm719.com/images/sample6/refresh-icon.svg?ver=1.01");
    background-position: left 4px center;
    background-repeat: no-repeat;
}

.navigation-page .guest-balance-container {
    height: 24px;
    min-width: 108px;
    text-align: left;
    float: left;
    font-size: 13px;
    padding-top: 0;
    padding-right: 5px;
    padding-bottom: 0;
    padding-left: 5px;
    cursor: pointer;
    line-height: 24px;
}

.dropdown-arrow-container {
    width: 19px;
    height: 24px;
    float: left;
    font-size: 20px;
    color: rgb(204, 204, 204);
    text-align: center;
}

.dropdown-arrow-container i {
    vertical-align: text-top;
}

.dropdown-arrow-container i.fa-sort-up {}

.navigation-page .balance-dropdown-container {
    display: none;
    z-index: 11;
    width: 615px;
    background-color: rgb(29, 29, 29);
    background-image: initial;
    position: absolute;
    border-top-color: rgb(88, 87, 85);
    border-top-style: solid;
    border-top-width: 1px;
    border-right-color: rgb(88, 87, 85);
    border-right-style: solid;
    border-right-width: 1px;
    border-bottom-color: rgb(88, 87, 85);
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-left-color: rgb(88, 87, 85);
    border-left-style: solid;
    border-left-width: 1px;
    top: 50px;
    right: 10px;
    color: rgb(153, 153, 153);
    text-transform: uppercase;
    font-size: 1em;
}

.navigation-page .balance-dropdown-container .col-xs-6 {
    text-align: left;
    padding-top: 0;
    padding-right: 0;
    padding-bottom: 0;
    padding-left: 0;
}

.navigation-page .balance-dropdown-container .col-xs-6:first-of-type .balance__content {
    border-right-color: rgb(88, 87, 85);
    border-right-style: solid;
    border-right-width: 1px;
    border-bottom-color: rgb(88, 87, 85);
    border-bottom-style: solid;
    border-bottom-width: 1px;
}

.navigation-page .balance-dropdown-container .col-xs-6:first-of-type .balance__content:last-of-type {
    margin-bottom: 10px;
}

.navigation-page .balance__content:nth-child(2n) {
    background-color: inherit;
}

.navigation-page .guest_name {
    font-size: 13px;
    color: rgb(193, 173, 139);
    position: relative;
}

.navigation-page .guest-dm {
    width: 38px;
    height: 18px;
    background-color: rgb(177, 61, 59);
    background-image: initial;
    text-align: center;
    vertical-align: top;
    line-height: 18px;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
    margin-left: 5px;
    color: rgb(255, 255, 255);
    font-size: 13px;
}

.navigation-page .guest-dm:hover {
    background-color: rgb(193, 77, 74);
    background-image: initial;
}

.navigation-page .gsp-name {
    width: 70%;
    padding-top: 0;
    padding-right: 0;
    padding-bottom: 0;
    padding-left: 0;
}

.wallet-btn-yellow {
    width: 220px;
    height: 26px;
    background-color: rgb(29, 29, 29);
    background-image: initial;
    border-top-color: rgb(193, 173, 139);
    border-top-style: solid;
    border-top-width: 1px;
    border-right-color: rgb(193, 173, 139);
    border-right-style: solid;
    border-right-width: 1px;
    border-bottom-color: rgb(193, 173, 139);
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-left-color: rgb(193, 173, 139);
    border-left-style: solid;
    border-left-width: 1px;
    color: rgb(193, 173, 139);
    padding-top: 4px;
    padding-right: 40px;
    padding-bottom: 4px;
    padding-left: 40px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    font-weight: bold;
}

.wallet-btn-yellow:hover,
.wallet-btn-yellow:focus {
    background-color: rgb(193, 173, 139);
    background-image: initial;
    color: rgb(17, 17, 17);
}

.navigation-page .balance__content {
    border-bottom-color: rgb(88, 87, 85);
    border-bottom-style: solid;
    border-bottom-width: 1px;
}

.header-top .nav-container {
    display: flex;
    align-items: center;
}

.navigation-page .header-top .nav-container form.loggedIn {
    top: 0;
    left: 0;
}

.navigation-page .header-top .nav-container form {
    left: 61.9%;
    top: 0;
}

.header-bottom {
    height: 66px;
    background-color: rgb(29, 29, 29);
    background-image: initial;
    min-width: 1260px;
    font-size: 1.2em;
}

.header-bottom .links>ul {
    display: flex;
    width: 450px;
    height: 66px;
    justify-content: space-around;
    align-items: center;
    text-align: center;
    color: rgb(153, 153, 153);
}

.header-bottom .links>ul>li {
    z-index: 10;
    cursor: pointer;
}

.header-bottom .links>ul>li:hover {
    color: rgb(193, 173, 139);
}

.header-bottom .main-container {
    display: flex;
    justify-content: space-between;
}

.header-top .logo-container {
    z-index: 10;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0%);
}

.header-bottom p i {
    font-size: 23px;
}

.header-top .logo-container .content {}

.header-top .logo-container .content img {}

.navigation-page .balance__content:last-of-type {}