.mob {
    display: none !important;
}

html {
    font-size: 14px;
}

body.swal2-toast-shown .swal2-container.swal2-center {
    width: 100%;
}

body.swal2-toast-shown .swal2-container.swal2-center>.swal2-popup {}

.form-control:focus {
    border-top-color: rgb(102, 175, 233);
    border-right-color: rgb(102, 175, 233);
    border-bottom-color: rgb(102, 175, 233);
    border-left-color: rgb(102, 175, 233);
    outline-width: 0;
    box-shadow: inset 0 1px 1px rgb(0, 0, 0), 0 0 0px 2px rgb(102, 175, 233);
}

#menu-popup.ngdialog {
    display: none;
}

@media screen AND (max-width: 990px) {
    #menu-popup.ngdialog {
        display: initial;
    }

    html {}

    body {
        font-size: 1em;
    }

    .btn {
        font-size: 1em;
    }

    .pc {
        display: none !important;
    }

    .mob {
        display: inherit !important;
    }

    .wrapper {
        min-width: 300px;
    }

    .main-container {
        width: 100%;
    }

    .ngdialog-overlay {
        background-color: rgb(0, 0, 0);
        background-image: initial;
    }

    .notice_popup {
        width: 100%;
        margin-left: 0;
    }

    .dm-badge {
        position: absolute;
        top: 0px;
        left: 50%;
        background-color: rgb(177, 61, 59);
    }

    .footer {
        width: 100%;
        position: fixed;
        left:0;
        bottom: 0;
    }

    .footer-page {
        padding-top: 30px;
        padding-right: 30px;
        padding-bottom: 30px;
        padding-left: 30px;
        margin-bottom: 60px;
    }

    .footer .btn {
        height: 38px;
    }

    .footer-btn.btn-primary {
        border-top-color: initial;
        border-top-style: none;
        border-top-width: initial;
        border-right-color: initial;
        border-right-style: none;
        border-right-width: initial;
        border-bottom-color: initial;
        border-bottom-style: none;
        border-bottom-width: initial;
        border-left-color: initial;
        border-left-style: none;
        border-left-width: initial;
        background-color: rgb(193, 173, 139);
        background-image: initial;
        color: rgb(0, 0, 0);
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
    }

    .btn-top-right,
    .btn-bottom-right {
        border-right-color: initial;
        border-right-style: initial;
        border-right-width: 0;
    }

    .slick-dotted.slick-slider {
        margin-bottom: 10px;
    }

    .slot_list>ul {}

    .deposit label,
    .withdraw label {
        line-height: inherit;
        text-align: left;
    }

    .navigation-page .header-top {
        min-width: 100px;
    }

    .banner-slider img.banner-outside {
        height: 250px;
        margin-top: 0;
        margin-right: 0;
        margin-bottom: 0;
        margin-left: 0;
    }

    .banner-slider .main-promo-text {
        position: absolute;
        top: auto;
        bottom: 0px;
        right: 50%;
        transform: translate(50%, 0%);
    }

    .main-page .content .game-buttons,
    .main-page .content .game-buttons.no-mini {
        width: 100%;
        height: 9em;
        font-size: 1.1em;
        padding-top: 7.5em;
    }

    .main-page .content .game-buttons .title-container {
        width: 100%;
        padding-top: 5px;
        padding-right: 0px;
        padding-bottom: 5px;
        padding-left: 0px;
    }

    .main-page .content .game-buttons.others .currency-sign img {
        width: 80px;
    }

    .main-page .content .game-buttons.slot,
    .main-page .content .game-buttons.mini,
    .main-page .content .game-buttons.live {
        background-size: 100% 100%;
        text-align: center;
    }

    .main-page .content .game-buttons.slot:hover,
    .main-page .content .game-buttons.mini:hover,
    .main-page .content .game-buttons.live:hover {
        background-size: 120% 120%;
        padding-top: 4.45em;
        padding-right: 0;
        padding-bottom: 4.45em;
        padding-left: 0;
        font-size: 1.1em;
    }

    .main-page .container-jackpot {
        width: 100%;
        background-color: initial;
        background-image: url("https://bm719.com/images/images/main/bg-jackpot-mobile.jpg?ver=1.02");
        background-repeat: no-repeat;
        background-size: 100% auto;
        padding-top: 18px;
        padding-right: 18px;
        padding-bottom: 18px;
        padding-left: 18px;
        position: relative;
    }

    .main-page .jackpot-odometer {
        width: 520px;
        height: 33px;
        bottom: 0;
        position: absolute;
        right: 0;
    }

    .main-page .odometer-container {
        width: 540px;
        height: 33px;
        bottom: 15px;
    }

    .ngdialog.ngdialog-theme-default {
        padding-top: 0px;
        padding-right: 0px;
        padding-bottom: 0px;
        padding-left: 0px;
    }

    .ngdialog-games-page .game-button-container.live {
        display: flex;
        padding-top: 20px;
        padding-right: 20px;
        padding-bottom: 20px;
        padding-left: 20px;
        flex-wrap: wrap;
    }

    .ngdialog-games-page .game-button-container.live .game-buttons {
        width: 50%;
    }

    .ngdialog.ngdialog-theme-default.ngdialog-login .ngdialog-content {
        width: 100%;
    }

    .ngdialog-games-page .game-button-container.live .game-buttons .tit {
        display: flex;
        height: 23%;
        position: absolute;
        bottom: 0;
        justify-content: center;
        align-items: center;
        padding-top: 0.5em;
        padding-right: 0.5em;
        padding-bottom: 0.5em;
        padding-left: 0.5em;
        margin-top: 0;
        margin-right: 1em;
        margin-bottom: 0;
        margin-left: 1em;
    }

    .ngdialog-games-page .game-button-container.slot {}

    .ngdialog-login-page input[type="text"] {
        height: 50px;
        background-color: initial;
        background-image: url("https://bm719.com/images/images/common/user-icon.png?ver=1.01");
        background-position: top 16px left 11px;
        background-repeat: no-repeat;
        margin-top: 30px;
    }

    .ngdialog-login-page input[type="password"] {
        height: 50px;
        background-color: initial;
        background-image: url("https://bm719.com/images/images/common/password-icon.png?ver=1.01");
        background-position: top 16px left 11px;
        background-repeat: no-repeat;
        margin-top: 30px;
    }

    .ngdialog-login-page .btn-yellow {
        height: 70px;
        margin-top: 30px;
    }

    .customercenter-container {
        background-color: black;
        background-image: initial;
        padding-top: 10px;
        padding-right: 0;
        padding-bottom: 10px;
        padding-left: 0;
    }

    .customercenter-container .list-inline {
        margin-top: 0;
        margin-right: auto;
        margin-bottom: 0;
        margin-left: auto;
        font-size: 0;
    }

    .customercenter-container ul li {
        width: 25%;
        height: 45px;
        position: relative;
        text-align: center;
        color: rgb(255, 255, 255);
        padding-right: 10px;
        font-size: 14px;
        letter-spacing: -0.5px;
        word-break: keep-all;
    }

    .customercenter-container li img {
        width: 30px;
    }
  
    .customer-page .customer-content {
        flex-direction: column;
        flex-wrap: initial;
    }

    .customer-page .customer-buttons {
        height: initial;
        padding-top: 1em;
        padding-right: 1.5em;
        padding-bottom: 1em;
        padding-left: 1.5em;
        white-space: nowrap;
    }

    .customer-page .text-con {
        margin-left: 0.75em;
    }

    .customer-page .goldTxt {
        font-size: 1.5em;
    }

    .customer-page {
        font-size: 0.8em;
    }

    .wallet-buttons-container {
        width: 100%;
        height: 60px;
        background-color: rgb(17, 17, 17);
        background-image: initial;
        padding-top: 11px;
        padding-right: 18px;
        padding-bottom: 11px;
        padding-left: 18px;
        text-align: center;
    }

    .wallet-buttons-container .btn-wallet.withdraw {
        background-color: rgb(193, 173, 139);
        background-image: initial;
        border-top-color: rgb(193, 173, 139);
        border-top-style: solid;
        border-top-width: 1px;
        border-right-color: rgb(193, 173, 139);
        border-right-style: solid;
        border-right-width: 1px;
        border-bottom-color: rgb(193, 173, 139);
        border-bottom-style: solid;
        border-bottom-width: 1px;
        border-left-color: rgb(193, 173, 139);
        border-left-style: solid;
        border-left-width: 1px;
        color: rgb(17, 17, 17);
        margin-right: 0;
    }

    .wallet-buttons-container .btn-wallet.deposit {
        background-color: transparent;
        background-image: initial;
        border-top-color: rgb(153, 153, 153);
        border-top-style: solid;
        border-top-width: 1px;
        border-right-color: rgb(153, 153, 153);
        border-right-style: solid;
        border-right-width: 1px;
        border-bottom-color: rgb(153, 153, 153);
        border-bottom-style: solid;
        border-bottom-width: 1px;
        border-left-color: rgb(153, 153, 153);
        border-left-style: solid;
        border-left-width: 1px;
        color: rgb(153, 153, 153);
    }

    .wallet-buttons-container .btn-wallet {
        height: 38px;
        border-right-color: initial;
        border-right-style: initial;
        border-right-width: 3px;
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
        border-bottom-left-radius: 3px;
        font-weight: bold;
    }

    .ngdialog-wallet .ngdialog__content {
        background-color: rgb(29, 29, 29);
        background-image: initial;
        padding-top: 10px;
        padding-right: 10px;
        padding-bottom: 10px;
        padding-left: 10px;
    }

    .ngdialog-content .btn-black {
        margin-top: 1px;
        background-color: rgb(45, 45, 45);
        background-image: initial;
        color: rgb(193, 173, 139);
        font-weight: 600;
    }

    .ngdialog-content .btn-group-justified .btn-group:nth-child(+1) {
        padding-right: 1px;
    }

    .ngdialog-content .btn-group-justified>.btn-group .btn {
        padding-top: 1.75em;
        padding-right: 1.75em;
        padding-bottom: 1.75em;
        padding-left: 1.75em;
    }

    .ngdialog-wallet .my-wallet {
        width: 100%;
        float: left;
        position: relative;
        background-color: rgb(17, 17, 17);
        background-image: initial;
        font-size: 13px;
        color: rgb(193, 173, 139);
        padding-top: 7px;
        padding-right: 20px;
        padding-bottom: 7px;
        padding-left: 20px;
        font-weight: 700;
        text-align: center;
    }

    .ngdialog-wallet .my-wallet>div {
        padding-top: 17px;
        padding-right: 0;
        padding-bottom: 17px;
        padding-left: 0;
    }

    .ngdialog-wallet-page fieldset {
        border-bottom-color: initial;
        border-bottom-style: initial;
        border-bottom-width: 0px;
        padding-top: 0.5em;
        padding-right: 0;
        padding-bottom: 0.5em;
        padding-left: 0;
    }

    .ngdialog-wallet-page .change-password .ngdialog-wallet__form label {
        line-height: inherit;
        text-align: left;
    }

    .ngdialog.ngdialog-theme-default .ngdialog-content {
        line-height: 3em;
    }

    .ngdialog.ngdialog-theme-default.ngdialog-signup .ngdialog-content {
        width: 660px;
        background-size: initial;
    }

    .menu.ngdialog__title {
        color: aliceblue;
    }

    .menu.ngdialog__title .guest-dm {
        width: 38px;
        height: 18px;
        background-color: rgb(177, 61, 59);
        background-image: initial;
        text-align: center;
        vertical-align: middle;
        line-height: 18px;
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
        border-bottom-left-radius: 3px;
        margin-left: 7px;
    }

    .field {
        display: block;
    }

    .field .content {
        display: flex;
        padding-top: 0.5em;
        padding-right: 0;
        padding-bottom: 0.5em;
        padding-left: 0;
    }

    .field .content select,
    .field .content input {
        width: 100%;
    }

    .field .btn_plus,
    .field .detail {
        margin-left: 0px;
    }

    .field .btn_plus span {
        margin-top: 20px;
    }

    .field .btn_plus span button {
        margin-top: 0.5%;
        margin-right: 0.5%;
        margin-bottom: 0.5%;
        margin-left: 0.5%;
    }

    .live {
        height: 500px;
    }
}