@font-face {
    font-family: "Stroke-Gap-Icons";
    src: url("Stroke-Gap-Icons.eot"), url("Stroke-Gap-Icons.ttf"), url("Stroke-Gap-Icons.svg"), url("Stroke-Gap-Icons.woff");
}

/* @font-face {
    font-family: "Stroke-Gap-Icons";
    src: url("zkBGQHJA6kE8QchCgEPSRCREsEVmRnhHAEeUSBRIhEjwSVJJ4kpsSs5LWEueS9hMKkzCTOhNJk1kTZBNtk3qTrBO3E9ST5hQQlCoURZRolIKUoBTBFNMU8hUKFSOVNwAAAABAAAAzAD+ABQAAAAAAAIAAAAAAAAAAAAAAAAAAAAAAAAADg") format("truetype"), url("gIAAC4CAAAAAgAAAAIAAAACAAAJAgAAAAIAAAACAAAFAgAAAAIAAAACAAAAAgAATQIAACACAAAAAgAAIAIAAIMCAAAAAgAAQAIAACACAAAAAgAAAAIAAEACAAAAAgAAAAIAAAACAAAAAgAAAAIAAAACAAAAAgAADgIAAAACAAAAAgAAAAI") format("woff");
    font-weight: normal;
    font-style: normal;
} */

.nav-btn .icon {
    font-family: "Stroke-Gap-Icons";
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant-alternates: normal;
    font-variant-caps: normal;
    font-variant-east-asian: normal;
    font-variant-ligatures: normal;
    font-variant-numeric: normal;
    font-variant-position: normal;
    text-transform: none;
    line-height: 1;
}

.nav-btn .icon-WorldWide:before {
    content: "";
}

.nav-btn .icon-WorldGlobe:before {
    content: "";
}

.nav-btn .icon-Underpants:before {
    content: "";
}

.nav-btn .icon-Tshirt:before {
    content: "";
}

.nav-btn .icon-Trousers:before {
    content: "";
}

.nav-btn .icon-Tie:before {
    content: "";
}

.nav-btn .icon-TennisBall:before {
    content: "";
}

.nav-btn .icon-Telesocpe:before {
    content: "";
}

.nav-btn .icon-Stop:before {
    content: "";
}

.nav-btn .icon-Starship:before {
    content: "";
}

.nav-btn .icon-Starship2:before {
    content: "";
}

.nav-btn .icon-Speaker:before {
    content: "";
}

.nav-btn .icon-Speaker2:before {
    content: "";
}

.nav-btn .icon-Soccer:before {
    content: "";
}

.nav-btn .icon-Snikers:before {
    content: "";
}

.nav-btn .icon-Scisors:before {
    content: "";
}

.nav-btn .icon-Puzzle:before {
    content: "";
}

.nav-btn .icon-Printer:before {
    content: "";
}

.nav-btn .icon-Pool:before {
    content: "";
}

.nav-btn .icon-Podium:before {
    content: "";
}

.nav-btn .icon-Play:before {
    content: "";
}

.nav-btn .icon-Planet:before {
    content: "";
}

.nav-btn .icon-Pause:before {
    content: "";
}

.nav-btn .icon-Next:before {
    content: "";
}

.nav-btn .icon-MusicNote2:before {
    content: "";
}

.nav-btn .icon-MusicNote:before {
    content: "";
}

.nav-btn .icon-MusicMixer:before {
    content: "";
}

.nav-btn .icon-Microphone:before {
    content: "";
}

.nav-btn .icon-Medal:before {
    content: "";
}

.nav-btn .icon-ManFigure:before {
    content: "";
}

.nav-btn .icon-Magnet:before {
    content: "";
}

.nav-btn .icon-Like:before {
    content: "";
}

.nav-btn .icon-Hanger:before {
    content: "";
}

.nav-btn .icon-Handicap:before {
    content: "";
}

.nav-btn .icon-Forward:before {
    content: "";
}

.nav-btn .icon-Footbal:before {
    content: "";
}

.nav-btn .icon-Flag:before {
    content: "";
}

.nav-btn .icon-FemaleFigure:before {
    content: "";
}

.nav-btn .icon-Dislike:before {
    content: "";
}

.nav-btn .icon-DiamondRing:before {
    content: "";
}

.nav-btn .icon-Cup:before {
    content: "";
}

.nav-btn .icon-Crown:before {
    content: "";
}

.nav-btn .icon-Column:before {
    content: "";
}

.nav-btn .icon-Click:before {
    content: "";
}

.nav-btn .icon-Cassette:before {
    content: "";
}

.nav-btn .icon-Bomb:before {
    content: "";
}

.nav-btn .icon-BatteryLow:before {
    content: "";
}

.nav-btn .icon-BatteryFull:before {
    content: "";
}

.nav-btn .icon-Bascketball:before {
    content: "";
}

.nav-btn .icon-Astronaut:before {
    content: "";
}

.nav-btn .icon-WineGlass:before {
    content: "";
}

.nav-btn .icon-Water:before {
    content: "";
}

.nav-btn .icon-Wallet:before {
    content: "";
}

.nav-btn .icon-Umbrella:before {
    content: "";
}

.nav-btn .icon-TV:before {
    content: "";
}

.nav-btn .icon-TeaMug:before {
    content: "";
}

.nav-btn .icon-Tablet:before {
    content: "";
}

.nav-btn .icon-Soda:before {
    content: "";
}

.nav-btn .icon-SodaCan:before {
    content: "";
}

.nav-btn .icon-SimCard:before {
    content: "";
}

.nav-btn .icon-Signal:before {
    content: "";
}

.nav-btn .icon-Shaker:before {
    content: "";
}

.nav-btn .icon-Radio:before {
    content: "";
}

.nav-btn .icon-Pizza:before {
    content: "";
}

.nav-btn .icon-Phone:before {
    content: "";
}

.nav-btn .icon-Notebook:before {
    content: "";
}

.nav-btn .icon-Mug:before {
    content: "";
}

.nav-btn .icon-Mastercard:before {
    content: "";
}

.nav-btn .icon-Ipod:before {
    content: "";
}

.nav-btn .icon-Info:before {
    content: "";
}

.nav-btn .icon-Icecream2:before {
    content: "";
}

.nav-btn .icon-Icecream1:before {
    content: "";
}

.nav-btn .icon-Hourglass:before {
    content: "";
}

.nav-btn .icon-Help:before {
    content: "";
}

.nav-btn .icon-Goto:before {
    content: "";
}

.nav-btn .icon-Glasses:before {
    content: "";
}

.nav-btn .icon-Gameboy:before {
    content: "";
}

.nav-btn .icon-ForkandKnife:before {
    content: "";
}

.nav-btn .icon-Export:before {
    content: "";
}

.nav-btn .icon-Exit:before {
    content: "";
}

.nav-btn .icon-Espresso:before {
    content: "";
}

.nav-btn .icon-Drop:before {
    content: "";
}

.nav-btn .icon-Download:before {
    content: "";
}

.nav-btn .icon-Dollars:before {
    content: "";
}

.nav-btn .icon-Dollar:before {
    content: "";
}

.nav-btn .icon-DesktopMonitor:before {
    content: "";
}

.nav-btn .icon-Corkscrew:before {
    content: "";
}

.nav-btn .icon-CoffeeToGo:before {
    content: "";
}

.nav-btn .icon-Chart:before {
    content: "";
}

.nav-btn .icon-ChartUp:before {
    content: "";
}

.nav-btn .icon-ChartDown:before {
    content: "";
}

.nav-btn .icon-Calculator:before {
    content: "";
}

.nav-btn .icon-Bread:before {
    content: "";
}

.nav-btn .icon-Bourbon:before {
    content: "";
}

.nav-btn .icon-BottleofWIne:before {
    content: "";
}

.nav-btn .icon-Bag:before {
    content: "";
}

.nav-btn .icon-Arrow:before {
    content: "";
}

.nav-btn .icon-Antenna2:before {
    content: "";
}

.nav-btn .icon-Antenna1:before {
    content: "";
}

.nav-btn .icon-Anchor:before {
    content: "";
}

.nav-btn .icon-Wheelbarrow:before {
    content: "";
}

.nav-btn .icon-Webcam:before {
    content: "";
}

.nav-btn .icon-Unlinked:before {
    content: "";
}

.nav-btn .icon-Truck:before {
    content: "";
}

.nav-btn .icon-Timer:before {
    content: "";
}

.nav-btn .icon-Time:before {
    content: "";
}

.nav-btn .icon-StorageBox:before {
    content: "";
}

.nav-btn .icon-Star:before {
    content: "";
}

.nav-btn .icon-ShoppingCart:before {
    content: "";
}

.nav-btn .icon-Shield:before {
    content: "";
}

.nav-btn .icon-Seringe:before {
    content: "";
}

.nav-btn .icon-Pulse:before {
    content: "";
}

.nav-btn .icon-Plaster:before {
    content: "";
}

.nav-btn .icon-Plaine:before {
    content: "";
}

.nav-btn .icon-Pill:before {
    content: "";
}

.nav-btn .icon-PicnicBasket:before {
    content: "";
}

.nav-btn .icon-Phone2:before {
    content: "";
}

.nav-btn .icon-Pencil:before {
    content: "";
}

.nav-btn .icon-Pen:before {
    content: "";
}

.nav-btn .icon-PaperClip:before {
    content: "";
}

.nav-btn .icon-On-Off:before {
    content: "";
}

.nav-btn .icon-Mouse:before {
    content: "";
}

.nav-btn .icon-Megaphone:before {
    content: "";
}

.nav-btn .icon-Linked:before {
    content: "";
}

.nav-btn .icon-Keyboard:before {
    content: "";
}

.nav-btn .icon-House:before {
    content: "";
}

.nav-btn .icon-Heart:before {
    content: "";
}

.nav-btn .icon-Headset:before {
    content: "";
}

.nav-btn .icon-FullShoppingCart:before {
    content: "";
}

.nav-btn .icon-FullScreen:before {
    content: "";
}

.nav-btn .icon-Folder:before {
    content: "";
}

.nav-btn .icon-Floppy:before {
    content: "";
}

.nav-btn .icon-Files:before {
    content: "";
}

.nav-btn .icon-File:before {
    content: "";
}

.nav-btn .icon-FileBox:before {
    content: "";
}

.nav-btn .icon-ExitFullScreen:before {
    content: "";
}

.nav-btn .icon-EmptyBox:before {
    content: "";
}

.nav-btn .icon-Delete:before {
    content: "";
}

.nav-btn .icon-Controller:before {
    content: "";
}

.nav-btn .icon-Compass:before {
    content: "";
}

.nav-btn .icon-CompassTool:before {
    content: "";
}

.nav-btn .icon-ClipboardText:before {
    content: "";
}

.nav-btn .icon-ClipboardChart:before {
    content: "";
}

.nav-btn .icon-ChemicalGlass:before {
    content: "";
}

.nav-btn .icon-CD:before {
    content: "";
}

.nav-btn .icon-Carioca:before {
    content: "";
}

.nav-btn .icon-Car:before {
    content: "";
}

.nav-btn .icon-Book:before {
    content: "";
}

.nav-btn .icon-BigTruck:before {
    content: "";
}

.nav-btn .icon-Bicycle:before {
    content: "";
}

.nav-btn .icon-Wrench:before {
    content: "";
}

.nav-btn .icon-Web:before {
    content: "";
}

.nav-btn .icon-Watch:before {
    content: "";
}

.nav-btn .icon-Volume:before {
    content: "";
}

.nav-btn .icon-Video:before {
    content: "";
}

.nav-btn .icon-Users:before {
    content: "";
}

.nav-btn .icon-User:before {
    content: "";
}

.nav-btn .icon-UploadCLoud:before {
    content: "";
}

.nav-btn .icon-Typing:before {
    content: "";
}

.nav-btn .icon-Tools:before {
    content: "";
}

.nav-btn .icon-Tag:before {
    content: "";
}

.nav-btn .icon-Speedometter:before {
    content: "";
}

.nav-btn .icon-Share:before {
    content: "";
}

.nav-btn .icon-Settings:before {
    content: "";
}

.nav-btn .icon-Search:before {
    content: "";
}

.nav-btn .icon-Screwdriver:before {
    content: "";
}

.nav-btn .icon-Rolodex:before {
    content: "";
}

.nav-btn .icon-Ringer:before {
    content: "";
}

.nav-btn .icon-Resume:before {
    content: "";
}

.nav-btn .icon-Restart:before {
    content: "";
}

.nav-btn .icon-PowerOff:before {
    content: "";
}

.nav-btn .icon-Pointer:before {
    content: "";
}

.nav-btn .icon-Picture:before {
    content: "";
}

.nav-btn .icon-OpenedLock:before {
    content: "";
}

.nav-btn .icon-Notes:before {
    content: "";
}

.nav-btn .icon-Mute:before {
    content: "";
}

.nav-btn .icon-Movie:before {
    content: "";
}

.nav-btn .icon-Microphone2:before {
    content: "";
}

.nav-btn .icon-Message:before {
    content: "";
}

.nav-btn .icon-MessageRight:before {
    content: "";
}

.nav-btn .icon-MessageLeft:before {
    content: "";
}

.nav-btn .icon-Menu:before {
    content: "";
}

.nav-btn .icon-Media:before {
    content: "";
}

.nav-btn .icon-Mail:before {
    content: "";
}

.nav-btn .icon-List:before {
    content: "";
}

.nav-btn .icon-Layers:before {
    content: "";
}

.nav-btn .icon-Key:before {
    content: "";
}

.nav-btn .icon-Imbox:before {
    content: "";
}

.nav-btn .icon-Eye:before {
    content: "";
}

.nav-btn .icon-Edit:before {
    content: "";
}

.nav-btn .icon-DSLRCamera:before {
    content: "";
}

.nav-btn .icon-DownloadCloud:before {
    content: "";
}

.nav-btn .icon-CompactCamera:before {
    content: "";
}

.nav-btn .icon-Cloud:before {
    content: "";
}

.nav-btn .icon-ClosedLock:before {
    content: "";
}

.nav-btn .icon-Chart2:before {
    content: "";
}

.nav-btn .icon-Bulb:before {
    content: "";
}

.nav-btn .icon-Briefcase:before {
    content: "";
}

.nav-btn .icon-Blog:before {
    content: "";
}

.nav-btn .icon-Agenda:before {
    content: "";
}

