/*게임리스트 영역조정*/
.bs-modal {
    padding: 0px;
}

.modal-body .tab-mdl {
    padding: 10px 0px 10px 30px;
}

/* in SLB */
.bsm-cont {
    margin-top: -1px;
    width: 100%;
}

.form-container .form-group .labels {
    width: 130px;
}

.form-container .form-group .infos {
    padding-left: 10px;
}


#dashboard {
    width: 100%;
}

#dashboard .bsm-inner {
    padding-left: 130px;
}

.player-balance {
    width: 30%;
    float: left;
}

.form-container .form-group .infos span {
    color: #ff9c0e;
    font-size: 22px;
    font-family: SCDream6;
    margin-top: 8px;
}

.mypage-nav button {
    width: 100%;
}

.uk-modal {
    text-align: left;
}


.uk-modal-header {
    padding: 0 30px;
}

._pagination>li.active a {
    background-color: #0a182f;
}

@media (max-width: 640px) {

    /* 모바일 마이페이지2 좌여백 없애기*/
    #dashboard .bsm-inner {
        padding-left: 0px;
    }
}

@media (min-width: 800px) {

    /** 좌측메뉴 이벤트게시판까지 보이도록 전체높이로 넓힘 **/
    #dashboard {
        width: 100%;
        height: 100%;
    }

    .bsm-inner {
        width: 100%;
        height: 100%;
        float: left;
        position: relative;
        padding: 0;
        overflow: hidden;
        border: solid 1px #656565;
        border-radius: 5px;
        padding-left: 130px;
    }

    .bs-modal {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0;
        overflow-y: scroll;
    }

    .bsm-cont {
        width: 100%;
        height: 100%;
    }

    .nav-mdl {
        width: 130px;
        height: 100%;
        float: left;
        position: absolute;
        left: 0;
        background-color: #191919;
        top: 0;
        z-index: 3;
        box-shadow: 0 0 10px #000;
    }

    .modal-body {
        width: 100%;
        height: 100%;
        float: left;
        text-align: center;
        position: relative;
        background-image: url(../images/modal/modal-bg.png);
        background-repeat: no-repeat;
        background-position: top 2px center;
        background-color: #121212;
        border-left: none;
        padding: 0;
        z-index: 1;
    }
}




.nav-mdl {
    width: 130px;
    height: 100%;
    float: left;
    position: absolute;
    left: 0;
    background-color: rgb(25, 25, 25);
    top: 0;
    z-index: 3;
    box-shadow: 0 0 10px rgb(0, 0, 0);
}

.nav-mdl .nav-cont {
    width: 100%;
    float: left;
    padding-top: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    position: relative;
}

.nav-mdl .nav-btn {
    width: 100%;
    height: 90px;
    float: left;
    background-image: linear-gradient(180deg, rgb(44, 45, 47), rgb(44, 45, 47));
    border-top-color: rgb(76, 77, 79);
    border-top-style: solid;
    border-top-width: 2px;
    border-right-color: rgb(26, 26, 28);
    border-right-style: solid;
    border-right-width: 1px;
    border-bottom-color: initial;
    border-bottom-style: none;
    border-bottom-width: initial;
    border-left-color: rgb(59, 59, 61);
    border-left-style: solid;
    border-left-width: 1px;
    color: rgb(156, 156, 158);
    font-size: 12px;
    font-family: SCDream6;
    position: relative;
    z-index: 1;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    padding-bottom: 2px;
    transition-property: initial;
    transition-duration: 0.3s;
    transition-timing-function: initial;
    transition-delay: initial;
    overflow-x: hidden;
    overflow-y: hidden;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.5);
}

.nav-mdl .nav-btn:hover {
    color: rgb(0, 220, 255);
    border-top-color: rgb(92, 93, 95);
    border-top-style: solid;
    border-top-width: 2px;
}

.nav-mdl .nav-btn.active {
    border-left-color: rgb(222, 184, 38);
    border-left-style: solid;
    border-left-width: 1px;
    color: rgb(255, 178, 25);
    text-shadow: 0 0 15px rgb(225, 183, 19);
    background-image: linear-gradient(180deg, rgb(234, 161, 35), rgb(151, 88, 10));
    margin-top: -5px;
    margin-right: 0;
    margin-bottom: -5px;
    margin-left: 0;
    z-index: 2;
}

.nav-mdl .nav-btn.active:first-child {
    margin-top: 0;
}

.nav-mdl .nav-btn:first-child {
    border-left-color: initial;
    border-left-style: none;
    border-left-width: initial;
}

.nav-mdl .nav-btn:last-child {
    border-right-color: initial;
    border-right-style: none;
    border-right-width: initial;
}

.nav-mdl .nav-btn:before,
.nav-mdl .nav-btn:after {
    content: "";
    width: 100%;
    height: 50%;
    position: absolute;
    left: 0;
    z-index: -1;
    transition-property: initial;
    transition-duration: 0.3s;
    transition-timing-function: initial;
    transition-delay: initial;
    opacity: 1;
}

.nav-mdl .nav-btn:before {
    top: 0;
    background-image: linear-gradient(180deg, rgb(55, 56, 58), rgb(44, 45, 47));
}

.nav-mdl .nav-btn:after {
    bottom: 0;
    background-image: linear-gradient(180deg, rgb(33, 34, 36), rgb(21, 22, 26));
}

.nav-mdl .nav-btn:hover:before {
    background-image: linear-gradient(180deg, rgb(72, 73, 75), rgb(60, 61, 63));
}

.nav-mdl .nav-btn:hover:after {
    background-image: linear-gradient(180deg, rgb(50, 51, 53), rgb(39, 39, 41));
}

.nav-mdl .nav-btn.active:before {
    opacity: 0;
}

.nav-mdl .nav-btn.active:after {
    opacity: 0;
}

.nav-mdl .nav-btn button {
    width: 100%;
    height: 100%;
    float: left;
    background-color: transparent;
    border-top-color: initial;
    border-top-style: none;
    border-top-width: initial;
    border-right-color: initial;
    border-right-style: none;
    border-right-width: initial;
    border-bottom-color: initial;
    border-bottom-style: none;
    border-bottom-width: initial;
    border-left-color: initial;
    border-left-style: none;
    border-left-width: initial;
    color: rgb(255, 255, 255);
    font-size: 14px;
    text-shadow: 0 1px 1px rgb(51, 0, 0);
}

.nav-mdl .nav-btn.active button {
    color: rgb(255, 255, 255);
    text-shadow: 0 0 15px rgb(255, 117, 25);
}

.nav-mdl .nav-btn button i {
    font-size: 24px;
}



.mdl-close-btn {
    width: 41px;
    height: 43px;
    top: 12px;
    right: 12px;
    position: absolute;
    background-color: transparent;
    background-image: url("../images/modal/close.png");
    background-repeat: no-repeat;
    background-position: center;
    transition-property: initial;
    transition-duration: 0.3s;
    transition-timing-function: initial;
    transition-delay: initial;
    border-top-color: initial;
    border-top-style: none;
    border-top-width: initial;
    border-right-color: initial;
    border-right-style: none;
    border-right-width: initial;
    border-bottom-color: initial;
    border-bottom-style: none;
    border-bottom-width: initial;
    border-left-color: initial;
    border-left-style: none;
    border-left-width: initial;
    z-index: 3;
}

.mdl-close-btn:before,
.mdl-close-btn:after {
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    position: absolute;
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: center;
    transition-property: initial;
    transition-duration: 0.5s;
    transition-timing-function: initial;
    transition-delay: initial;
}

.mdl-close-btn:before {
    background-image: url("../images/modal/close.png");
    opacity: 1;
}

.mdl-close-btn:after {
    background-image: url("../images/modal/close-on.png");
    opacity: 0;
}

.mdl-close-btn:hover:before {
    opacity: 0;
}

.mdl-close-btn:hover:after {
    opacity: 1;
}

.mdl-menu {
    width: 100%;
    float: left;
    margin-top: 0;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 0;
    background-color: rgb(21, 21, 21);
    border-bottom-color: rgb(240, 106, 130);
    border-bottom-style: solid;
    border-bottom-width: 1px;
    position: relative;
    padding-left: 82px;
    padding-top: 20px;
}

.mdl-menu .deco {
    width: 50px;
    height: 35px;
    position: absolute;
    left: 30px;
    bottom: 0;
    background-color: rgb(240, 106, 130);
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.mdl-menu .deco i {
    color: rgb(255, 255, 255);
    font-size: 14px;
}

.mdl-menu a {
    width: auto;
    height: 40px;
    float: left;
    padding-top: 0;
    padding-right: 0;
    padding-bottom: 0;
    padding-left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgb(255, 255, 255);
    font-size: 14px;
    margin-top: 0;
    margin-right: 20px;
    margin-bottom: 0;
    margin-left: 20px;
    transition-property: initial;
    transition-duration: 0.3s;
    transition-timing-function: initial;
    transition-delay: initial;
    text-decoration-line: none;
    position: relative;
}

.mdl-menu a:hover {
    color: rgb(240, 106, 130);
}

.mdl-menu a.active {
    color: rgb(240, 106, 130);
}

.mdl-menu a.active:hover {
    color: rgb(240, 106, 130);
}



.mypageModal .form-container .form-group {
    padding-left: 0;
}


.mypage-nav {
    width: 100%;
    float: left;
    margin-bottom: 40px;
    border-radius: 20px 4px 20px 4px;
    box-shadow: 0 0 5px #000;
    overflow: hidden;
}



.mypage-nav {
    width: 100%;
    float: left;
    margin-bottom: 40px;
    border-radius: 20px 4px 20px 4px;
    box-shadow: 0 0 5px #000;
    overflow: hidden;
}

.mypage-nav button {
    width: 50%;
    height: 40px;
    float: left;
    background-color: #2f2f2f;
    border: none;
    color: #9c9c9e;
    font-size: 12px;
    font-family: SCDream6;
    position: relative;
    z-index: 1;
    border-right: 1px solid #1a1a1c;
    border-left: 1px solid #3b3b3d;
    border-top: 2px solid #4c4d4f;
    padding-bottom: 2px;
    transition: 0.3s;
}

.mypage-nav button:hover {
    color: #f3d752;
    border-top: 2px solid #5c5d5f;
}

.mypage-nav button.active {
    color: #f3d752;
    border-top: 2px solid #f3d752;
    text-shadow: 0 0 15px rgba(243, 215, 82, 0.55);
}

.mypage-nav button:first-child {
    border-left: none;
}

.mypage-nav button:last-child {
    border-right: none;
}

.mypage-nav button:before,
.mypage-nav button:after {
    content: '';
    width: 100%;
    height: 50%;
    position: absolute;
    left: 0;
    z-index: -1;
    transition: 0.3s;
    opacity: 1;
}

.mypage-nav button:before {
    top: 0;
    background-image: linear-gradient(#37383a, #2c2d2f);
}

.mypage-nav button:after {
    bottom: 0;
    background-image: linear-gradient(#212224, #15161a);
}

.mypage-nav button:hover:before {
    background-image: linear-gradient(#48494b, #3c3d3f);
}

.mypage-nav button:hover:after {
    background-image: linear-gradient(#323335, #272729);
}

.mypage-nav button.active:before {
    opacity: 0;
}

.mypage-nav button.active:after {
    opacity: 0;
}

.mypageModal .tab-mdl {
    min-height: 600px;
}

.mypage-tabs {
    width: 100%;
    float: left;
    min-height: 511px;
}

.mypage-tabs .mp-tab {
    width: 100%;
    float: left;
    animation: modalTabAnim 0.8s ease 1;
    display: none;
}

@keyframes modalTabAnim {
    0% {
        opacity: 0;
        transform: translateX(100%);
    }

    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

.mypage-tabs .mp-tab.active {
    display: block
}

.mypageModal .form-container .form-group {
    padding-left: 150px;
}

.mypageModal .form-container .form-group .labels {
    width: 150px;
}

.mypageModal .mp-info {
    width: 100%;
    height: 80px;
    float: left;
    background-color: rgba(204, 204, 204, .20);
    border: solid 1px #616161;
    border-radius: 10px;
    margin-bottom: 10px;
}

.mypageModal .mp-info .cont {
    width: 25%;
    height: 100%;
    float: left;
    display: table;
}

.mypageModal .mp-info .cont span {
    color: #fff;
    font-size: 14px;
    display: table-cell;
    text-align: center;
    vertical-align: middle;
}

.mypageModal .mp-info .cont span img {
    position: relative;
    display: inline-block;
    vertical-align: middle;
}

.mypageModal .mp-info .cont span p:first-child {
    color: #fff;
    font-size: 14px;
    display: inline-block;
    vertical-align: middle;
    margin: 0;
}

.mypageModal .mp-info .cont span p:last-child {
    color: #ffd400;
    font-size: 18px;
    display: inline-block;
    position: relative;
    margin: 0;
    vertical-align: middle;
}

.mypageModal .mp-info .point-form {
    width: 100%;
    height: 35px;
    border: none;
    border-radius: 3px;
    padding: 0 5px;
    color: #fff;
    font-size: 12px;
    background-color: rgba(0, 0, 0, 0.5);
}

.mypageModal .mp-info .point-form::-webkit-input-placeholder {
    color: #ccc;
}

.mypageModal .mp-info .points-btn {
    color: #fff;
    font-size: 11px;
    width: auto;
    height: 35px;
    border: none;
    border-radius: 5px;
    background-color: #009aff;
    padding: 0 10px;
    transition: 0.3s;
    white-space: nowrap;
}

.mypageModal .mp-info .points-btn:hover {
    background-color: #0a88e6;
}

.mypageModal .my-table {
    width: 100%;
    height: auto;
    float: left;
    position: relative;
    margin-top: 20px;
}

.mypageModal .my-table:before {
    content: '';
    display: block;
    position: absolute;
    top: -20px;
    left: 0;
    width: 100%;
    height: 1px;
    transition: opacity .2s linear;
    background: radial-gradient(ellipse at center, #b7853c 0, rgba(75, 251, 181, 0) 68%);
}


/*******************************
            Button
*******************************/

.ui.button {
    cursor: pointer;
    display: inline-block;
    min-height: 1em;
    outline: none;
    border: none;
    vertical-align: baseline;
    background: #E0E1E2 none;
    color: rgba(0, 0, 0, 0.6);
    font-family: 'Lato', 'Helvetica Neue', Arial, Helvetica, sans-serif;
    margin: 0em 0.25em 0em 0em;
    padding: 0.78571429em 1.5em 0.78571429em;
    text-transform: none;
    text-shadow: none;
    font-weight: bold;
    line-height: 1em;
    font-style: normal;
    text-align: center;
    text-decoration: none;
    border-radius: 0.28571429rem;
    -webkit-box-shadow: 0px 0px 0px 1px transparent inset, 0px 0em 0px 0px rgba(34, 36, 38, 0.15) inset;
    box-shadow: 0px 0px 0px 1px transparent inset, 0px 0em 0px 0px rgba(34, 36, 38, 0.15) inset;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-transition: opacity 0.1s ease, background-color 0.1s ease, color 0.1s ease, background 0.1s ease, -webkit-box-shadow 0.1s ease;
    transition: opacity 0.1s ease, background-color 0.1s ease, color 0.1s ease, background 0.1s ease, -webkit-box-shadow 0.1s ease;
    transition: opacity 0.1s ease, background-color 0.1s ease, color 0.1s ease, box-shadow 0.1s ease, background 0.1s ease;
    transition: opacity 0.1s ease, background-color 0.1s ease, color 0.1s ease, box-shadow 0.1s ease, background 0.1s ease, -webkit-box-shadow 0.1s ease;
    will-change: '';
    -webkit-tap-highlight-color: transparent;
}


.ui.teal.buttons .button,
.ui.teal.button {
    background-color: #00B5AD;
    color: #FFFFFF;
    text-shadow: none;
    background-image: none;
}

.ui.teal.button {
    -webkit-box-shadow: 0px 0em 0px 0px rgba(34, 36, 38, 0.15) inset;
    box-shadow: 0px 0em 0px 0px rgba(34, 36, 38, 0.15) inset;
}

.ui.teal.buttons .button:hover,
.ui.teal.button:hover {
    background-color: #009c95;
    color: #FFFFFF;
    text-shadow: none;
}

.ui.teal.buttons .button:focus,
.ui.teal.button:focus {
    background-color: #008c86;
    color: #FFFFFF;
    text-shadow: none;
}

.ui.teal.buttons .button:active,
.ui.teal.button:active {
    background-color: #00827c;
    color: #FFFFFF;
    text-shadow: none;
}

.ui.teal.buttons .active.button,
.ui.teal.buttons .active.button:active,
.ui.teal.active.button,
.ui.teal.button .active.button:active {
    background-color: #009c95;
    color: #FFFFFF;
    text-shadow: none;
}



/* Basic */

.ui.basic.teal.buttons .button,
.ui.basic.teal.button {
    -webkit-box-shadow: 0px 0px 0px 1px #00B5AD inset;
    box-shadow: 0px 0px 0px 1px #00B5AD inset;
    color: #00B5AD;
}

.ui.basic.teal.buttons .button:hover,
.ui.basic.teal.button:hover {
    background: transparent;
    -webkit-box-shadow: 0px 0px 0px 1px #009c95 inset;
    box-shadow: 0px 0px 0px 1px #009c95 inset;
    color: #009c95;
}

.ui.basic.teal.buttons .button:focus,
.ui.basic.teal.button:focus {
    background: transparent;
    -webkit-box-shadow: 0px 0px 0px 1px #008c86 inset;
    box-shadow: 0px 0px 0px 1px #008c86 inset;
    color: #009c95;
}

.ui.basic.teal.buttons .active.button,
.ui.basic.teal.active.button {
    background: transparent;
    -webkit-box-shadow: 0px 0px 0px 1px #009c95 inset;
    box-shadow: 0px 0px 0px 1px #009c95 inset;
    color: #00827c;
}

.ui.basic.teal.buttons .button:active,
.ui.basic.teal.button:active {
    -webkit-box-shadow: 0px 0px 0px 1px #00827c inset;
    box-shadow: 0px 0px 0px 1px #00827c inset;
    color: #00827c;
}

.ui.buttons:not(.vertical)>.basic.teal.button:not(:first-child) {
    margin-left: -1px;
}



i.icon,
i.icons {
    font-size: 1em;
}




/*--- Blue ---*/

.ui.blue.buttons .button,
.ui.blue.button {
    background-color: #2185D0;
    color: #FFFFFF;
    text-shadow: none;
    background-image: none;
}

.ui.blue.button {
    -webkit-box-shadow: 0px 0em 0px 0px rgba(34, 36, 38, 0.15) inset;
    box-shadow: 0px 0em 0px 0px rgba(34, 36, 38, 0.15) inset;
}

.ui.blue.buttons .button:hover,
.ui.blue.button:hover {
    background-color: #1678c2;
    color: #FFFFFF;
    text-shadow: none;
}

.ui.blue.buttons .button:focus,
.ui.blue.button:focus {
    background-color: #0d71bb;
    color: #FFFFFF;
    text-shadow: none;
}

.ui.blue.buttons .button:active,
.ui.blue.button:active {
    background-color: #1a69a4;
    color: #FFFFFF;
    text-shadow: none;
}

.ui.blue.buttons .active.button,
.ui.blue.buttons .active.button:active,
.ui.blue.active.button,
.ui.blue.button .active.button:active {
    background-color: #1279c6;
    color: #FFFFFF;
    text-shadow: none;
}

/* Basic */

.ui.basic.blue.buttons .button,
.ui.basic.blue.button {
    -webkit-box-shadow: 0px 0px 0px 1px #2185D0 inset;
    box-shadow: 0px 0px 0px 1px #2185D0 inset;
    color: #2185D0;
}

.ui.basic.blue.buttons .button:hover,
.ui.basic.blue.button:hover {
    background: transparent;
    -webkit-box-shadow: 0px 0px 0px 1px #1678c2 inset;
    box-shadow: 0px 0px 0px 1px #1678c2 inset;
    color: #1678c2;
}

.ui.basic.blue.buttons .button:focus,
.ui.basic.blue.button:focus {
    background: transparent;
    -webkit-box-shadow: 0px 0px 0px 1px #0d71bb inset;
    box-shadow: 0px 0px 0px 1px #0d71bb inset;
    color: #1678c2;
}

.ui.basic.blue.buttons .active.button,
.ui.basic.blue.active.button {
    background: transparent;
    -webkit-box-shadow: 0px 0px 0px 1px #1279c6 inset;
    box-shadow: 0px 0px 0px 1px #1279c6 inset;
    color: #1a69a4;
}

.ui.basic.blue.buttons .button:active,
.ui.basic.blue.button:active {
    -webkit-box-shadow: 0px 0px 0px 1px #1a69a4 inset;
    box-shadow: 0px 0px 0px 1px #1a69a4 inset;
    color: #1a69a4;
}

.ui.buttons:not(.vertical)>.basic.blue.button:not(:first-child) {
    margin-left: -1px;
}



/* -- BS Table -- */

.bs-table {
    width: 100%;
    float: left;
}

.bs-table thead {
    background-color: #2f2e2d;
}

.bs-table thead th {
    height: 40px;
    color: #fff;
    font-size: 14px;
    text-align: center;
}

.bs-table thead th:first-child {
    border-radius: 5px 0 0 0;
}

.bs-table thead th:last-child {
    border-radius: 0 5px 0 0;
}

.bs-table tr td {
    height: 44px;
    color: #fff;
    font-size: 12px;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.2);
    border-bottom: solid 1px #3a3a3a;
    padding: 1px;
    transition: 0.3s;
}

.bs-table tr:hover td {
    background-color: rgba(0, 0, 0, 0.4);
    color: #ff7745;
}

.bs-table tbody tr:last-child td {
    border-bottom: none;
}

.bs-table tbody tr:last-child td:first-child {
    border-radius: 0 0 0 5px;
}

.bs-table tbody tr:last-child td:last-child {
    border-radius: 0 0 5px 0;
}

.bs-table tr td.count {
    /*width:10%;*/
}

.bs-table tr td.title {
    text-align: left;
    padding: 0 10px;
    cursor: pointer;
}

.bs-table tr td.date {
    /*width:20%;*/
    font-size: 12px;
}

.bs-table tr td.red {
    color: #fea304;
}

.bs-table td .level-txt {
    display: inline-block;
    width: 45px;
    text-align: left;
}

.bs-table tr td a {
    color: #fff;
    font-size: 12px;
    transition: 0.3s;
    text-decoration: none;
}

.bs-table tr:hover td a {
    color: #ff7745;
}

.new-icon {
    width: 30px;
    height: 15px;
    border-radius: 2px;
    border: none;
    color: #000;
    font-size: 8px;
    background-color: #ffe25c;
    display: inline-block;
    vertical-align: middle;
}

/* -- Pagination -- */

.pagination>li>a {
    color: #fff;
    font-size: 14px;
    font-family: SCDream4;
    text-decoration: none;
    border: 1px solid rgba(255, 255, 255, .2);
    background-color: transparent;
    width: 25px;
    height: 25px;
    padding: 2px 0;
    margin: 0 2px;
    border-radius: 2px;
}

.pagination li.turn-pg a {
    color: #fff;
    padding: 0 0;
    font-size: 18px;
    background-color: rgba(255, 255, 255, .15);
}

.pagination li.turn-pg a span {
    position: relative;
    top: -3px;
    font-family: Arial;
}

.pagination li.space-r a {
    margin-right: 7px;
}

.pagination li.space-l a {
    margin-left: 7px;
}

.pagination>li>a:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.3);
    border: 1px solid rgba(255, 255, 255, 0.3)
}

.pagination>li.turn-pg>a:hover {
    background-color: rgba(28, 165, 255, 0.3);
    border: 1px solid rgba(0, 166, 255, 0.3);
}

.pagination .active {
    color: #fff;
    background-color: #ff7519;
    border: none;
}

.pagination .active:hover {
    color: #000;
    background-color: rgba(255, 163, 4, 1);
    border: 1px solid rgba(255, 163, 4, 1);
}




.ui.inverted.black.buttons .button,
.ui.inverted.black.button {
    background-color: transparent;
    -webkit-box-shadow: 0px 0px 0px 2px #D4D4D5 inset;
    box-shadow: 0px 0px 0px 2px #D4D4D5 inset;
    color: #FFFFFF;
}

.ui.inverted.black.buttons .button:hover,
.ui.inverted.black.button:hover,
.ui.inverted.black.buttons .button:focus,
.ui.inverted.black.button:focus,
.ui.inverted.black.buttons .button.active,
.ui.inverted.black.button.active,
.ui.inverted.black.buttons .button:active,
.ui.inverted.black.button:active {
    -webkit-box-shadow: none;
    box-shadow: none;
    color: #FFFFFF;
}

.ui.inverted.black.buttons .button:hover,
.ui.inverted.black.button:hover {
    background-color: #000000;
}

.ui.inverted.black.buttons .button:focus,
.ui.inverted.black.button:focus {
    background-color: #000000;
}

.ui.inverted.black.buttons .active.button,
.ui.inverted.black.active.button {
    background-color: #000000;
}

.ui.inverted.black.buttons .button:active,
.ui.inverted.black.button:active {
    background-color: #000000;
}

/* Inverted Basic */

.ui.inverted.black.basic.buttons .button,
.ui.inverted.black.buttons .basic.button,
.ui.inverted.black.basic.button {
    background-color: transparent;
    -webkit-box-shadow: 0px 0px 0px 2px rgba(255, 255, 255, 0.5) inset;
    box-shadow: 0px 0px 0px 2px rgba(255, 255, 255, 0.5) inset;
    color: #FFFFFF;
}

.ui.inverted.black.basic.buttons .button:hover,
.ui.inverted.black.buttons .basic.button:hover,
.ui.inverted.black.basic.button:hover {
    -webkit-box-shadow: 0px 0px 0px 2px #000000 inset;
    box-shadow: 0px 0px 0px 2px #000000 inset;
    color: #FFFFFF;
}

.ui.inverted.black.basic.buttons .button:focus,
.ui.inverted.black.basic.buttons .button:focus,
.ui.inverted.black.basic.button:focus {
    -webkit-box-shadow: 0px 0px 0px 2px #000000 inset;
    box-shadow: 0px 0px 0px 2px #000000 inset;
    color: #545454;
}

.ui.inverted.black.basic.buttons .active.button,
.ui.inverted.black.buttons .basic.active.button,
.ui.inverted.black.basic.active.button {
    -webkit-box-shadow: 0px 0px 0px 2px #000000 inset;
    box-shadow: 0px 0px 0px 2px #000000 inset;
    color: #FFFFFF;
}

.ui.inverted.black.basic.buttons .button:active,
.ui.inverted.black.buttons .basic.button:active,
.ui.inverted.black.basic.button:active {
    -webkit-box-shadow: 0px 0px 0px 2px #000000 inset;
    box-shadow: 0px 0px 0px 2px #000000 inset;
    color: #FFFFFF;
}




/* Other Combinations */

.ui.form .two.fields>.fields,
.ui.form .two.fields>.field {
    width: 50%;
}

.ui.form .three.fields>.fields,
.ui.form .three.fields>.field {
    width: 33.33333333%;
}

.ui.form .four.fields>.fields,
.ui.form .four.fields>.field {
    width: 25%;
}

.ui.form .five.fields>.fields,
.ui.form .five.fields>.field {
    width: 20%;
}

.ui.form .six.fields>.fields,
.ui.form .six.fields>.field {
    width: 16.66666667%;
}

.ui.form .seven.fields>.fields,
.ui.form .seven.fields>.field {
    width: 14.28571429%;
}

.ui.form .eight.fields>.fields,
.ui.form .eight.fields>.field {
    width: 12.5%;
}

.ui.form .nine.fields>.fields,
.ui.form .nine.fields>.field {
    width: 11.11111111%;
}

.ui.form .ten.fields>.fields,
.ui.form .ten.fields>.field {
    width: 10%;
}

/* Swap to full width on mobile */

@media only screen and (max-width: 767px) {
    .ui.form .fields {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }

    .ui[class*="equal width"].form:not(.unstackable) .fields>.field,
    .ui.form:not(.unstackable) [class*="equal width"].fields:not(.unstackable)>.field,
    .ui.form:not(.unstackable) .two.fields:not(.unstackable)>.fields,
    .ui.form:not(.unstackable) .two.fields:not(.unstackable)>.field,
    .ui.form:not(.unstackable) .three.fields:not(.unstackable)>.fields,
    .ui.form:not(.unstackable) .three.fields:not(.unstackable)>.field,
    .ui.form:not(.unstackable) .four.fields:not(.unstackable)>.fields,
    .ui.form:not(.unstackable) .four.fields:not(.unstackable)>.field,
    .ui.form:not(.unstackable) .five.fields:not(.unstackable)>.fields,
    .ui.form:not(.unstackable) .five.fields:not(.unstackable)>.field,
    .ui.form:not(.unstackable) .six.fields:not(.unstackable)>.fields,
    .ui.form:not(.unstackable) .six.fields:not(.unstackable)>.field,
    .ui.form:not(.unstackable) .seven.fields:not(.unstackable)>.fields,
    .ui.form:not(.unstackable) .seven.fields:not(.unstackable)>.field,
    .ui.form:not(.unstackable) .eight.fields:not(.unstackable)>.fields,
    .ui.form:not(.unstackable) .eight.fields:not(.unstackable)>.field,
    .ui.form:not(.unstackable) .nine.fields:not(.unstackable)>.fields,
    .ui.form:not(.unstackable) .nine.fields:not(.unstackable)>.field,
    .ui.form:not(.unstackable) .ten.fields:not(.unstackable)>.fields,
    .ui.form:not(.unstackable) .ten.fields:not(.unstackable)>.field {
        width: 100%;
        margin: 0em 0em 1em;
    }
}

/* Sizing Combinations */

.ui.form .fields .wide.field {
    width: 6.25%;
    padding-left: 0.5em;
    padding-right: 0.5em;
}

.ui.form .one.wide.field {
    width: 6.25%;
}

.ui.form .two.wide.field {
    width: 12.5%;
}

.ui.form .three.wide.field {
    width: 18.75%;
}

.ui.form .four.wide.field {
    width: 25%;
}

.ui.form .five.wide.field {
    width: 31.25%;
}

.ui.form .six.wide.field {
    width: 37.5%;
}

.ui.form .seven.wide.field {
    width: 43.75%;
}

.ui.form .eight.wide.field {
    width: 50%;
}

.ui.form .nine.wide.field {
    width: 56.25%;
}

.ui.form .ten.wide.field {
    width: 62.5%;
}

.ui.form .eleven.wide.field {
    width: 68.75%;
}

.ui.form .twelve.wide.field {
    width: 75%;
}

.ui.form .thirteen.wide.field {
    width: 81.25%;
}

.ui.form .fourteen.wide.field {
    width: 87.5%;
}

.ui.form .fifteen.wide.field {
    width: 93.75%;
}

.ui.form .sixteen.wide.field {
    width: 100%;
}

/* Swap to full width on mobile */

@media only screen and (max-width: 767px) {

    .ui.form:not(.unstackable) .two.fields:not(.unstackable)>.fields,
    .ui.form:not(.unstackable) .two.fields:not(.unstackable)>.field,
    .ui.form:not(.unstackable) .three.fields:not(.unstackable)>.fields,
    .ui.form:not(.unstackable) .three.fields:not(.unstackable)>.field,
    .ui.form:not(.unstackable) .four.fields:not(.unstackable)>.fields,
    .ui.form:not(.unstackable) .four.fields:not(.unstackable)>.field,
    .ui.form:not(.unstackable) .five.fields:not(.unstackable)>.fields,
    .ui.form:not(.unstackable) .five.fields:not(.unstackable)>.field,
    .ui.form:not(.unstackable) .fields:not(.unstackable)>.two.wide.field,
    .ui.form:not(.unstackable) .fields:not(.unstackable)>.three.wide.field,
    .ui.form:not(.unstackable) .fields:not(.unstackable)>.four.wide.field,
    .ui.form:not(.unstackable) .fields:not(.unstackable)>.five.wide.field,
    .ui.form:not(.unstackable) .fields:not(.unstackable)>.six.wide.field,
    .ui.form:not(.unstackable) .fields:not(.unstackable)>.seven.wide.field,
    .ui.form:not(.unstackable) .fields:not(.unstackable)>.eight.wide.field,
    .ui.form:not(.unstackable) .fields:not(.unstackable)>.nine.wide.field,
    .ui.form:not(.unstackable) .fields:not(.unstackable)>.ten.wide.field,
    .ui.form:not(.unstackable) .fields:not(.unstackable)>.eleven.wide.field,
    .ui.form:not(.unstackable) .fields:not(.unstackable)>.twelve.wide.field,
    .ui.form:not(.unstackable) .fields:not(.unstackable)>.thirteen.wide.field,
    .ui.form:not(.unstackable) .fields:not(.unstackable)>.fourteen.wide.field,
    .ui.form:not(.unstackable) .fields:not(.unstackable)>.fifteen.wide.field,
    .ui.form:not(.unstackable) .fields:not(.unstackable)>.sixteen.wide.field {
        width: 100%;
    }

    .ui.form .fields {
        margin-bottom: 0em;
    }
}

/*--------------------
       Equal Width
  ---------------------*/

.ui[class*="equal width"].form .fields>.field,
.ui.form [class*="equal width"].fields>.field {
    width: 100%;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
}

/*--------------------
      Inline Fields
  ---------------------*/

.ui.form .inline.fields {
    margin: 0em 0em 1em;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.ui.form .inline.fields .field {
    margin: 0em;
    padding: 0em 1em 0em 0em;
}

/* Inline Label */

.ui.form .inline.fields>label,
.ui.form .inline.fields .field>label,
.ui.form .inline.fields .field>p,
.ui.form .inline.field>label,
.ui.form .inline.field>p {
    display: inline-block;
    width: auto;
    margin-top: 0em;
    margin-bottom: 0em;
    vertical-align: baseline;
    font-size: 0.92857143em;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.87);
    text-transform: none;
}

/* Grouped Inline Label */

.ui.form .inline.fields>label {
    margin: 0.035714em 1em 0em 0em;
}

/* Inline Input */

.ui.form .inline.fields .field>input,
.ui.form .inline.fields .field>select,
.ui.form .inline.field>input,
.ui.form .inline.field>select {
    display: inline-block;
    width: auto;
    margin-top: 0em;
    margin-bottom: 0em;
    vertical-align: middle;
    font-size: 1em;
}

/* Label */

.ui.form .inline.fields .field> :first-child,
.ui.form .inline.field> :first-child {
    margin: 0em 0.85714286em 0em 0em;
}

.ui.form .inline.fields .field> :only-child,
.ui.form .inline.field> :only-child {
    margin: 0em;
}

/* Wide */

.ui.form .inline.fields .wide.field {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.ui.form .inline.fields .wide.field>input,
.ui.form .inline.fields .wide.field>select {
    width: 100%;
}

/*--------------------
          Sizes
  ---------------------*/

.ui.mini.form {
    font-size: 0.78571429rem;
}

.ui.tiny.form {
    font-size: 0.85714286rem;
}

.ui.small.form {
    font-size: 0.92857143rem;
}

.ui.form {
    font-size: 1rem;
}

.ui.large.form {
    font-size: 1.14285714rem;
}

.ui.big.form {
    font-size: 1.28571429rem;
}

.ui.huge.form {
    font-size: 1.42857143rem;
}

.ui.massive.form {
    font-size: 1.71428571rem;
}


.ui.form .field {
    clear: both;
    margin: 0em 0em 1em;
}

.ui.form .field:last-child,
.ui.form .fields:last-child .field {
    margin-bottom: 0em;
}

.ui.form .fields .field {
    clear: both;
    margin: 0em;
}



/*--------------------
    Standard Inputs
---------------------*/

.ui.form textarea,
.ui.form input:not([type]),
.ui.form input[type="date"],
.ui.form input[type="datetime-local"],
.ui.form input[type="email"],
.ui.form input[type="number"],
.ui.form input[type="password"],
.ui.form input[type="search"],
.ui.form input[type="tel"],
.ui.form input[type="time"],
.ui.form input[type="text"],
.ui.form input[type="file"],
.ui.form input[type="url"] {
    width: 100%;
    vertical-align: top;
}

/* Set max height on unusual input */

.ui.form ::-webkit-datetime-edit,
.ui.form ::-webkit-inner-spin-button {
    height: 1.21428571em;
}

.ui.form input:not([type]),
.ui.form input[type="date"],
.ui.form input[type="datetime-local"],
.ui.form input[type="email"],
.ui.form input[type="number"],
.ui.form input[type="password"],
.ui.form input[type="search"],
.ui.form input[type="tel"],
.ui.form input[type="time"],
.ui.form input[type="text"],
.ui.form input[type="file"],
.ui.form input[type="url"] {
    font-family: 'Lato', 'Helvetica Neue', Arial, Helvetica, sans-serif;
    margin: 0em;
    outline: none;
    line-height: 1.21428571em;
    padding: 0.67857143em 1em;
    font-size: 1em;
    background: #FFFFFF;
    border: 1px solid rgba(34, 36, 38, 0.15);
    color: rgba(0, 0, 0, 0.87);
    border-radius: 0.28571429rem;
    -webkit-box-shadow: 0em 0em 0em 0em transparent inset;
    box-shadow: 0em 0em 0em 0em transparent inset;
    -webkit-transition: color 0.1s ease, border-color 0.1s ease;
    transition: color 0.1s ease, border-color 0.1s ease;
}


/*--------------------
        Labels
---------------------*/

.ui.form .field>label {
    display: block;
    margin: 0em 0em 0.28571429rem 0em;
    /* color: rgba(0, 0, 0, 0.87); */
    font-size: 0.92857143em;
    font-weight: bold;
    text-transform: none;
}






/*--- Red ---*/

.ui.red.buttons .button,
.ui.red.button {
    background-color: #DB2828;
    color: #FFFFFF;
    text-shadow: none;
    background-image: none;
}

.ui.red.button {
    -webkit-box-shadow: 0px 0em 0px 0px rgba(34, 36, 38, 0.15) inset;
    box-shadow: 0px 0em 0px 0px rgba(34, 36, 38, 0.15) inset;
}

.ui.red.buttons .button:hover,
.ui.red.button:hover {
    background-color: #d01919;
    color: #FFFFFF;
    text-shadow: none;
}

.ui.red.buttons .button:focus,
.ui.red.button:focus {
    background-color: #ca1010;
    color: #FFFFFF;
    text-shadow: none;
}

.ui.red.buttons .button:active,
.ui.red.button:active {
    background-color: #b21e1e;
    color: #FFFFFF;
    text-shadow: none;
}

.ui.red.buttons .active.button,
.ui.red.buttons .active.button:active,
.ui.red.active.button,
.ui.red.button .active.button:active {
    background-color: #d41515;
    color: #FFFFFF;
    text-shadow: none;
}

/* Basic */

.ui.basic.red.buttons .button,
.ui.basic.red.button {
    -webkit-box-shadow: 0px 0px 0px 1px #DB2828 inset;
    box-shadow: 0px 0px 0px 1px #DB2828 inset;
    color: #DB2828;
}

.ui.basic.red.buttons .button:hover,
.ui.basic.red.button:hover {
    background: transparent;
    -webkit-box-shadow: 0px 0px 0px 1px #d01919 inset;
    box-shadow: 0px 0px 0px 1px #d01919 inset;
    color: #d01919;
}

.ui.basic.red.buttons .button:focus,
.ui.basic.red.button:focus {
    background: transparent;
    -webkit-box-shadow: 0px 0px 0px 1px #ca1010 inset;
    box-shadow: 0px 0px 0px 1px #ca1010 inset;
    color: #d01919;
}

.ui.basic.red.buttons .active.button,
.ui.basic.red.active.button {
    background: transparent;
    -webkit-box-shadow: 0px 0px 0px 1px #d41515 inset;
    box-shadow: 0px 0px 0px 1px #d41515 inset;
    color: #b21e1e;
}

.ui.basic.red.buttons .button:active,
.ui.basic.red.button:active {
    -webkit-box-shadow: 0px 0px 0px 1px #b21e1e inset;
    box-shadow: 0px 0px 0px 1px #b21e1e inset;
    color: #b21e1e;
}

.ui.buttons:not(.vertical)>.basic.red.button:not(:first-child) {
    margin-left: -1px;
}

/* Inverted */

.ui.inverted.red.buttons .button,
.ui.inverted.red.button {
    background-color: transparent;
    -webkit-box-shadow: 0px 0px 0px 2px #FF695E inset;
    box-shadow: 0px 0px 0px 2px #FF695E inset;
    color: #FF695E;
}

.ui.inverted.red.buttons .button:hover,
.ui.inverted.red.button:hover,
.ui.inverted.red.buttons .button:focus,
.ui.inverted.red.button:focus,
.ui.inverted.red.buttons .button.active,
.ui.inverted.red.button.active,
.ui.inverted.red.buttons .button:active,
.ui.inverted.red.button:active {
    -webkit-box-shadow: none;
    box-shadow: none;
    color: #FFFFFF;
}

.ui.inverted.red.buttons .button:hover,
.ui.inverted.red.button:hover {
    background-color: #ff5144;
}

.ui.inverted.red.buttons .button:focus,
.ui.inverted.red.button:focus {
    background-color: #ff4335;
}

.ui.inverted.red.buttons .active.button,
.ui.inverted.red.active.button {
    background-color: #ff5144;
}

.ui.inverted.red.buttons .button:active,
.ui.inverted.red.button:active {
    background-color: #ff392b;
}

/* Inverted Basic */

.ui.inverted.red.basic.buttons .button,
.ui.inverted.red.buttons .basic.button,
.ui.inverted.red.basic.button {
    background-color: transparent;
    -webkit-box-shadow: 0px 0px 0px 2px rgba(255, 255, 255, 0.5) inset;
    box-shadow: 0px 0px 0px 2px rgba(255, 255, 255, 0.5) inset;
    color: #FFFFFF;
}

.ui.inverted.red.basic.buttons .button:hover,
.ui.inverted.red.buttons .basic.button:hover,
.ui.inverted.red.basic.button:hover {
    -webkit-box-shadow: 0px 0px 0px 2px #ff5144 inset;
    box-shadow: 0px 0px 0px 2px #ff5144 inset;
    color: #FF695E;
}

.ui.inverted.red.basic.buttons .button:focus,
.ui.inverted.red.basic.buttons .button:focus,
.ui.inverted.red.basic.button:focus {
    -webkit-box-shadow: 0px 0px 0px 2px #ff4335 inset;
    box-shadow: 0px 0px 0px 2px #ff4335 inset;
    color: #FF695E;
}

.ui.inverted.red.basic.buttons .active.button,
.ui.inverted.red.buttons .basic.active.button,
.ui.inverted.red.basic.active.button {
    -webkit-box-shadow: 0px 0px 0px 2px #ff5144 inset;
    box-shadow: 0px 0px 0px 2px #ff5144 inset;
    color: #FF695E;
}

.ui.inverted.red.basic.buttons .button:active,
.ui.inverted.red.buttons .basic.button:active,
.ui.inverted.red.basic.button:active {
    -webkit-box-shadow: 0px 0px 0px 2px #ff392b inset;
    box-shadow: 0px 0px 0px 2px #ff392b inset;
    color: #FF695E;
}



/*--------------------
        Fields
---------------------*/

/* Split fields */

.ui.form .fields {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    margin: 0em -0.5em 1em;
  }
  
  .ui.form .fields > .field {
    -webkit-box-flex: 0;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    padding-left: 0.5em;
    padding-right: 0.5em;
  }
  
  .ui.form .fields > .field:first-child {
    border-left: none;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  
  /* Other Combinations */
  
  .ui.form .two.fields > .fields,
  .ui.form .two.fields > .field {
    width: 50%;
  }
  
  .ui.form .three.fields > .fields,
  .ui.form .three.fields > .field {
    width: 33.33333333%;
  }
  
  .ui.form .four.fields > .fields,
  .ui.form .four.fields > .field {
    width: 25%;
  }
  
  .ui.form .five.fields > .fields,
  .ui.form .five.fields > .field {
    width: 20%;
  }
  
  .ui.form .six.fields > .fields,
  .ui.form .six.fields > .field {
    width: 16.66666667%;
  }
  
  .ui.form .seven.fields > .fields,
  .ui.form .seven.fields > .field {
    width: 14.28571429%;
  }
  
  .ui.form .eight.fields > .fields,
  .ui.form .eight.fields > .field {
    width: 12.5%;
  }
  
  .ui.form .nine.fields > .fields,
  .ui.form .nine.fields > .field {
    width: 11.11111111%;
  }
  
  .ui.form .ten.fields > .fields,
  .ui.form .ten.fields > .field {
    width: 10%;
  }
  
  /* Swap to full width on mobile */
  
  @media only screen and (max-width: 767px) {
    .ui.form .fields {
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
    }
  
    .ui[class*="equal width"].form:not(.unstackable) .fields > .field,
    .ui.form:not(.unstackable) [class*="equal width"].fields:not(.unstackable) > .field,
    .ui.form:not(.unstackable) .two.fields:not(.unstackable) > .fields,
    .ui.form:not(.unstackable) .two.fields:not(.unstackable) > .field,
    .ui.form:not(.unstackable) .three.fields:not(.unstackable) > .fields,
    .ui.form:not(.unstackable) .three.fields:not(.unstackable) > .field,
    .ui.form:not(.unstackable) .four.fields:not(.unstackable) > .fields,
    .ui.form:not(.unstackable) .four.fields:not(.unstackable) > .field,
    .ui.form:not(.unstackable) .five.fields:not(.unstackable) > .fields,
    .ui.form:not(.unstackable) .five.fields:not(.unstackable) > .field,
    .ui.form:not(.unstackable) .six.fields:not(.unstackable) > .fields,
    .ui.form:not(.unstackable) .six.fields:not(.unstackable) > .field,
    .ui.form:not(.unstackable) .seven.fields:not(.unstackable) > .fields,
    .ui.form:not(.unstackable) .seven.fields:not(.unstackable) > .field,
    .ui.form:not(.unstackable) .eight.fields:not(.unstackable) > .fields,
    .ui.form:not(.unstackable) .eight.fields:not(.unstackable) > .field,
    .ui.form:not(.unstackable) .nine.fields:not(.unstackable) > .fields,
    .ui.form:not(.unstackable) .nine.fields:not(.unstackable) > .field,
    .ui.form:not(.unstackable) .ten.fields:not(.unstackable) > .fields,
    .ui.form:not(.unstackable) .ten.fields:not(.unstackable) > .field {
      width: 100% ;
      margin: 0em 0em 1em;
    }
  }
  
  /* Sizing Combinations */
  
  .ui.form .fields .wide.field {
    width: 6.25%;
    padding-left: 0.5em;
    padding-right: 0.5em;
  }
  
  .ui.form .one.wide.field {
    width: 6.25% ;
  }
  
  .ui.form .two.wide.field {
    width: 12.5% ;
  }
  
  .ui.form .three.wide.field {
    width: 18.75% ;
  }
  
  .ui.form .four.wide.field {
    width: 25% ;
  }
  
  .ui.form .five.wide.field {
    width: 31.25% ;
  }
  
  .ui.form .six.wide.field {
    width: 37.5% ;
  }
  
  .ui.form .seven.wide.field {
    width: 43.75% ;
  }
  
  .ui.form .eight.wide.field {
    width: 50% ;
  }
  
  .ui.form .nine.wide.field {
    width: 56.25% ;
  }
  
  .ui.form .ten.wide.field {
    width: 62.5% ;
  }
  
  .ui.form .eleven.wide.field {
    width: 68.75% ;
  }
  
  .ui.form .twelve.wide.field {
    width: 75% ;
  }
  
  .ui.form .thirteen.wide.field {
    width: 81.25% ;
  }
  
  .ui.form .fourteen.wide.field {
    width: 87.5% ;
  }
  
  .ui.form .fifteen.wide.field {
    width: 93.75% ;
  }
  
  .ui.form .sixteen.wide.field {
    width: 100% ;
  }
  
  /* Swap to full width on mobile */
  
  @media only screen and (max-width: 767px) {
    .ui.form:not(.unstackable) .two.fields:not(.unstackable) > .fields,
    .ui.form:not(.unstackable) .two.fields:not(.unstackable) > .field,
    .ui.form:not(.unstackable) .three.fields:not(.unstackable) > .fields,
    .ui.form:not(.unstackable) .three.fields:not(.unstackable) > .field,
    .ui.form:not(.unstackable) .four.fields:not(.unstackable) > .fields,
    .ui.form:not(.unstackable) .four.fields:not(.unstackable) > .field,
    .ui.form:not(.unstackable) .five.fields:not(.unstackable) > .fields,
    .ui.form:not(.unstackable) .five.fields:not(.unstackable) > .field,
    .ui.form:not(.unstackable) .fields:not(.unstackable) > .two.wide.field,
    .ui.form:not(.unstackable) .fields:not(.unstackable) > .three.wide.field,
    .ui.form:not(.unstackable) .fields:not(.unstackable) > .four.wide.field,
    .ui.form:not(.unstackable) .fields:not(.unstackable) > .five.wide.field,
    .ui.form:not(.unstackable) .fields:not(.unstackable) > .six.wide.field,
    .ui.form:not(.unstackable) .fields:not(.unstackable) > .seven.wide.field,
    .ui.form:not(.unstackable) .fields:not(.unstackable) > .eight.wide.field,
    .ui.form:not(.unstackable) .fields:not(.unstackable) > .nine.wide.field,
    .ui.form:not(.unstackable) .fields:not(.unstackable) > .ten.wide.field,
    .ui.form:not(.unstackable) .fields:not(.unstackable) > .eleven.wide.field,
    .ui.form:not(.unstackable) .fields:not(.unstackable) > .twelve.wide.field,
    .ui.form:not(.unstackable) .fields:not(.unstackable) > .thirteen.wide.field,
    .ui.form:not(.unstackable) .fields:not(.unstackable) > .fourteen.wide.field,
    .ui.form:not(.unstackable) .fields:not(.unstackable) > .fifteen.wide.field,
    .ui.form:not(.unstackable) .fields:not(.unstackable) > .sixteen.wide.field {
      width: 100% ;
    }
  
    .ui.form .fields {
      margin-bottom: 0em;
    }
  }
  


/*--------------------
        Form
---------------------*/

.ui.form {
    position: relative;
    max-width: 100%;
  }
  
  
/*--------------------
        Fields
---------------------*/

/* Split fields */

.ui.form .fields {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    margin: 0em -0.5em 1em;
  }
  
  .ui.form .fields > .field {
    -webkit-box-flex: 0;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    padding-left: 0.5em;
    padding-right: 0.5em;
  }
  
  .ui.form .fields > .field:first-child {
    border-left: none;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  
  /* Other Combinations */
  
  .ui.form .two.fields > .fields,
  .ui.form .two.fields > .field {
    width: 50%;
  }
  
  .ui.form .three.fields > .fields,
  .ui.form .three.fields > .field {
    width: 33.33333333%;
  }
  
  .ui.form .four.fields > .fields,
  .ui.form .four.fields > .field {
    width: 25%;
  }
  
  .ui.form .five.fields > .fields,
  .ui.form .five.fields > .field {
    width: 20%;
  }
  
  .ui.form .six.fields > .fields,
  .ui.form .six.fields > .field {
    width: 16.66666667%;
  }
  
  .ui.form .seven.fields > .fields,
  .ui.form .seven.fields > .field {
    width: 14.28571429%;
  }
  
  .ui.form .eight.fields > .fields,
  .ui.form .eight.fields > .field {
    width: 12.5%;
  }
  
  .ui.form .nine.fields > .fields,
  .ui.form .nine.fields > .field {
    width: 11.11111111%;
  }
  
  .ui.form .ten.fields > .fields,
  .ui.form .ten.fields > .field {
    width: 10%;
  }
  
  /* Swap to full width on mobile */
  
  @media only screen and (max-width: 767px) {
    .ui.form .fields {
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
    }
  
    .ui[class*="equal width"].form:not(.unstackable) .fields > .field,
    .ui.form:not(.unstackable) [class*="equal width"].fields:not(.unstackable) > .field,
    .ui.form:not(.unstackable) .two.fields:not(.unstackable) > .fields,
    .ui.form:not(.unstackable) .two.fields:not(.unstackable) > .field,
    .ui.form:not(.unstackable) .three.fields:not(.unstackable) > .fields,
    .ui.form:not(.unstackable) .three.fields:not(.unstackable) > .field,
    .ui.form:not(.unstackable) .four.fields:not(.unstackable) > .fields,
    .ui.form:not(.unstackable) .four.fields:not(.unstackable) > .field,
    .ui.form:not(.unstackable) .five.fields:not(.unstackable) > .fields,
    .ui.form:not(.unstackable) .five.fields:not(.unstackable) > .field,
    .ui.form:not(.unstackable) .six.fields:not(.unstackable) > .fields,
    .ui.form:not(.unstackable) .six.fields:not(.unstackable) > .field,
    .ui.form:not(.unstackable) .seven.fields:not(.unstackable) > .fields,
    .ui.form:not(.unstackable) .seven.fields:not(.unstackable) > .field,
    .ui.form:not(.unstackable) .eight.fields:not(.unstackable) > .fields,
    .ui.form:not(.unstackable) .eight.fields:not(.unstackable) > .field,
    .ui.form:not(.unstackable) .nine.fields:not(.unstackable) > .fields,
    .ui.form:not(.unstackable) .nine.fields:not(.unstackable) > .field,
    .ui.form:not(.unstackable) .ten.fields:not(.unstackable) > .fields,
    .ui.form:not(.unstackable) .ten.fields:not(.unstackable) > .field {
      width: 100% ;
      margin: 0em 0em 1em;
    }
  }
  
  /* Sizing Combinations */
  
  .ui.form .fields .wide.field {
    width: 6.25%;
    padding-left: 0.5em;
    padding-right: 0.5em;
  }
  
  .ui.form .one.wide.field {
    width: 6.25% ;
  }
  
  .ui.form .two.wide.field {
    width: 12.5% ;
  }
  
  .ui.form .three.wide.field {
    width: 18.75% ;
  }
  
  .ui.form .four.wide.field {
    width: 25% ;
  }
  
  .ui.form .five.wide.field {
    width: 31.25% ;
  }
  
  .ui.form .six.wide.field {
    width: 37.5% ;
  }
  
  .ui.form .seven.wide.field {
    width: 43.75% ;
  }
  
  .ui.form .eight.wide.field {
    width: 50% ;
  }
  
  .ui.form .nine.wide.field {
    width: 56.25% ;
  }
  
  .ui.form .ten.wide.field {
    width: 62.5% ;
  }
  
  .ui.form .eleven.wide.field {
    width: 68.75% ;
  }
  
  .ui.form .twelve.wide.field {
    width: 75% ;
  }
  
  .ui.form .thirteen.wide.field {
    width: 81.25% ;
  }
  
  .ui.form .fourteen.wide.field {
    width: 87.5% ;
  }
  
  .ui.form .fifteen.wide.field {
    width: 93.75% ;
  }
  
  .ui.form .sixteen.wide.field {
    width: 100% ;
  }
  
  /* Swap to full width on mobile */
  
  @media only screen and (max-width: 767px) {
    .ui.form:not(.unstackable) .two.fields:not(.unstackable) > .fields,
    .ui.form:not(.unstackable) .two.fields:not(.unstackable) > .field,
    .ui.form:not(.unstackable) .three.fields:not(.unstackable) > .fields,
    .ui.form:not(.unstackable) .three.fields:not(.unstackable) > .field,
    .ui.form:not(.unstackable) .four.fields:not(.unstackable) > .fields,
    .ui.form:not(.unstackable) .four.fields:not(.unstackable) > .field,
    .ui.form:not(.unstackable) .five.fields:not(.unstackable) > .fields,
    .ui.form:not(.unstackable) .five.fields:not(.unstackable) > .field,
    .ui.form:not(.unstackable) .fields:not(.unstackable) > .two.wide.field,
    .ui.form:not(.unstackable) .fields:not(.unstackable) > .three.wide.field,
    .ui.form:not(.unstackable) .fields:not(.unstackable) > .four.wide.field,
    .ui.form:not(.unstackable) .fields:not(.unstackable) > .five.wide.field,
    .ui.form:not(.unstackable) .fields:not(.unstackable) > .six.wide.field,
    .ui.form:not(.unstackable) .fields:not(.unstackable) > .seven.wide.field,
    .ui.form:not(.unstackable) .fields:not(.unstackable) > .eight.wide.field,
    .ui.form:not(.unstackable) .fields:not(.unstackable) > .nine.wide.field,
    .ui.form:not(.unstackable) .fields:not(.unstackable) > .ten.wide.field,
    .ui.form:not(.unstackable) .fields:not(.unstackable) > .eleven.wide.field,
    .ui.form:not(.unstackable) .fields:not(.unstackable) > .twelve.wide.field,
    .ui.form:not(.unstackable) .fields:not(.unstackable) > .thirteen.wide.field,
    .ui.form:not(.unstackable) .fields:not(.unstackable) > .fourteen.wide.field,
    .ui.form:not(.unstackable) .fields:not(.unstackable) > .fifteen.wide.field,
    .ui.form:not(.unstackable) .fields:not(.unstackable) > .sixteen.wide.field {
      width: 100% ;
    }
  
    .ui.form .fields {
      margin-bottom: 0em;
    }
  }
  
  /*--------------------
       Equal Width
  ---------------------*/
  
  .ui[class*="equal width"].form .fields > .field,
  .ui.form [class*="equal width"].fields > .field {
    width: 100%;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
  }
  
  /*--------------------
      Inline Fields
  ---------------------*/
  
  .ui.form .inline.fields {
    margin: 0em 0em 1em;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  
  .ui.form .inline.fields .field {
    margin: 0em;
    padding: 0em 1em 0em 0em;
  }
  
  /* Inline Label */
  
  .ui.form .inline.fields > label,
  .ui.form .inline.fields .field > label,
  .ui.form .inline.fields .field > p,
  .ui.form .inline.field > label,
  .ui.form .inline.field > p {
    display: inline-block;
    width: auto;
    margin-top: 0em;
    margin-bottom: 0em;
    vertical-align: baseline;
    font-size: 0.92857143em;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.87);
    text-transform: none;
  }
  
  /* Grouped Inline Label */
  
  .ui.form .inline.fields > label {
    margin: 0.035714em 1em 0em 0em;
  }
  
  /* Inline Input */
  
  .ui.form .inline.fields .field > input,
  .ui.form .inline.fields .field > select,
  .ui.form .inline.field > input,
  .ui.form .inline.field > select {
    display: inline-block;
    width: auto;
    margin-top: 0em;
    margin-bottom: 0em;
    vertical-align: middle;
    font-size: 1em;
  }
  
  /* Label */
  
  .ui.form .inline.fields .field > :first-child,
  .ui.form .inline.field > :first-child {
    margin: 0em 0.85714286em 0em 0em;
  }
  
  .ui.form .inline.fields .field > :only-child,
  .ui.form .inline.field > :only-child {
    margin: 0em;
  }
  
  /* Wide */
  
  .ui.form .inline.fields .wide.field {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  
  .ui.form .inline.fields .wide.field > input,
  .ui.form .inline.fields .wide.field > select {
    width: 100%;
  }
  
  /*--------------------
          Sizes
  ---------------------*/
  
  .ui.mini.form {
    font-size: 0.78571429rem;
  }
  
  .ui.tiny.form {
    font-size: 0.85714286rem;
  }
  
  .ui.small.form {
    font-size: 0.92857143rem;
  }
  
  .ui.form {
    font-size: 1rem;
  }
  
  .ui.large.form {
    font-size: 1.14285714rem;
  }
  
  .ui.big.form {
    font-size: 1.28571429rem;
  }
  
  .ui.huge.form {
    font-size: 1.42857143rem;
  }
  
  .ui.massive.form {
    font-size: 1.71428571rem;
  }
  



@media (max-width: 800px) {
    .bsm-inner {
        padding-left: 0;
    }

    .nav-mdl {
        width: 100%;
        height: auto;
        position: relative;
        padding-top: 0;
        background-color: #161616;
        border-right: none;
        padding: 0 5px;
    }

    .nav-mdl .nav-btn {
        width: 100%;
        height: 40px;
        left: 0;
        float: left;
        margin: 3px 0;
    }

    .nav-mdl .nav-btn.active:first-child {
        margin-top: 3px;
    }

    .nav-mdl .nav-btn.active {
        margin: 3px 0;
    }

    .subpgModal .nav-mdl .nav-btn {
        width: 25%;
    }

    .nav-mdl.three .nav-btn {
        width: 33.33%;
    }

    .nav-mdl .nav-btn button i {
        font-size: 16px;
        display: inline-block;
        vertical-align: middle;
    }

    .nav-mdl .nav-btn button p {
        display: inline-block;
        vertical-align: middle;
    }
}

@media (max-width: 640px) {
    .modal-body .mdl-head .mdl-title div .kr {
        color: #ffbb7f;
        font-size: 20px;
        margin-top: 0;
    }

    .modal-body:before {
        background-size: 250px 138px;
    }

    .modal-body:after {
        background-size: 350px 108px;
    }

    .joinModal .modal-body .tab-mdl {
        padding: 40px 20px;
    }

    .loginModal .modal-body .tab-mdl {
        padding: 40px 20px;
    }

    .modal-body .tab-mdl {
        padding: 40px 20px;
    }

    .form-container .form-group {
        padding-left: 0;
        margin-bottom: 15px;
    }

    .form-container .form-group .labels {
        width: 100%;
        height: auto;
        position: relative;
        float: left;
        display: inline-block;
        text-align: left;
        padding-left: 5px;
        margin-bottom: 5px;
    }

    .form-container .form-group .infos input {
        height: 30px;
        font-size: 12px;
        padding: 0 5px;
        border-radius: 5px;
    }

    .form-container .form-group .labels p {
        font-size:13px;
    }

    .form-container .form-group .infos label {
        height: auto;
        margin-bottom: 0;
    }

    .form-container .form-group .infos label select {
        height: 40px;
        font-size: 14px;
        padding: 0 10px;
        border-radius: 5px;
    }

    .form-container .form-group.w-btn .infos button {
        height: 40px;
        border-radius: 5px;
        font-size: 14px;
    }

    .form-container .form-group .infos {
        text-align: center;
    }

    .mypageModal .form-container .form-group {
        padding-left: 0;
    }

    .deposit-ask {
        padding: 0 20px;
    }

    .gamelist-cont {
        height: auto;
        overflow-y: auto;
    }

    .gl-inner {
        top: -10px;
        left: 0;
    }

    .game-btn {
        width: 31.83%;
    }
}

@media (max-width: 500px) {
    .nav-main .nav-cont .bs-ul li a {
        font-size: 14px;
        padding: 0 0;
    }

    .nav-mdl .nav-btn {
        border-radius: 30px 5px 30px 5px;
    }

    .subpgModal .modal-body .tab-mdl {
        min-height: auto;
    }

    .subpgModal .nav-mdl .nav-btn {
        width: 50%;
    }

    .bs-table tr td a {
        display: inline-block;
        vertical-align: middle;
        max-width: 200px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .modal-btn-foot .btn-cont {
        width: 100%;
    }

    .modal-btn-foot .btn-cont button {
        width: 48%;
        height: 30px;
        margin: 0 1%;
        font-size: 14px;
    }

    .joinModal .modal-btn-foot .btn-cont button {
        width: 100%;
        margin: 0 0;
    }

    .modal-btn-foot.three .btn-cont button {
        width: 32%;
    }

    .modal-btn-foot.three .btn-cont button:first-child {
        margin-left: 0;
    }

    .modal-btn-foot.three .btn-cont button:last-child {
        margin-right: 0;
    }

    .mypageModal .mp-info .cont span {
        font-size: 10px;
        padding: 0 3px;
    }

    .mypageModal .mp-info .cont:first-child {
        width: 19%;
    }

    .mypageModal .mp-info .cont:nth-child(3) {
        width: 30%;
    }

    .mypageModal .mp-info .cont span p:first-child {
        font-size: 10px;
    }

    .mypageModal .mp-info .cont:nth-child(2) span p:first-child {
        position: relative;
        top: -5px;
    }

    .mypageModal .mp-info .cont span p:last-child {
        font-size: 14px;
    }

    .mypageModal .mp-info .cont span img {
        display: block;
        margin: 3px auto 0 auto;
    }

    .mypageModal .mp-info .points-btn {
        width: 100%;
        font-size: 8px;
        padding: 0 5px;
    }

    .bs-table tr td a {
        display: inline-block;
        vertical-align: middle;
        max-width: 200px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .bs-table thead th {
        font-size: 12px;
    }
}

@media (max-width: 414px) {
    .bs-table tr td a {
        max-width: 160px;
    }

    .game-btn {
        width: 48.5%;
    }

    .mypage-nav {
        margin-bottom: 20px;
    }

    .nav-mdl .nav-btn button {
        white-space: nowrap;
    }

    .mypageModal .nav-mdl .nav-btn button {
        font-size: 11px;
    }

    .historyModal .nav-mdl .nav-btn button {
        font-size: 12px;
    }
}