.transaction-page {
    margin-bottom: 10px;
}

.transaction-page .transaction-container {
    display: flex;
    flex-wrap: nowrap;
    align-items: stretch;
    background-color: rgb(0, 0, 0);
}

.transaction-table {
    border-top-color: rgb(40, 40, 40);
    border-top-style: solid;
    border-top-width: 1px;
    border-right-color: rgb(40, 40, 40);
    border-right-style: solid;
    border-right-width: 1px;
    border-bottom-color: rgb(40, 40, 40);
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-left-color: rgb(40, 40, 40);
    border-left-style: solid;
    border-left-width: 1px;
    font-size: 13px;
    color: rgb(153, 153, 153);
    font-family: "Dotum Regular";
    flex-grow: 1;
}

.transaction-table:nth-of-type(+1),
.transaction-table:nth-of-type(+2) {
    border-right-color: initial;
    border-right-style: initial;
    border-right-width: 0;
}

.transaction-table:nth-of-type(+3) {
    border-left-color: rgb(40, 40, 40);
    border-left-style: solid;
    border-left-width: 1px;
}

.transaction-table:nth-of-type(+2) {
    border-left-color: rgb(40, 40, 40);
    border-left-style: solid;
    border-left-width: 1px;
}

.transaction-page .transaction-table .table-heading {
    width: 100%;
    background-color: rgb(17, 17, 17);
    background-image: url("../images/customer-header.jpg");
    background-position: top center 100%;
    background-repeat: no-repeat;
    font-size: 13px;
    color: rgb(193, 173, 139);
    padding-top: 7px;
    padding-right: 18px;
    padding-bottom: 7px;
    padding-left: 18px;
    font-weight: 600;
    font-family: noto sans cjk;
    letter-spacing: -0.8px;
    line-height: 49px;
    background-size: 100% 100%;
}

.transaction-page .transaction-table:nth-child(+2) .table-heading {
    display: flex;
    justify-content: space-around !important;
    align-items: center;
    height: 65px;
}

.transaction-page .goldTxt {
    color: rgb(222, 185, 125);
    font-weight: 600;
}

.transaction-page .text-white {
    color: rgb(255, 255, 255);
    font-weight: 600;
}

.transaction-page .text-white:hover {
    color: rgb(222, 185, 125);
}

.transaction-page .transaction-table tr {
    height: 26px;
    text-align: center;
    background-color: rgb(29, 29, 29);
}

.transaction-page .transaction-table tr:nth-of-type(2n+1) {
    background-color: rgb(17, 17, 17);
}

.transaction-page .table-heading span.pull-right {
    color: rgb(129, 128, 131);
}

.transaction-page .table-heading span:hover.pull-right {
    color: rgb(202, 181, 147);
    cursor: pointer;
}

.transaction-page .transaction-table:first-of-type {
    width: 410px;
}

.transaction-page .transaction-table:nth-child(1n+2) {
    width: 415px;
}

.transaction-page .table-heading span:first-of-type {
    font-size: 20px;
}

.transaction-page .transaction-table:nth-child(+2) .table-heading span {
    font-size: 20px;
    color: rgb(129, 128, 131);
    cursor: pointer;
}

.transaction-page .transaction-table:nth-child(+2) .table-heading span.active,
.transaction-page .transaction-table:nth-child(+2) .table-heading span:hover {
    color: rgb(193, 173, 139);
}

.transaction-page .table-body {
    overflow-x: hidden;
    overflow-y: hidden;
}

ul.transaction-list {
    padding-top: 0;
    padding-right: 25px;
    padding-bottom: 0;
    padding-left: 25px;
    height: 130px;
    margin-top: 0;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 0;
}

.flow-list {
    position: relative;
    top: 0;
}

.flow-list.list-charge {}

.flow-list.list-excharge {}

@keyframes scroll-charge {}

@keyframes scroll-excharge {}

ul.transaction-list .items {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 13px;
    height: 26px;
    color: rgb(153, 153, 153);
}

ul.transaction-list .transaction-item {}