@font-face {
    font-family: "Lato";
    font-style: italic;
    font-weight: 400;
    src: url("../font/S6u8w4BMUTPHjxsAXC-s.woff") format("woff");
}

@font-face {
    font-family: "Lato";
    font-style: italic;
    font-weight: 700;
    src: url("../font/S6u_w4BMUTPHjxsI5wq_Gwfr.woff") format("woff");
}

@font-face {
    font-family: "Lato";
    font-style: normal;
    font-weight: 400;
    src: url("../font/S6uyw4BMUTPHjx4wWA.woff") format("woff");
}

@font-face {
    font-family: "Lato";
    font-style: normal;
    font-weight: 700;
    src: url("../font/S6u9w4BMUTPHh6UVSwiPHw.woff") format("woff");
}

div.banner {
    background-color: initial;
    background-image: url("../images/background.jpg");
    background-position: center top;
    background-repeat: no-repeat;    
    
}


.main-page .content .game-buttons.others {
    background-color: initial;
    background-image: url("../images/prog-jockpot(0).png?ver=1.0");
    background-repeat: no-repeat;
}

.jodometer_dot{
    background: url("../images/odometer.png") center bottom no-repeat;    
    
}



.uk-modal-dialog {
    position: relative;
    box-sizing: border-box;
    margin-top: 0;
    margin-right: auto;
    margin-bottom: 0;
    margin-left: auto;
    width: 600px;
    /* background-color: rgb(255, 255, 255); */
    background-image: initial;
    /* opacity: 0;
    transform: translateY(-100px); */
    transition-property: opacity, transform;
    transition-duration: 0.3s;
    transition-timing-function: initial;
    transition-delay: initial;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
}






