@font-face {
    font-family: "S-CoreDream-4Regular";
    src: url("../font/S-CoreDream-4Regular.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

.wrapper {
    min-width: 1260px;
}

body {
    font-family: "S-CoreDream-4Regular", "MalgunGothic", sans-serif;
    color: rgb(51, 51, 51);
    overflow-y: scroll;
}

.main-container {
    width: 1260px;
    margin-top: 0;
    margin-right: auto;
    margin-bottom: 0;
    margin-left: auto;
    position: relative;
}

.bg-main {
    background-color: rgb(0, 0, 0);
    background-image: initial;
}

.bg-viewsport {
    background-color: rgb(0, 0, 0);
    background-image: url("https://bm719.com/images/images/sports-header.jpg");
    background-position: top center;
    background-repeat: no-repeat;
}

.list-inline {
    margin-top: 0;
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 0;
}

a:hover,
a:focus,
a:active,
a.active {
    text-decoration-line: none;
}

.main-content {
    display: flex;
    flex-wrap: nowrap;
}

.no-padding {
    padding-top: 0 !important;
    padding-right: 0 !important;
    padding-bottom: 0 !important;
    padding-left: 0 !important;
}

.btn-red {
    background-color: rgb(155, 0, 0);
    background-image: initial;
    color: rgb(255, 255, 255);
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    border-top-color: rgb(254, 15, 0);
    border-right-color: rgb(254, 15, 0);
    border-bottom-color: rgb(254, 15, 0);
    border-left-color: rgb(254, 15, 0);
    outline-style: none;
}

.btn-red:hover,
.btn-red:active,
.btn-primary:active:focus,
.btn-red:focus {
    background-color: rgb(108, 37, 29);
    background-image: initial;
    color: rgb(255, 255, 255);
    border-top-color: transparent;
    border-right-color: transparent;
    border-bottom-color: transparent;
    border-left-color: transparent;
    outline-style: none;
}

.view-sports {
    background-color: rgb(255, 255, 255);
    background-image: url("https://bm719.com/images/images/preloader-main.gif");
    background-position: center;
    background-repeat: no-repeat;
}

.btn-yellow {
    background-color: rgb(17, 17, 17);
    background-image: initial;
    border-top-color: rgb(193, 173, 139);
    border-top-style: solid;
    border-top-width: 1px;
    border-right-color: rgb(193, 173, 139);
    border-right-style: solid;
    border-right-width: 1px;
    border-bottom-color: rgb(193, 173, 139);
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-left-color: rgb(193, 173, 139);
    border-left-style: solid;
    border-left-width: 1px;
    color: rgb(193, 173, 139) !important;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    font-weight: bold;
}

.btn-yellow.active,
.btn-yellow:hover {
    background-color: rgb(193, 173, 139);
    background-image: initial;
    color: rgb(17, 17, 17) !important;
}

.btn:focus,
.btn:active:focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn.active.focus {
    outline-style: none !important;
}

select {}

select:expand {
    display: none;
}

.text-left {
    text-align: left !important;
}

.disable-event {
    pointer-events: none;
}

@media NOT ALL,
NOT ALL {
    .transaction-page .transaction-table tr:nth-of-type(2n+1) {
        box-shadow: none !important;
    }

    .transaction-page .transaction-table:nth-of-type(+3) tr:nth-of-type(2n+1) {
        box-shadow: none !important;
    }

    .transaction-page .transaction-table tr:last-of-type {
        box-shadow: none !important;
    }

    .navigation-page .nav-container .list-inline.nav-list>li {
        padding-top: 0 !important;
        padding-right: 16px !important;
        padding-bottom: 0 !important;
        padding-left: 0 !important;
    }

    #textSearch:input-placeholder {
        color: rgb(204, 204, 204) !important;
        font-weight: 400 !important;
        line-height: 20px;
    }

    .quick-links-page .quick-links-container .list-inline.live>li {
        padding-top: 0 !important;
        padding-right: 3px !important;
        padding-bottom: 0 !important;
        padding-left: 3px !important;
    }
}

.swal-title,
.swal-text {
    text-align: center;
}

.swal-title {
    white-space: pre-wrap;
}

.swal-modal {
    width: 600px;
}

:scrollbar {
    width: 10px;
    background-color: rgb(24, 24, 24);
}

:scrollbar-thumb {
    background-color: rgb(193, 173, 139);
    background-image: initial;
}