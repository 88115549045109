.SLB_caption {
    width: 100%;
    height: 40px;
    line-height: 40px;
    color: white;
    font-weight: bold;
    background-color: initial;
    background-image: linear-gradient(90deg, rgb(27, 36, 48), black);
    cursor: move;
    font-size: 20px;
    padding-left: 10px;
}

.SLB_close {
    position: absolute;
    top: 6px;
    right: 10px;
    width: 30px;
    height: 30px;
    cursor: pointer;
    background-color: initial;
    background-image: url("../images/icon-close.png");
    background-repeat: no-repeat;
    background-size: 27px auto;
}

#SLB_iframe {
    background-color: white;
    overflow-y: scroll;
    overflow-x: hidden;
}

#SLB_content {
    background-color: rgb(27, 36, 48);
}

#debug {
    width: 80%;
    text-align: left;
    white-space: pre-wrap;
    margin-left: 250px;
    padding-top: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    font-size: 10pt;
    font-family: consolas;
    color: rgb(91, 91, 91);
    border-top-color: black;
    border-top-style: solid;
    border-top-width: 1px;
    border-right-color: black;
    border-right-style: solid;
    border-right-width: 1px;
    border-bottom-color: black;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-left-color: black;
    border-left-style: solid;
    border-left-width: 1px;
}

label.error {
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 0;
    margin-left: 10px;
    color: orangered;
    display: inline-block;
}

input.error,
textarea.error {
    border-top-color: rgb(224, 180, 180);
    border-top-style: solid;
    border-top-width: 1px;
    border-right-color: rgb(224, 180, 180);
    border-right-style: solid;
    border-right-width: 1px;
    border-bottom-color: rgb(224, 180, 180);
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-left-color: rgb(224, 180, 180);
    border-left-style: solid;
    border-left-width: 1px;
}

.pagination_box {
    clear: both;
    display: block;
    text-align: center;
    width: 100%;
    margin-top: 10px;
}

._pagination {
    padding-left: 0;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
    display: inline-block;
    margin-top: 20px;
    margin-right: 0;
    margin-bottom: 20px;
    margin-left: 0;
    text-align: center;
}

._pagination>li {
    display: inline;
    font-size: 0.9em;
    font-weight: bold;
}

._pagination>li>a,
._pagination>li>span {
    position: relative;
    float: left;
    padding-top: 2px;
    padding-right: 6px;
    padding-bottom: 2px;
    padding-left: 6px;
    line-height: 1.4;
    text-decoration-line: none;
    color: rgb(90, 92, 107);
    margin-top: 0;
    margin-right: 1px;
    margin-bottom: 0;
    margin-left: 1px;
}

._pagination>li:first-child>a,
._pagination>li:last-child>a {
    margin-left: 0;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
    border-top-color: rgb(168, 169, 179);
    border-top-style: solid;
    border-top-width: 1px;
    border-right-color: rgb(168, 169, 179);
    border-right-style: solid;
    border-right-width: 1px;
    border-bottom-color: rgb(168, 169, 179);
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-left-color: rgb(168, 169, 179);
    border-left-style: solid;
    border-left-width: 1px;
}

._pagination>li.active a {
    background-color: cornflowerblue;
    color: rgb(255, 255, 255);
    border-top-color: rgb(249, 249, 249);
    border-top-style: solid;
    border-top-width: 1px;
    border-right-color: rgb(249, 249, 249);
    border-right-style: solid;
    border-right-width: 1px;
    border-bottom-color: rgb(249, 249, 249);
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-left-color: rgb(249, 249, 249);
    border-left-style: solid;
    border-left-width: 1px;
}

.user_level_icon {
    width: 20px;
}